import { Form } from 'components/Form/Form.styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const AuthTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 500;
  color: white;
  opacity: 0.9;
`;

export const AuthSubtitle = styled.h2`
  font-size: 1rem;
  font-weight: 500;
  color: white;
  opacity: 0.7;
  text-align: center;
  line-height: 1.5;
`;

export const AuthForm = styled(Form)`
  border-radius: 8px;
  box-shadow: 0 0 12px 12px rgba(255, 255, 255, 0.1);
  gap: 24px;
  width: 500px;
  padding: 40px;
`;

export const AuthInputButton = styled.div`
  padding: 14px 16px;
  border: 1px solid white;
  border-radius: 4px;
  font-size: 1rem;
  background-color: transparent;
  color: white;
  opacity: 0.7;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const AuthLink = styled(Link)<{
  color?: 'black' | 'white' | 'primary';
  opacity?: number;
  textDecoration?: string;
}>`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${(props) =>
    props.color === 'primary'
      ? props.theme.colors[props.color].light
      : props.theme.colors[props.color || 'white'].main};
  font-weight: 500;
  text-decoration: ${(props) => props.textDecoration || 'none'};
  &:hover {
    text-decoration: underline;
  }
  opacity: ${(props) => props.opacity || 0.9};
`;

const AuthButtonBase = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 16px 16px;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  border: none;
`;

export const AuthButton = styled(AuthButtonBase)`
  background-color: ${(props) => props.theme.colors.primary.main};
  color: ${(props) => props.theme.colors.white.main};
  &:hover {
    background-color: ${(props) => props.theme.colors.primary.dark};
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &:disabled:hover {
    background-color: ${(props) => props.theme.colors.primary.main};
  }
`;

export const KakaoButton = styled(AuthButtonBase)`
  background-color: #fff066;
  color: #3c1e1e;
  &:hover {
    background-color: #fee500;
  }
`;

export const NaverButton = styled(AuthButtonBase)`
  background-color: #1bc801;
  color: white;
  &:hover {
    background-color: #0aa500;
  }
`;

export const SocialButtonWrapper = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
