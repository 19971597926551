import { TSubscription } from 'apis/@types';
import styled from 'styled-components';

interface IChatRoomProps {
  subscription: TSubscription;
}

const ChatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 432px;
  padding: 0 12px;
  box-sizing: border-box;
  width: 100%;
`;

export default function Chats({ subscription }: IChatRoomProps) {
  return <ChatsWrapper></ChatsWrapper>;
}

// 1. STANDARD
// 퍼포먼스 : 네이버, 카카오, 구글, 페이스북 광고
// 바이럴 : 네이버 블로그
// 주차별 보고서

//
