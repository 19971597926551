import styled from 'styled-components';

export const WorkspaceContainer = styled.div`
  display: flex;
  padding: 20px 80px;
  box-sizing: border-box;

  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: 100%;
`;

export const WorkspaceTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 40px;
  text-align: left;
`;

export const WorkspaceCardWrapper = styled.div<{
  direction?: 'row' | 'column';
  justify?: 'center' | 'flex-start' | 'flex-end';
  align?: 'center' | 'flex-start' | 'flex-end';
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justify }) => justify || 'start'};
  align-items: ${({ align }) => align || 'start'};
  flex-direction: ${({ direction }) => direction || 'row'};
  gap: 20px;
`;

export const WorkspaceCard = styled.div<{
  $width?: string;
  $flex?: string;
  $padding?: string;
  $hideShadow?: boolean;
  $gap?: number;
  $alignItems?: string;
  $justifyContent?: string;
}>`
  background-color: ${({ theme }) => theme.colors.white.main};
  box-shadow: ${({ $hideShadow: hideShadow }) =>
    hideShadow ? 'none' : '0 0 10px rgba(0, 0, 0, 0.1)'};
  border-radius: 10px;
  padding: ${({ $padding: padding }) => padding || '20px'};
  width: ${({ $width: width }) => width || '300px'};
  flex: ${({ $flex: flex, $width: width }) => flex || (width ? 'none' : '1')};
  display: flex;
  align-items: ${({ $alignItems: alignItems }) => alignItems || 'center'};
  justify-content: ${({ $justifyContent: justifyContent }) =>
    justifyContent || 'start'};
  flex-direction: column;
  height: 100%;
  min-height: 600px;
  box-sizing: border-box;
  gap: ${({ $gap: gap }) => gap || 0}px;
`;

export const WorkspaceCardTitle = styled.h2`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black.main};
  margin-bottom: 12px;
  opacity: 0.9;
`;

export const WorkspaceCardDescription = styled.p<{
  color?: string;
  opacity?: number;
}>`
  font-size: 1rem;
  color: ${({ theme, color }) => color || theme.colors.black.main};
  opacity: ${({ opacity }) => opacity || 0.9};
  line-height: 1.5;
`;

export const WorkspaceCardButton = styled.button`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary.main};
  border: none;
  color: ${({ theme }) => theme.colors.white.main};
  padding: 16px 20px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.dark};
  }
`;

export const WorkspaceCardOutlineButton = styled.button`
  width: 100%;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.primary.main};
  padding: 16px 20px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.white.main};
  }
`;
