import styled from 'styled-components';

export const DatePickerWrapper = styled.div<{
  color?: string;
  backgroundColor?: string;
  $borderColor?: string;
  opacity?: number;
}>`
  position: relative;
  display: inline-block;
  width: 100%;

  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    input {
      width: 100%;
      box-sizing: border-box;
      padding: 14px 16px;
      border: 1px solid ${(props) => props.$borderColor || 'white'};
      border-radius: 4px;
      font-size: 1rem;
      background-color: ${(props) => props.backgroundColor || 'transparent'};
      color: ${(props) => props.color || 'white'};
      opacity: ${(props) => props.opacity || 1};
      outline: none;
      transition: border-color 0.2s;

      &:focus {
        border-color: ${({ theme }) => theme.colors.primary.main};
      }
    }
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle {
    fill: ${({ theme }) => theme.colors.primary.light};
    color: ${({ theme }) => theme.colors.primary.light};
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.primary.main};
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary.main};
  }

  .react-datepicker__navigation {
    top: 0.5rem;
  }

  .react-datepicker__current-month {
    margin-top: 0.25rem;
    color: ${({ theme }) => theme.colors.white.main};
    font-size: 1.25rem;
  }

  .react-datepicker__day-name {
    color: ${({ theme }) => theme.colors.white.main};
    width: 32px;
    line-height: 32px;
  }

  .react-datepicker {
    font-size: 1rem;
    border: 1px solid ${({ theme }) => theme.colors.white.dark};
    border-radius: 4px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 32px;
    line-height: 32px;
  }

  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.white.main};
  }

  .react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.colors.gray.main};
  }

  .calendar-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.gray.dark};
    opacity: 0.7;
  }
`;
