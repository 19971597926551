import React from 'react';
import { ko } from 'date-fns/locale/ko';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, Control, FieldValues, Path } from 'react-hook-form';
import { DatePickerWrapper } from 'components/Form/DatePicker/DatePicker.styles';
import { FaRegCalendar } from 'react-icons/fa';

interface DatePickerProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
  filterDate?: (date: Date) => boolean;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  opacity?: number;
}

const DatePicker = <T extends FieldValues>({
  control,
  name,
  placeholder,
  minDate,
  maxDate,
  filterDate,
  borderColor,
  backgroundColor,
  color,
  opacity,
}: DatePickerProps<T>) => {
  return (
    <DatePickerWrapper
      $borderColor={borderColor}
      backgroundColor={backgroundColor}
      color={color}
      opacity={opacity}
    >
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <ReactDatePicker
              locale={ko}
              selected={new Date(field.value)}
              onChange={(date) => {
                field.onChange(date?.toISOString().split('T')[0]);
              }}
              dateFormat="yyyy-MM-dd"
              placeholderText={placeholder}
              minDate={minDate}
              maxDate={maxDate}
              filterDate={filterDate}
              disabledKeyboardNavigation
              toggleCalendarOnIconClick
              showIcon
              icon={<FaRegCalendar className="calendar-icon" />}
            />
          );
        }}
      />
    </DatePickerWrapper>
  );
};

export default DatePicker;
