import Footer from 'layouts/landing/components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import { Outlet } from 'react-router-dom';
import { Blank } from 'Globalstyles';
import styled from 'styled-components';

const LandingLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function LandingLayout() {
  return (
    <LandingLayoutWrapper>
      <Navbar />
      <Outlet />
      <Blank $height="318px" />
      <Footer />
    </LandingLayoutWrapper>
  );
}
