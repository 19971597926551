import { Blank, Divider, FlexRow } from 'Globalstyles';
import { TSubscription } from 'apis/@types';
import { WorkspaceCard } from 'pages/workspace/Workspace.styles';
import {
  MenuContainer,
  MenuItem,
} from 'pages/workspace/components/Menu.styles';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface ChatListProps {
  subscriptions: TSubscription[];
}

const ChatItemTitle = styled.p`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
`;

const ChatItemDescription = styled.p`
  font-size: 0.9rem;
`;

export default function ChatList({ subscriptions }: ChatListProps) {
  const [status, setStatus] = useState<'matched' | 'end'>('matched');
  const { search } = useLocation();
  const navigate = useNavigate();
  const subscriptionId = new URLSearchParams(search).get('subscription');

  function handleStatusChange(newStatus: 'matched' | 'end') {
    setStatus(newStatus);
  }

  return (
    <WorkspaceCard $width="280px" $padding="12px">
      <FlexRow $justifyContent="stretch" width="100%" $gap="8px">
        <MenuItem
          style={{
            width: '100%',
            textAlign: 'center',
            padding: '16px',
          }}
          $active={status === 'matched'}
          onClick={() => handleStatusChange('matched')}
        >
          진행중
        </MenuItem>
        <MenuItem
          style={{
            width: '100%',
            textAlign: 'center',
            padding: '16px',
          }}
          $active={status === 'end'}
          onClick={() => handleStatusChange('end')}
        >
          마감
        </MenuItem>
      </FlexRow>
      <Blank $height="12px" />
      <Divider $direction="horizontal" />
      <Blank $height="12px" />
      <MenuContainer>
        {subscriptions
          .filter((subscription) => subscription.status === status)
          .map((subscription) => (
            <MenuItem
              key={subscription.id}
              $active={subscription.id === subscriptionId}
              onClick={() => navigate(`?subscription=${subscription.id}`)}
            >
              <ChatItemDescription>
                {subscription.companyName}
              </ChatItemDescription>
              <ChatItemTitle>{subscription.workName}</ChatItemTitle>
            </MenuItem>
          ))}
      </MenuContainer>
    </WorkspaceCard>
  );
}
