import { TSubscription } from 'apis/@types';
import getHosts from 'apis/analytics/host/getHosts';
import removeHost from 'apis/analytics/host/removeHost';
import getUserProfile from 'apis/common/getUserProfile';
import {
  collection,
  doc,
  getDocs,
  query,
  runTransaction,
  where,
} from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';
import { auth, db, storage } from 'utils/firebase';

export async function resignClient(reasons: string[]) {
  const userId = auth.currentUser?.uid;
  if (!userId) {
    throw new Error('로그인 되지 않았습니다.');
  }
  const userProfile = await getUserProfile(userId);
  if (userProfile.role !== 'client') {
    throw new Error('클라이언트가 아닙니다.');
  }

  const q = query(
    collection(db, 'subscriptions'),
    where('clientId', '==', userId)
  );

  const subscriptions: TSubscription[] = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const subscription = doc.data() as TSubscription;
    subscriptions.push({
      id: doc.id,
      ...subscription,
    });
  });

  if (
    subscriptions.some(
      (subscription) =>
        subscription.status === 'matched' || subscription.status === 'pending'
    )
  ) {
    throw new Error('진행 중인 프로젝트가 있습니다.');
  }

  for (const subscription of subscriptions) {
    if (subscription.status === 'temp') {
      subscription.reference.forEach(async (reference) => {
        await deleteObject(ref(storage, reference));
      });
    }
  }
  if (
    !!userProfile.profileImage &&
    userProfile.profileImage.startsWith('users/')
  ) {
    await deleteObject(ref(storage, userProfile.profileImage));
  }

  await runTransaction(db, async (transaction) => {
    for (const subscription of subscriptions) {
      if (subscription.status === 'temp') {
        transaction.delete(doc(db, `subscriptions/${subscription.id}`));
      }
    }

    transaction.set(doc(db, `resignations/${userId}`), {
      reason: reasons,
      createdAt: new Date(),
    });

    transaction.delete(doc(db, `users/${userId}/settings/paymentMethod`));

    transaction.delete(doc(db, `users/${userId}`));
  });

  const hosts = await getHosts(userId);
  hosts.hosts.forEach(async (host) => {
    removeHost(userId, host.id);
  });

  await auth.currentUser?.delete();

  return true;
}
