import {
  WorkspaceCardWrapper,
  WorkspaceContainer,
  WorkspaceTitle,
} from 'pages/workspace/Workspace.styles';
import SettingAnalyticsApi from 'pages/workspace/settings/SettingAnalyticsApi';
import SettingMenu from 'pages/workspace/settings/SettingMenu';
import SettingPayments from 'pages/workspace/settings/SettingPayment';
import SettingProfile from 'pages/workspace/settings/SettingProfile';
import SettingResign from 'pages/workspace/settings/SettingResign';
import { useLocation } from 'react-router-dom';

export type TSettingMenuItem = {
  default?: boolean;
  param?: string;
  label: string;
  onClick?: () => void;
};

export const settingMenuItems: TSettingMenuItem[] = [
  {
    default: true,
    param: 'profile',
    label: '프로필 설정',
  },
  {
    param: 'payments',
    label: '결제 관리',
  },
  {
    param: 'analytics-api',
    label: '분석툴 API',
  },
  {
    param: 'resign',
    label: '회원 탈퇴',
  },
];

export default function WorkspaceSettings() {
  const location = useLocation();
  const tab = new URLSearchParams(location.search).get('tab');
  return (
    <WorkspaceContainer>
      <WorkspaceTitle>계정설정</WorkspaceTitle>
      <WorkspaceCardWrapper>
        <SettingMenu settingMenuItems={settingMenuItems} />
        {(tab === 'profile' || !tab) && (
          <SettingProfile {...settingMenuItems[0]} />
        )}
        {tab === 'payments' && <SettingPayments {...settingMenuItems[1]} />}
        {tab === 'analytics-api' && (
          <SettingAnalyticsApi {...settingMenuItems[2]} />
        )}
        {tab === 'resign' && <SettingResign {...settingMenuItems[4]} />}
      </WorkspaceCardWrapper>
    </WorkspaceContainer>
  );
}
