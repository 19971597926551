import styled, { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export const pcWidth = '1440px';

// hide scrollbar
const GlobalStyles = createGlobalStyle`
  ${reset}

  html::-webkit-scrollbar {
    display: none;
  }

  html {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  
  #root {
    font-family: 'Noto Sans KR', sans-serif;
    background-color: ${(props) => props.theme.colors.white.main};
    min-height: 100vh;
    position: relative;
    width: 100%;
  }
  a{
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }
  .hidden {
    display: none;
  }
  .rotated {
    transform: rotate(180deg);
  }

  .mobile_hidden {
    @media screen and (max-width: ${pcWidth}) {
      display: none;
    }
  }

  .pc_hidden {
    @media screen and (min-width: ${pcWidth}) {
      display: none;
    }
  }
`;

export const Blank = styled.div<{
  $width?: string;
  $height?: string;
  $margin?: string;
  $padding?: string;
  $bgColor?: string;
  $mobileWidth?: string;
  $mobileHeight?: string;
}>`
  width: ${(props) => (props.$width ? props.$width : '0')};
  height: ${(props) => (props.$height ? props.$height : '0')};
  margin: ${(props) => (props.$margin ? props.$margin : '0')};
  padding: ${(props) => (props.$padding ? props.$padding : '0')};
  background-color: ${(props) =>
    props.$bgColor ? props.$bgColor : 'transparent'};

  @media screen and (max-width: ${pcWidth}) {
    width: ${(props) =>
      props.$mobileWidth ? props.$mobileWidth : props.$width};
    height: ${(props) =>
      props.$mobileHeight ? props.$mobileHeight : props.$height};
  }
`;

export const ResponsiveFlex = styled.div<{
  $justifyContent?: string;
  $alignItems?: string;
  $gap?: string;
  $width?: string;
  $height?: string;
  $flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';

  $mobileJustifyContent?: string;
  $mobileAlignItems?: string;
  $mobileGap?: string;
  $mobileWidth?: string;
  $mobileHeight?: string;
  $mobileFlexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
}>`
  display: flex;
  flex-direction: ${(props) =>
    props.$flexDirection ? props.$flexDirection : 'row'};
  justify-content: ${(props) =>
    props.$justifyContent ? props.$justifyContent : 'flex-start'};
  align-items: ${(props) =>
    props.$alignItems ? props.$alignItems : 'flex-start'};
  gap: ${(props) => (props.$gap ? props.$gap : '0')};
  width: ${(props) => (props.$width ? props.$width : 'auto')};
  height: ${(props) => (props.$height ? props.$height : 'auto')};

  @media screen and (max-width: ${pcWidth}) {
    justify-content: ${(props) =>
      props.$mobileJustifyContent
        ? props.$mobileJustifyContent
        : props.$justifyContent};
    align-items: ${(props) =>
      props.$mobileAlignItems ? props.$mobileAlignItems : props.$alignItems};
    gap: ${(props) => (props.$mobileGap ? props.$mobileGap : props.$gap)};
    width: ${(props) =>
      props.$mobileWidth ? props.$mobileWidth : props.$width};
    height: ${(props) =>
      props.$mobileHeight ? props.$mobileHeight : props.$height};
    flex-direction: ${(props) =>
      props.$mobileFlexDirection
        ? props.$mobileFlexDirection
        : props.$flexDirection};
  }
`;

export const FlexColumn = styled.div<{
  $justifyContent?: string;
  $alignItems?: string;
  $gap?: string;
  width?: string;
  height?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.$justifyContent ? props.$justifyContent : 'flex-start'};
  align-items: ${(props) =>
    props.$alignItems ? props.$alignItems : 'flex-start'};
  gap: ${(props) => (props.$gap ? props.$gap : '0')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
`;

export const FlexRow = styled.div<{
  $justifyContent?: string;
  $alignItems?: string;
  $gap?: string;
  width?: string;
  height?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.$justifyContent ? props.$justifyContent : 'flex-start'};
  align-items: ${(props) =>
    props.$alignItems ? props.$alignItems : 'flex-start'};
  gap: ${(props) => (props.$gap ? props.$gap : '0')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
`;

export const Divider = styled.div<{
  $direction?: 'horizontal' | 'vertical';
  $margin?: string;
  $padding?: string;
  $bgColor?: string;
}>`
  width: ${(props) => (props.$direction === 'horizontal' ? '100%' : '1px')};
  height: ${(props) => (props.$direction === 'vertical' ? '100%' : '1px')};
  margin: ${(props) => (props.$margin ? props.$margin : '0')};
  padding: ${(props) => (props.$padding ? props.$padding : '0')};
  background-color: ${(props) => (props.$bgColor ? props.$bgColor : '#D9D9D9')};
`;

export const Tag = styled.div<{
  color: 'primary';
  padding?: string;
  margin?: string;
}>`
  width: fit-content;
  background-color: ${(props) => props.theme.colors[props.color][200]};
  color: ${(props) => props.theme.colors[props.color].main};
  padding: ${(props) => (props.padding ? props.padding : '4px 12px')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  border-radius: 5px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 21px;
`;

export const Logo = styled.img<{
  height?: string;
  cursor?: string;
}>`
  cursor: ${(props) => (props.cursor ? props.cursor : 'default')};
  height: ${(props) => (props.height ? props.height : '36px')};
  margin-right: 10px;
`;

export default GlobalStyles;
