import {
  AdminSidebarItemContainer,
  AdminSidebarItemIcon,
  AdminSidebarItemText,
} from 'layouts/admin/components/AdminSidebar/AdminSidebar.styles';
import { ReactNode } from 'react';

interface AdminSidebarItemProps {
  icon: ReactNode;
  text: string;
  path: string;
  $active?: boolean;
  $exclude?: boolean;
}

export default function AdminSidebarItem({
  icon,
  text,
  path,
  $active: active,
  $exclude: exclude,
}: AdminSidebarItemProps) {
  return (
    <AdminSidebarItemContainer $active={active} to={path} $exclude={exclude}>
      <AdminSidebarItemIcon>{icon}</AdminSidebarItemIcon>
      <AdminSidebarItemText>{text}</AdminSidebarItemText>
    </AdminSidebarItemContainer>
  );
}
