function MobileSection9() {
  return (
    <section className="h-full w-full bg-white px-4">
      <div className="mx-auto flex flex-col items-start justify-between gap-12 px-4 py-12">
        <div className="flex flex-col items-start justify-start gap-2 pt-[40px]">
          <div className="gap-2">
            <p className="text-left font-suit text-[14px] font-semibold leading-[22.4px] tracking-[-0.02em] text-[#00000099]">
              업무 진행 방향의 근거를 데이터로 증명합니다
            </p>
            <h3 className="text-left font-suit text-2xl font-semibold leading-[42px] tracking-[-0.02em] text-[#000000E5]">
              실시간으로 데이터 수집 및 추적
              <br />
              가능한 애널리틱스 대시보드
            </h3>
          </div>

          <pre className="text-wrap text-left font-suit text-[16px] font-normal leading-[25.6px] tracking-[-0.02em] text-[#000000CC]">
            마케터하이어의 마케터는
            <span className="text-left font-suit text-[16px] font-bold leading-[25.6px] tracking-[-0.02em] text-[#000000CC]">
              플랫폼 전용 애널리틱스 대시보드를 활용
            </span>
            하여
            <span className="text-left font-suit text-[16px] font-bold leading-[25.6px] tracking-[-0.02em] text-[#000000CC]">
              논리적으로 가설을 세우고 검증
            </span>
            합니다. 그 모든 과정을 투명하게 확인하세요.
          </pre>
        </div>

        <img
          src="/img/box/section9-box1.png"
          alt="section9-box1"
          className="w-full"
        />
      </div>
    </section>
  );
}

export default MobileSection9;
