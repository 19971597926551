// @ts-nocheck
import { ScrollProvider } from 'landing/contexts/ScrollContext';
import HeroSection from 'landing/desktop_sections/HeroSection';
import HeroSection10 from 'landing/desktop_sections/HeroSection10';
import HeroSection11 from 'landing/desktop_sections/HeroSection11';
import HeroSection12 from 'landing/desktop_sections/HeroSection12';
import HeroSection14 from 'landing/desktop_sections/HeroSection14';
import HeroSection15 from 'landing/desktop_sections/HeroSection15';
import HeroSection2 from 'landing/desktop_sections/HeroSection2';
import HeroSection3 from 'landing/desktop_sections/HeroSection3';
import HeroSection5 from 'landing/desktop_sections/HeroSection5';
import HeroSection6 from 'landing/desktop_sections/HeroSection6';
import HeroSection7 from 'landing/desktop_sections/HeroSection7';
import HeroSection9 from 'landing/desktop_sections/HeroSection9';

export default function DesktopLanding() {
  return (
    <main className="pc:flex zero:hidden h-full w-full flex-col items-center justify-start">
      <ScrollProvider>
        <HeroSection />
        <HeroSection2 />
        <HeroSection3 />
        <HeroSection5 />
        <HeroSection6 />
        <HeroSection7 />
        <HeroSection9 />
        <HeroSection10 />
        <HeroSection11 />
        <HeroSection12 />
        <HeroSection14 />
        <HeroSection15 />
      </ScrollProvider>
    </main>
  );
}
