import {
  CustomButton,
  FileInputWrapper,
  HiddenInput,
} from 'components/Form/FileInput/FileInput.styles';
import { useRef } from 'react';
import { Control, useController } from 'react-hook-form';
interface FileInputProps {
  control: Control<any>;
  name: string;
  rules?: any;
}

const FileInput: React.FC<FileInputProps> = ({ control, name, rules }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const {
    field: { ref, onChange, value, ...inputProps },
  } = useController({
    name,
    control,
    rules,
    defaultValue: null,
  });

  return (
    <FileInputWrapper>
      <HiddenInput
        multiple
        type="file"
        ref={(e) => {
          inputRef.current = e;
          ref(e); // useController에서 반환된 ref를 설정
        }}
        onChange={(e) => {
          if (e.target.files) onChange(Array.from(e.target.files));
        }}
        {...inputProps}
      />
      <CustomButton
        type="button"
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.click();
          }
        }}
      >
        파일 선택
      </CustomButton>
    </FileInputWrapper>
  );
};

export default FileInput;
