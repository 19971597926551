import { Timestamp, doc, updateDoc } from 'firebase/firestore';
import { auth, db } from 'utils/firebase';

export default async function updateLastVisit() {
  const user = auth.currentUser;
  if (!user) throw new Error('User not found');

  await updateDoc(doc(db, 'users', user.uid), {
    lastVisitAt: Timestamp.now(),
  });
}
