import styled from 'styled-components';

export const SubscriptionCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  box-sizing: border-box;
  flex-flow: wrap;
  flex: 1;
`;

export const SubscriptionCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  width: 340px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white.main};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  &:hover {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const SubscriptionCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export const SubscriptionCardTitle = styled.h1`
  max-height: 64px;
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 두 줄로 제한 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SubscriptionCardSubtitle = styled.h2`
  font-size: 1rem;
  font-weight: 500;
  opacity: 0.9;
`;

export const SubscriptionCardDescription = styled.p`
  font-size: 1rem;
  font-weight: 500;
  opacity: 0.7;
`;

export const SubscriptionCardButtonStyle = styled.button`
  width: 100%;
  height: 40px;
  border: 1px solid ${(props) => props.theme.colors.primary.main};
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.primary.main};
  color: ${(props) => props.theme.colors.white.main};
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary.dark};
  }

  &.temp {
    background-color: ${(props) => props.theme.colors.white.main};
    color: ${(props) => props.theme.colors.black.main};
    border: 1px solid ${(props) => props.theme.colors.black.main};

    &:hover {
      background-color: ${(props) => props.theme.colors.white.dark};
    }
  }

  &.delete {
    background-color: ${(props) => props.theme.colors.error.main};
    border: 1px solid ${(props) => props.theme.colors.error.main};

    &:hover {
      background-color: ${(props) => props.theme.colors.error.dark};
      border: 1px solid ${(props) => props.theme.colors.error.dark};
    }
  }

  &.pending {
    background-color: ${(props) => props.theme.colors.error.main};
    border: 1px solid ${(props) => props.theme.colors.error.main};

    &:hover {
      background-color: ${(props) => props.theme.colors.error.dark};
      border: 1px solid ${(props) => props.theme.colors.error.dark};
    }
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.gray.main};
    border: 1px solid ${(props) => props.theme.colors.gray.main};
    color: ${(props) => props.theme.colors.white.main};
    cursor: not-allowed;
  }
`;
