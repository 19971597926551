import { Blank } from 'Globalstyles';
import { TUserProfile } from 'apis/@types';
import Avatar from 'components/Avatar/Avatar';

import useStorageUrl from 'hooks/useStorageUrl';
import {
  WorkspaceCard,
  WorkspaceCardOutlineButton,
  WorkspaceCardTitle,
} from 'pages/workspace/Workspace.styles';

import { Link } from 'react-router-dom';

export default function ProfileCard({
  userProfile,
}: {
  userProfile: TUserProfile | null;
}) {
  const profileImageUrl = useStorageUrl(userProfile?.profileImage);

  return (
    <WorkspaceCard $padding="40px 24px" $width="280px">
      <Avatar alt="profile" size="152px" src={profileImageUrl} />
      <Blank $height="24px" />
      <WorkspaceCardTitle>{userProfile?.name} 님</WorkspaceCardTitle>
      <Blank $height="24px" />
      <Link to="/workspace/settings" style={{ width: '100%' }}>
        <WorkspaceCardOutlineButton>프로필 수정</WorkspaceCardOutlineButton>
      </Link>
    </WorkspaceCard>
  );
}
