import getUserProfile from 'apis/common/getUserProfile';
import { doc, updateDoc } from 'firebase/firestore';
import { deleteObject, ref, uploadBytes } from 'firebase/storage';
import { auth, db, storage } from 'utils/firebase';

export default async function updateProfileImage(file: File) {
  const user = auth.currentUser;
  if (!user) throw new Error('User not found');

  const userProfile = await getUserProfile(user.uid);
  const currentProfileImage = userProfile.profileImage;
  if (
    currentProfileImage &&
    !currentProfileImage.startsWith('http') &&
    !currentProfileImage.startsWith('https')
  ) {
    await deleteObject(ref(storage, currentProfileImage));
  }

  const fileRef = ref(storage, `users/${user.uid}/${file.name}`);
  await uploadBytes(fileRef, file);

  return await updateDoc(doc(db, 'users', user.uid), {
    profileImage: fileRef.fullPath,
  });
}
