import { Blank, Logo } from 'Globalstyles';
import { onAuthStateChanged } from 'firebase/auth';
import {
  AuthLayoutWrapper,
  AuthWrapper,
} from 'layouts/auth/auth_layout.styles';
import { pages } from 'pages';

import { useEffect } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { auth } from 'utils/firebase';
import logo from 'assets/logo.png';
import getUserProfile from 'apis/common/getUserProfile';
import logout from 'apis/auth/logout';

export default function AuthLayout() {
  const navigate = useNavigate();

  useEffect(() => {
    let unsubscribe: () => void;
    unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const profile = await getUserProfile(user.uid);
        if (profile.role === 'admin') {
          navigate(pages.AdminHome.path);
        } else if (profile.role === 'client') {
          navigate(pages.WorkspaceHome.path);
        } else if (profile.role === 'partner') {
          navigate(pages.WorkspaceHome.path);
        } else {
          await logout();
          navigate(pages.AuthLogin.path);
        }
      }
    });
    return unsubscribe;
  }, [navigate]);

  return (
    <AuthLayoutWrapper>
      <AuthWrapper>
        <Link to={'/'}>
          <Logo src={logo} height="64px" cursor="pointer" />
        </Link>
        <Blank $height="48px" />
        <Outlet />
      </AuthWrapper>
    </AuthLayoutWrapper>
  );
}
