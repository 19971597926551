import Calendar from 'components/Calendar/Calendar';
import subscriptionsToEvents from 'layouts/workspace/utils/subscriptionsToEvents';
import { SubscriptionsContext } from 'layouts/workspace/workspace_layout';

import {
  WorkspaceCard,
  WorkspaceCardWrapper,
  WorkspaceContainer,
  WorkspaceTitle,
} from 'pages/workspace/Workspace.styles';
import SubscriptionCard, {
  AddSubscriptionCard,
} from 'pages/workspace/components/SubscriptionCard/SubscriptionCard';
import { SubscriptionCardContainer } from 'pages/workspace/components/SubscriptionCard/SubscriptionCard.styles';
import { useContext } from 'react';

export default function WorkspaceSubscriptions() {
  const subscriptions = useContext(SubscriptionsContext);
  return (
    <WorkspaceContainer>
      <WorkspaceTitle>구독관리</WorkspaceTitle>
      <WorkspaceCardWrapper align="flex-start">
        <SubscriptionCardContainer>
          {subscriptions.map((subscription) => (
            <SubscriptionCard key={subscription.id} {...subscription} />
          ))}
          {subscriptions.length === 0 && <AddSubscriptionCard />}
        </SubscriptionCardContainer>
        <WorkspaceCard $width="400px">
          <Calendar events={subscriptionsToEvents(subscriptions)} />
        </WorkspaceCard>
      </WorkspaceCardWrapper>
    </WorkspaceContainer>
  );
}
