import { FlexRow } from 'Globalstyles';
import Chip from 'components/Chip/Chip';
import {
  SubscribeCardHeaderWrapper,
  SubscribeCardTitle,
} from 'pages/subscribe/Subscribe.styles';

interface SubscribeCardHeaderProps {
  step: number;
  totalSteps: number;
  title?: string;
}

export default function SubscribeCardHeader({
  step,
  totalSteps,
  title,
}: SubscribeCardHeaderProps) {
  return (
    <SubscribeCardHeaderWrapper>
      <FlexRow $justifyContent="space-between" width="100%">
        <Chip>⚡ 1분이면 작성 완료!</Chip>
        <p>
          {step} / {totalSteps}
        </p>
      </FlexRow>
      <SubscribeCardTitle>{title}</SubscribeCardTitle>
    </SubscribeCardHeaderWrapper>
  );
}
