import DesktopLanding from 'landing/DesktopLanding';
import MobileLanding from 'landing/MobileLanding';

export default function Landing() {
  return (
    <>
      <DesktopLanding />
      <MobileLanding />
    </>
  );
}
