import { TSubscription, getSubscriptionPlanLabel } from 'apis/@types';
import {
  SubscriptionCardWrapper,
  SubscriptionCardDescription,
  SubscriptionCardHeader,
  SubscriptionCardTitle,
  SubscriptionCardSubtitle,
} from './SubscriptionCard.styles';
import SubscriptionCardChip from 'pages/workspace/components/SubscriptionCard/SubscriptionCardChip';
import SubscriptionCardButton from 'pages/workspace/components/SubscriptionCard/SubscriptionCardButton';
import { useNavigate } from 'react-router-dom';
import { pages } from 'pages';
import theme from 'theme';
import { Blank, FlexRow } from 'Globalstyles';
import { FaPlus } from 'react-icons/fa6';

export default function SubscriptionCard({
  id,
  companyName,
  workName,
  industry,
  plan,
  startDate,
  endDate,
  paymentDate,
  reference,
  etc,
  status,
}: TSubscription) {
  return (
    <SubscriptionCardWrapper>
      <SubscriptionCardHeader>
        <SubscriptionCardChip status={status} />
        <SubscriptionCardSubtitle>{companyName}</SubscriptionCardSubtitle>
      </SubscriptionCardHeader>
      <SubscriptionCardTitle>{workName}</SubscriptionCardTitle>
      <SubscriptionCardDescription>
        {getSubscriptionPlanLabel(plan)}
      </SubscriptionCardDescription>
      <FlexRow $justifyContent="space-between" $alignItems="center">
        <SubscriptionCardDescription>
          {startDate} ~ {endDate ?? ''}
        </SubscriptionCardDescription>
        <SubscriptionCardDescription
          style={{ fontSize: '0.875rem', opacity: 0.5 }}
        >
          {paymentDate ? `결제일: ${paymentDate}` : ''}
        </SubscriptionCardDescription>
      </FlexRow>
      <SubscriptionCardButton id={id} status={status} />
    </SubscriptionCardWrapper>
  );
}

export function AddSubscriptionCard() {
  const navigate = useNavigate();
  return (
    <SubscriptionCardWrapper onClick={() => navigate(pages.Subscribe.path)}>
      <Blank $height="8px" />

      <FaPlus
        style={{
          alignSelf: 'center',
          backgroundColor: theme.colors.gray.main,
          padding: '8px',
          borderRadius: '50%',
        }}
        size={32}
        color={theme.colors.white.main}
      />
      <Blank $height="4px" />
      <SubscriptionCardSubtitle style={{ textAlign: 'center' }}>
        새로운 구독 신청하기
      </SubscriptionCardSubtitle>
      <Blank $height="8px" />
    </SubscriptionCardWrapper>
  );
}
