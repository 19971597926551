const theme = {
  colors: {
    primary: {
      main: '#795BF9',
      dark: '#5637DE',
      // light : rgb(132 106 244)
      light: '#846AF4',
      200: '#ECE9FF',
      300: '#DCD5FE',
    },
    black: {
      main: '#000000',
      light: '#1f1f1f',
    },
    white: {
      main: '#ffffff',
      dark: '#EDEDED',
    },
    gray: {
      main: '#e0e0e0',
      200: '#f5f5f5',
    },
    error: {
      main: '#FF1A27',
      dark: '#D40D12',
      light: '#FF555F',
      200: '#FFEAEA',
    },
    warning: {
      main: '#B8860B',
      200: '#FFF0A6',
    },
  },
};

export default theme;
