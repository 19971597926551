export default async function removeHost(userId: string, hostId: number) {
  const response = await fetch(
    `${process.env.REACT_APP_MARKETER_HIRE_SERVER_URL}/api/host/${hostId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'user-id': userId,
      },
    }
  );

  if (response.ok) {
    return;
  } else {
    throw new Error('Failed to remove host');
  }
}
