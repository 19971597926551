import { THost } from 'apis/analytics/@types';

export default async function createHost(
  userId: string,
  hostname: string
): Promise<THost> {
  const response = await fetch(
    `${process.env.REACT_APP_MARKETER_HIRE_SERVER_URL}/api/host`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'user-id': userId,
      },
      body: JSON.stringify({ hostname }),
    }
  );

  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Failed to create host');
  }
}
