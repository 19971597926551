import { FlexColumn } from 'Globalstyles';
import { SubscriptionIndustryCategories } from 'apis/@types';

import Dropdown from 'components/Form/Dropdown/Dropdown';
import { InputContainer, InputLabel, Input } from 'components/Form/Form.styles';
import { StepProps } from 'pages/subscribe/Subscribe';
import {
  SubscribeButton,
  SubscribeButtonContainer,
  SubscribeTempSaveButton,
} from 'pages/subscribe/Subscribe.styles';
import SubscribeCardHeader from 'pages/subscribe/SubscribeCardHeader';
import theme from 'theme';

export default function Step1({
  step,
  setStep,
  subscribeFormData,
  totalSteps,
  handleTempSave,
}: StepProps) {
  const { register, watch, control } = subscribeFormData;

  return (
    <FlexColumn $gap="40px">
      <SubscribeCardHeader
        step={step}
        totalSteps={totalSteps}
        title={`신청서를 작성하고 실력있는 \n전담 마케터와 일해보세요`}
      />
      <InputContainer $gap={12}>
        <InputLabel
          htmlFor="companyName"
          color={theme.colors.black.main}
          required
        >
          회사명
        </InputLabel>
        <Input
          {...register('companyName')}
          placeholder="마케터하이어"
          id="companyName"
          color={theme.colors.black.main}
          $borderColor={theme.colors.gray.main}
        />
      </InputContainer>

      <InputContainer $gap={12}>
        <InputLabel htmlFor="workName" color={theme.colors.black.main} required>
          업무명
        </InputLabel>
        <Input
          {...register('workName')}
          placeholder="그로스 마케팅"
          id="workName"
          color={theme.colors.black.main}
          $borderColor={theme.colors.gray.main}
        />
      </InputContainer>

      <InputContainer $gap={12}>
        <InputLabel htmlFor="industry" color={theme.colors.black.main} required>
          산업군
        </InputLabel>

        <Dropdown
          id="industry"
          options={SubscriptionIndustryCategories}
          name="industry"
          control={control}
          placeholder="선택해주세요"
          color={theme.colors.black.main}
          borderColor={theme.colors.gray.main}
          maxHeight="480px"
        />
      </InputContainer>

      <SubscribeButtonContainer>
        <SubscribeTempSaveButton onClick={handleTempSave} type="button">
          임시저장
        </SubscribeTempSaveButton>
        <SubscribeButton
          type="button"
          disabled={
            !!watch('companyName') && !!watch('workName') && !!watch('industry')
              ? false
              : true
          }
          onClick={() => setStep(step + 1)}
        >
          다음 &gt;
        </SubscribeButton>
      </SubscribeButtonContainer>
    </FlexColumn>
  );
}
