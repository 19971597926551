import { AdminSidebarContainer } from 'layouts/admin/components/AdminSidebar/AdminSidebar.styles';
import AdminSidebarItem from 'layouts/admin/components/AdminSidebar/AdminSidebarItem';

import { pages } from 'pages';
import { FaCalendarCheck, FaHome, FaUser } from 'react-icons/fa';
import { TiBusinessCard } from 'react-icons/ti';
import { useLocation } from 'react-router-dom';

const items = {
  dashboard: {
    icon: <FaHome />,
    text: '대시보드',
    path: pages.AdminHome.path,
  },
  contacts: {
    icon: <TiBusinessCard />,
    text: '상담문의',
    path: pages.AdminContacts.path,
  },
  subscriptions: {
    icon: <FaCalendarCheck />,
    text: '구독관리',
    path: pages.AdminSubscriptions.path,
  },
  users: {
    icon: <FaUser />,
    text: '유저관리',
    path: pages.AdminUsers.path,
  },
};

export default function AdminSidebar() {
  const pathname = useLocation().pathname;

  return (
    <AdminSidebarContainer>
      {Object.entries(items).map(([key, value]) => (
        <AdminSidebarItem
          key={key}
          icon={value.icon}
          text={value.text}
          path={value.path}
          $active={pathname === value.path}
        />
      ))}
    </AdminSidebarContainer>
  );
}
