import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  background-color: ${(props) => props.theme.colors.black.main};
  width: 100%;
  height: 318px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 0;
  gap: 32px;
  bottom: 0px;
  position: absolute;
`;

export const FooterText = styled.span`
  display: inline-block;
  flex-direction: row;
  color: ${(props) => props.theme.colors.white.main};
  opacity: 0.7;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
`;

export const FooterTextBold = styled.strong`
  font-weight: 700;
  margin: 0 0.25rem;
`;
