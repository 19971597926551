import { FlexRow } from 'Globalstyles';
import { TSubscriptionStatus } from 'apis/@types';
import cancelSubscription from 'apis/subscription/cancelSubscription';
import deleteSubscription from 'apis/subscription/deleteSubscription';
import { pages } from 'pages';
import { SubscriptionCardButtonStyle } from 'pages/workspace/components/SubscriptionCard/SubscriptionCard.styles';
import { useNavigate } from 'react-router-dom';

export default function SubscriptionCardButton({
  id,
  status,
}: {
  id?: string;
  status: TSubscriptionStatus;
}) {
  const navigate = useNavigate();

  if (!id) return null;
  switch (status) {
    case 'temp':
      return (
        <FlexRow $gap="8px">
          <SubscriptionCardButtonStyle
            className="temp"
            onClick={() => navigate(`${pages.Subscribe.path}/${id}`)}
          >
            이어서 작성
          </SubscriptionCardButtonStyle>
          <SubscriptionCardButtonStyle
            className="delete"
            onClick={() => deleteSubscription(id)}
          >
            삭제
          </SubscriptionCardButtonStyle>
        </FlexRow>
      );
    case 'pending':
      return (
        <SubscriptionCardButtonStyle
          className="pending"
          onClick={() =>
            cancelSubscription(id).then(() =>
              navigate(`${pages.Subscribe.path}/${id}`)
            )
          }
        >
          취소 후 재작성
        </SubscriptionCardButtonStyle>
      );
    case 'matched':
      return (
        <SubscriptionCardButtonStyle>요금제 변경</SubscriptionCardButtonStyle>
      );
    case 'end':
      return (
        <SubscriptionCardButtonStyle disabled>종료</SubscriptionCardButtonStyle>
      );
  }
}
