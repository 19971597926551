import getWeeklyVisitors, {
  TWeeklyVisitors,
} from 'apis/analytics/getWeeklyVisitors';
import { WorkspaceCard } from 'pages/workspace/Workspace.styles';
import { useEffect, useState } from 'react';
import { Bar, BarChart, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { auth } from 'utils/firebase';

export default function WeeklyVisitors() {
  const [weeklyVisitors, setWeeklyVisitors] = useState<TWeeklyVisitors[]>([]);
  console.log(weeklyVisitors);

  useEffect(() => {
    if (auth.currentUser) {
      getWeeklyVisitors(auth.currentUser.uid).then((data) => {
        setWeeklyVisitors(data);
      });
    }
  }, []);
  return (
    <WorkspaceCard
      $alignItems="start"
      $padding="0 20px"
      $justifyContent="center"
    >
      <BarChart data={weeklyVisitors} width={800} height={400}>
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="visitorCount"
          fill="#FFABAB"
          barSize={28}
          name={'총 유입'}
        />
        <Bar
          dataKey="pcVisitorCount"
          fill="#A2C8EC"
          barSize={20}
          name={'PC유입'}
        />
        <Bar
          dataKey="mobileVisitorCount"
          fill="#77DD77"
          barSize={20}
          name={'모바일유입'}
        />
      </BarChart>
    </WorkspaceCard>
  );
}
