import { FaCircleUser } from 'react-icons/fa6';
import theme from 'theme';

interface AvatarProps {
  src?: string;
  size?: string;
  border?: string;
  borderColor?: string;
  borderRadius?: string;
  alt?: string;
  onClick?: () => void;
}

export default function Avatar({
  src,
  size = '30px',
  border = 'none',
  borderColor = 'none',
  borderRadius = '50%',
  alt = 'avatar',
  onClick,
}: AvatarProps) {
  if (!src)
    return (
      <FaCircleUser
        style={{
          width: size,
          height: size,
          border: border,
          borderColor: borderColor,
          borderRadius: borderRadius,
          color: theme.colors.gray.main,
        }}
        onClick={onClick}
      />
    );
  return (
    <img
      src={src}
      alt={alt}
      style={{
        width: size,
        height: size,
        border: border,
        borderColor: borderColor,
        borderRadius: borderRadius,
      }}
      onClick={onClick}
    />
  );
}
