import { FlexColumn } from 'Globalstyles';
import { SubscriptionPlanCategories } from 'apis/@types';
import DatePicker from 'components/Form/DatePicker/DatePicker';

import Dropdown from 'components/Form/Dropdown/Dropdown';
import { InputContainer, InputLabel } from 'components/Form/Form.styles';
import { StepProps } from 'pages/subscribe/Subscribe';
import {
  SubscribeButton,
  SubscribeButtonContainer,
  SubscribeTempSaveButton,
} from 'pages/subscribe/Subscribe.styles';
import ApplyCardHeader from 'pages/subscribe/SubscribeCardHeader';
import theme from 'theme';
import addDaysExecptWeekends from 'utils/date/addDaysExecptWeekends';

export default function Step2({
  step,
  setStep,
  subscribeFormData,
  totalSteps,
  handleTempSave,
}: StepProps) {
  const { watch, control } = subscribeFormData;

  // minDate : 오늘부터 3일 이후 부터 선택 가능
  const minDate = addDaysExecptWeekends(new Date(), 3);

  // maxDate : 오늘부터 1달 이후까지 선택 가능
  const maxDate = addDaysExecptWeekends(new Date(), 30);

  const onlyFreePlanCategories = SubscriptionPlanCategories.map((category) => {
    if (category.value !== 'free') {
      return { ...category, disabled: true };
    }
    return category;
  });

  return (
    <FlexColumn $gap="40px">
      <ApplyCardHeader
        step={step}
        totalSteps={totalSteps}
        title="언제부터 시작해 볼까요?"
      />
      <InputContainer $gap={12}>
        <InputLabel htmlFor="plan" color={theme.colors.black.main} required>
          희망 업무 요금제
        </InputLabel>
        <Dropdown
          id="plan"
          options={onlyFreePlanCategories}
          name="plan"
          control={control}
          placeholder="요금제 선택"
          color={theme.colors.black.main}
          borderColor={theme.colors.gray.main}
        />
      </InputContainer>

      <InputContainer $gap={12}>
        <InputLabel
          htmlFor="startDate"
          color={theme.colors.black.main}
          required
        >
          희망 업무 시작일
        </InputLabel>

        <DatePicker
          control={control}
          name="startDate"
          placeholder="YYYY-MM-DD"
          color={theme.colors.black.main}
          borderColor={theme.colors.gray.main}
          minDate={minDate}
          maxDate={maxDate}
          filterDate={(date) => date.getDay() !== 0 && date.getDay() !== 6}
        />
      </InputContainer>

      <SubscribeButtonContainer>
        <SubscribeTempSaveButton onClick={handleTempSave} type="button">
          임시저장
        </SubscribeTempSaveButton>
        <SubscribeButton as="div" onClick={() => setStep(step - 1)}>
          &lt; 이전
        </SubscribeButton>
        <SubscribeButton
          type="button"
          disabled={watch('plan') && watch('startDate') ? false : true}
          onClick={() => setStep(step + 1)}
        >
          다음 &gt;
        </SubscribeButton>
      </SubscribeButtonContainer>
    </FlexColumn>
  );
}
