import { pcWidth } from 'Globalstyles';
import styled from 'styled-components';

interface SectionBackgroundProps {
  $bgColor: 'black' | 'lightblack' | 'white' | 'darkwhite';
  $marginTop?: string;
  $marginBottom?: string;
  $mobileMarginTop?: string;
  $mobileMarginBottom?: string;
}

export const SectionBackground = styled.section<SectionBackgroundProps>`
  background-color: ${(props) => {
    switch (props.$bgColor) {
      case 'black':
        return props.theme.colors.black.main;
      case 'lightblack':
        return props.theme.colors.black.light;
      case 'white':
        return props.theme.colors.white.main;
      case 'darkwhite':
        return props.theme.colors.white.dark;
      default:
        return 'white';
    }
  }};
  overflow: hidden;
  color: white;
  padding-top: ${(props) => (props.$marginTop ? props.$marginTop : '0')};
  padding-bottom: ${(props) =>
    props.$marginBottom ? props.$marginBottom : '0'};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${pcWidth}) {
    padding-top: ${(props) =>
      props.$mobileMarginTop ? props.$mobileMarginTop : '0'};
    padding-bottom: ${(props) =>
      props.$mobileMarginBottom ? props.$mobileMarginBottom : '0'};
  }
`;

export const MobileNewLine = styled.br`
  display: none;
  @media screen and (max-width: ${pcWidth}) {
    display: block;
  }
`;

export const PcNewLine = styled.br`
  display: block;
  @media screen and (max-width: ${pcWidth}) {
    display: none;
  }
`;

export const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
`;

interface SectionTextProps {
  color: 'primary' | 'black' | 'white';
  opacity?: number;
  $align?: 'left' | 'center' | 'right';
  $mobileAlign?: 'left' | 'center' | 'right';
}

const SectionText = styled.p<SectionTextProps>`
  color: ${(props) => {
    switch (props.color) {
      case 'primary':
        return props.theme.colors.primary.main;
      case 'black':
        return props.theme.colors.black.main;
      case 'white':
        return props.theme.colors.white.main;
      default:
        return 'white';
    }
  }};
  opacity: ${(props) => (props.opacity ? props.opacity : 1)};
  text-align: ${(props) => (props.$align ? props.$align : 'left')};

  @media screen and (max-width: ${pcWidth}) {
    text-align: ${(props) =>
      props.$mobileAlign ? props.$mobileAlign : props.$align};
  }
`;

export const SectionTitle = styled(SectionText)`
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.35;

  @media screen and (max-width: ${pcWidth}) {
    font-size: 2rem;
  }
`;

export const SectionSubTitle = styled(SectionText)`
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.35;

  @media screen and (max-width: ${pcWidth}) {
    font-size: 1.75rem;
    text-align: center;
  }
`;

export const SectionContentTitle = styled(SectionText)`
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.35;

  @media screen and (max-width: ${pcWidth}) {
    font-size: 1.5rem;
  }
`;

export const SectionLargeContent = styled(SectionText)`
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.6;

  @media screen and (max-width: ${pcWidth}) {
    font-size: 1.25rem;
  }
`;

export const SectionContent = styled(SectionText)`
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6;

  @media screen and (max-width: ${pcWidth}) {
    font-size: 1rem;
  }
`;

export const SectionContentDescription = styled(SectionText)`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
`;

export const SectionLinkButton = styled.a<{ $fullWidth?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 1.125rem;
  text-decoration: none;
  cursor: pointer;
  color: white;
  background-color: ${(props) => props.theme.colors.primary.main};
  padding: 16px 24px;
  border-radius: 8px;
  border: none;
  box-sizing: border-box;
  transition: background-color 0.3s;
  width: ${(props) => (props.$fullWidth ? '100%' : 'auto')};
  text-align: center;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary.dark};
  }

  @media screen and (max-width: ${pcWidth}) {
    padding: 16px 24px;
    font-size: 1rem;
  }
`;

export const SectionFormTitle = styled(SectionText)<{ required: boolean }>`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;

  &:after {
    margin-left: 4px;
    content: ${(props) => (props.required ? '"*"' : '')};
    color: ${(props) => props.theme.colors.primary.main};
  }
`;

export const SectionFormInput = styled.input`
  font-size: 1rem;
  padding: 12px 16px;
  border-radius: 8px;
  box-sizing: border-box;
  line-height: 24px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.6);
  color: white;
`;

export const SectionErrorMessage = styled.p`
  font-size: 0.875rem;
  color: red;
  opacity: 0.7;
`;

export const PcImage = styled.img`
  display: block;
  height: auto;
  margin: 0 auto;

  @media screen and (max-width: ${pcWidth}) {
    display: none;3
  }
`;

export const NumberCircle = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary.main};
  color: white;
  font-size: 1.25rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;

  @media screen and (max-width: ${pcWidth}) {
    display: flex;
  }
`;
