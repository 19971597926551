import { TScriptResponse } from 'apis/analytics/@types';

export default async function getScript(
  userId: string
): Promise<TScriptResponse> {
  const response = await fetch(
    `${process.env.REACT_APP_MARKETER_HIRE_SERVER_URL}/api/host/script`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'user-id': userId,
      },
    }
  );

  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Failed to fetch script');
  }
}
