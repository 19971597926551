import {
  TSubscription,
  TSubscribeForm,
  TSubscriptionIndustryValue,
  TSubscriptionPlanValue,
} from 'apis/@types';
import { Timestamp, doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import { auth, db, storage } from 'utils/firebase';
import generateUuid from 'utils/generateUuid';

type TSubscribeOption = {
  id?: string;
  temp?: boolean;
};

export default async function createSubscription(
  subscribeForm: TSubscribeForm,
  { id, temp }: TSubscribeOption
) {
  const uid = auth.currentUser?.uid;
  if (!uid) throw new Error('User not found');

  if (!id) id = generateUuid();

  const fileRefs = await Promise.all(
    subscribeForm.reference.map(async (file) => {
      if (typeof file === 'string') return file;
      const fileRef = ref(storage, `subscriptions/${id}/${file.name}`);
      await uploadBytes(fileRef, file);
      return fileRef.fullPath;
    })
  );

  const endDate = null;
  const paymentDate = null;

  const subscription: TSubscription = {
    id,
    companyName: subscribeForm.companyName,
    workName: subscribeForm.workName,
    industry: subscribeForm.industry as TSubscriptionIndustryValue,
    plan: subscribeForm.plan as TSubscriptionPlanValue,
    startDate: subscribeForm.startDate,
    endDate,
    paymentDate,
    reference: fileRefs,
    etc: subscribeForm.etc,
    clientId: uid,
    status: temp ? 'temp' : 'pending',
    partners: [],
    createdAt: Timestamp.now(),
  };

  return await setDoc(doc(db, 'subscriptions', id), subscription);
}
