import { Divider, FlexRow } from 'Globalstyles';
import {
  TSubscription,
  TSubscriptionStatus,
  TUserProfile,
  getSubscriptionPlanLabel,
} from 'apis/@types';
import changeStatus from 'apis/admin/subscriptions/matchedSubscription';
import {
  SubscriptionCardContainer,
  SubscriptionCardDescription,
  SubscriptionCardHeader,
  SubscriptionCardSubtitle,
  SubscriptionCardTitle,
  SubscriptionCardWrapper,
} from 'pages/workspace/components/SubscriptionCard/SubscriptionCard.styles';
import SubscriptionCardChip from 'pages/workspace/components/SubscriptionCard/SubscriptionCardChip';
import styled from 'styled-components';

export const AdminSubscriptionCardButton = styled.button`
  width: 100%;
  height: 40px;
  border: 1px solid ${(props) => props.theme.colors.primary.main};
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.primary.main};
  color: ${(props) => props.theme.colors.white.main};
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
`;

interface SubscriptionContainerProps {
  subscriptions: TSubscription[];
  users: TUserProfile[];
  status: TSubscriptionStatus;
}

export default function SubscriptionContainer({
  subscriptions,
  users,
  status,
}: SubscriptionContainerProps) {
  return (
    <SubscriptionCardContainer>
      {subscriptions
        .filter((s) => s.status === status)
        .map((s) => {
          const user = users.find((u) => u.id === s.clientId);
          const {
            companyName,
            workName,
            plan,
            startDate,
            endDate,
            paymentDate,
            industry,
            id,
          } = s;
          return (
            <SubscriptionCardWrapper>
              <SubscriptionCardHeader>
                <SubscriptionCardChip status={status} />
                <SubscriptionCardSubtitle>
                  {companyName}
                </SubscriptionCardSubtitle>
              </SubscriptionCardHeader>
              <SubscriptionCardTitle>{workName}</SubscriptionCardTitle>
              <SubscriptionCardDescription>
                {getSubscriptionPlanLabel(plan)}
              </SubscriptionCardDescription>
              <SubscriptionCardDescription>
                {industry}
              </SubscriptionCardDescription>
              <FlexRow $justifyContent="space-between" $alignItems="center">
                <SubscriptionCardDescription>
                  {startDate} ~ {endDate ?? ''}
                </SubscriptionCardDescription>
                <SubscriptionCardDescription
                  style={{ fontSize: '0.875rem', opacity: 0.5 }}
                >
                  {paymentDate ? `결제일: ${paymentDate}` : ''}
                </SubscriptionCardDescription>
              </FlexRow>
              <Divider $direction="horizontal" />
              <SubscriptionCardDescription>
                {user?.name}
              </SubscriptionCardDescription>
              <SubscriptionCardDescription>
                {user?.email}
              </SubscriptionCardDescription>
              <SubscriptionCardDescription>
                {user?.phone}
              </SubscriptionCardDescription>
              <FlexRow>
                {status === 'pending' && (
                  <AdminSubscriptionCardButton
                    onClick={() => id && changeStatus(id, 'matched')}
                  >
                    검토완료
                  </AdminSubscriptionCardButton>
                )}
                {status === 'matched' && (
                  <AdminSubscriptionCardButton
                    onClick={() => id && changeStatus(id, 'end')}
                  >
                    마감
                  </AdminSubscriptionCardButton>
                )}
              </FlexRow>
            </SubscriptionCardWrapper>
          );
        })}
    </SubscriptionCardContainer>
  );
}
