import { Logo } from 'Globalstyles';
import {
  Header,
  HeaderWrapper,
  Nav,
  NavLink,
  NavButton,
  HeaderRight,
} from 'layouts/landing/components/Navbar/Navbar.styles';
import logo from 'assets/logo.png';
import { pages } from 'pages';

export default function Navbar() {
  return (
    <Header>
      <HeaderWrapper>
        <NavLink href={pages.LandingHome.path} id="0705_gnb_inquiry_cv0">
          <Logo src={logo} alt="logo" cursor="pointer" height="42px" />
        </NavLink>
        <Nav>
          <NavLink href={pages.KakaoConsult.path} target="_blank">
            24시 즉시 상담
          </NavLink>
          <NavLink href={pages.ServiceIntroduction.path} target="_blank">
            서비스 소개
          </NavLink>
        </Nav>
        <HeaderRight>
          <NavLink href={pages.AuthLogin.path} id="gnb_login" onlyPc>
            로그인
          </NavLink>
          <NavButton href={pages.AuthSignup.path} id="gnb_cv0" onlyPc>
            회원가입
          </NavButton>
          <NavButton href={pages.AuthSignup.path} id="mognb_cv0" onlyMobile>
            회원가입
          </NavButton>
        </HeaderRight>
      </HeaderWrapper>
    </Header>
  );
}
