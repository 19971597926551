import Sidebar from 'layouts/workspace/components/Sidebar/Sidebar';
import { WorkspaceLayoutWrapper } from 'layouts/workspace/workspace.styles';
import WorkspaceHeader from 'layouts/workspace/components/WorkspaceHeader/WorkspaceHeader';
import { Outlet, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { createContext, useEffect, useState } from 'react';
import { auth } from 'utils/firebase';
import { pages } from 'pages';
import getUserProfile from 'apis/common/getUserProfile';

import { TSubscription, TUserProfile } from 'apis/@types';
import useClientSubscriptions from 'hooks/client/useMySubscriptions';

import updateLastVisit from 'apis/common/updateLastVisit';

export const UserContext = createContext<TUserProfile | null>(null);
export const SubscriptionsContext = createContext<TSubscription[]>([]);

export default function WorkspaceLayout() {
  const navigate = useNavigate();
  const [userId, setUserId] = useState<string | null>(null);
  const [userProfile, setUserProfile] = useState<TUserProfile | null>(null);
  const { subscriptions } = useClientSubscriptions(userId);

  useEffect(() => {
    function reloadUserProfile(uid?: string) {
      if (uid) {
        getUserProfile(uid).then((profile) => {
          profile.reload = () => reloadUserProfile(uid);
          setUserProfile(profile);
        });
      }
    }
    let unsubscribe: () => void;

    unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate(pages.LandingHome.path);
        return;
      }
      const profile = await getUserProfile(user.uid);
      if (profile.role === 'admin') {
        navigate(pages.AdminHome.path);
      }
      await updateLastVisit();
      setUserId(user.uid);
      profile.id = user.uid;
      profile.reload = () => reloadUserProfile(user.uid);
      setUserProfile(profile);
    });

    return unsubscribe;
  }, [navigate]);

  return userProfile ? (
    <UserContext.Provider value={userProfile}>
      <SubscriptionsContext.Provider value={subscriptions}>
        <WorkspaceLayoutWrapper>
          <Sidebar />
          <WorkspaceHeader />
          <Outlet />
        </WorkspaceLayoutWrapper>
      </SubscriptionsContext.Provider>
    </UserContext.Provider>
  ) : null;
}
