import { TSubscribeForm } from 'apis/@types';
import getPaymentMethod from 'apis/payment/getPaymentMethod';
import createSubscription from 'apis/subscription/createSubscription';
import getSubscription from 'apis/subscription/getSubscription';
import { pages } from 'pages';
import {
  SubscribeBackButton,
  SubscribeCard,
  SubscribeCardWrapper,
  SubscribeContainer,
  SubscribeHeader,
  SubscribeProgress,
  SubscribeProgressWrapper,
} from 'pages/subscribe/Subscribe.styles';
import Step1 from 'pages/subscribe/steps/Step1';
import Step2 from 'pages/subscribe/steps/Step2';
import Step3 from 'pages/subscribe/steps/Step3';
import { useEffect, useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import addDaysExecptWeekends from 'utils/date/addDaysExecptWeekends';
import { auth } from 'utils/firebase';

export type StepProps = {
  totalSteps: number;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  subscribeFormData: UseFormReturn<TSubscribeForm, any, undefined>;
  handleTempSave?: () => void;
};

export default function Subscribe() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const totalSteps = 3;

  const subscribeFormData = useForm<TSubscribeForm>({
    defaultValues: {
      companyName: '',
      workName: '',
      industry: '',
      plan: '',
      startDate: addDaysExecptWeekends(new Date(), 3)
        .toISOString()
        .split('T')[0],
      reference: [],
    },
  });

  const { handleSubmit, setValue } = subscribeFormData;

  const onSubmit = (data: TSubscribeForm) => {
    getPaymentMethod(auth.currentUser?.uid).then((paymentMethod) => {
      if (!paymentMethod) {
        alert('결제수단을 먼저 등록해주세요.');
        handleTempSave(`${pages.WorkspaceSettings.path}?tab=payments`);
      } else {
        createSubscription(data, { id }).then(() => {
          alert('신청이 완료되었습니다.');
          navigate(pages.WorkspaceSubscriptions.path);
        });
      }
    });
  };

  const handleTempSave = (path?: string) => {
    const data = subscribeFormData.getValues();

    createSubscription(data, {
      id,
      temp: true,
    }).then(() => {
      alert('임시 저장되었습니다.');
      navigate(path ?? pages.WorkspaceSubscriptions.path);
    });
  };

  useEffect(() => {
    if (id)
      getSubscription(id).then((data) =>
        Object.entries(data).forEach(([key, value]) => {
          setValue(key as any, value);
        })
      );
  }, [id, setValue]);

  return (
    <SubscribeContainer>
      <SubscribeHeader>
        <SubscribeProgressWrapper>
          <SubscribeProgress $progress={(step / totalSteps) * 100} />
        </SubscribeProgressWrapper>
        <SubscribeBackButton
          onClick={() => {
            navigate(-1);
          }}
        >
          &lt; 나가기
        </SubscribeBackButton>
      </SubscribeHeader>
      <SubscribeCardWrapper onSubmit={handleSubmit(onSubmit)}>
        <SubscribeCard order={step - 1}>
          <Step1
            totalSteps={totalSteps}
            step={step}
            setStep={setStep}
            subscribeFormData={subscribeFormData}
            handleTempSave={handleTempSave}
          />
        </SubscribeCard>
        <SubscribeCard order={step - 2}>
          <Step2
            totalSteps={totalSteps}
            step={step}
            setStep={setStep}
            subscribeFormData={subscribeFormData}
            handleTempSave={handleTempSave}
          />
        </SubscribeCard>
        <SubscribeCard order={step - 3}>
          <Step3
            totalSteps={totalSteps}
            step={step}
            setStep={setStep}
            subscribeFormData={subscribeFormData}
            handleTempSave={handleTempSave}
          />
        </SubscribeCard>
      </SubscribeCardWrapper>
    </SubscribeContainer>
  );
}
