import { Blank, FlexColumn } from 'Globalstyles';
import { resignClient } from 'apis/auth/resign';
import MultipleCheckbox from 'components/Form/Checkbox/MultipleCheckbox';
import { Button, InputLabel } from 'components/Form/Form.styles';
import useClientSubscriptions from 'hooks/client/useMySubscriptions';
import { pages } from 'pages';
import {
  WorkspaceCard,
  WorkspaceCardDescription,
  WorkspaceCardTitle,
} from 'pages/workspace/Workspace.styles';
import { TSettingMenuItem } from 'pages/workspace/WorkspaceSettings';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from 'theme';
import { auth } from 'utils/firebase';

type TReasonCheck = {
  checked: boolean;
  label: string;
};

const defaultResons: TReasonCheck[] = [
  {
    checked: false,
    label: '이용하고싶은 서비스가 없어요',
  },
  {
    checked: false,
    label: '서비스 퀄리티가 낮아요',
  },
  {
    checked: false,
    label: '잦은 오류가 발생해요',
  },
  {
    checked: false,
    label: '대체할만한 서비스를 찾았어요',
  },
  {
    checked: false,
    label: '다른 이유가 있어요',
  },
];

export default function SettingResign(settingMenuItem: TSettingMenuItem) {
  const { subscriptions } = useClientSubscriptions(auth.currentUser?.uid);
  const navigate = useNavigate();
  const [reasonChecks, setReasonChecks] = useState(defaultResons);

  const hasOngoingSubscription = subscriptions.some(
    (subscription) =>
      subscription.status === 'pending' || subscription.status === 'matched'
  );

  function handleClickResign() {
    if (window.confirm('정말 탈퇴하시겠습니까?')) {
      resignClient(
        reasonChecks
          .filter((check) => check.checked)
          .map((check) => check.label)
      )
        .then(() => {
          alert('탈퇴되었습니다.');
          navigate(pages.LandingHome.path);
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  }

  return (
    <WorkspaceCard $padding="40px 60px" $alignItems="start">
      <WorkspaceCardTitle>{settingMenuItem.label}</WorkspaceCardTitle>
      <WorkspaceCardDescription color={theme.colors.error.dark}>
        회원 탈퇴 시 모든 정보가 삭제되며, 복구가 불가능합니다.
      </WorkspaceCardDescription>
      <Blank $height="40px" />
      {!hasOngoingSubscription ? (
        <FlexColumn $gap="20px" width="100%">
          <InputLabel color="black">
            회원 탈퇴 사유를 선택해주세요. (다중 선택 가능)
          </InputLabel>

          <MultipleCheckbox
            checkboxItems={reasonChecks}
            setCheckboxItems={setReasonChecks}
          />
        </FlexColumn>
      ) : (
        <WorkspaceCardDescription>
          현재 진행 중인 구독이 있어 회원 탈퇴가 불가능합니다.
          <br />
          구독을 취소하신 후 다시 시도해주세요.
        </WorkspaceCardDescription>
      )}
      <Blank $height="40px" />
      <Button
        $color={theme.colors.error[200]}
        $backgroundColor={theme.colors.error.main}
        $hoverBackgroundColor={theme.colors.error.dark}
        onClick={handleClickResign}
        disabled={
          hasOngoingSubscription || !reasonChecks.some((check) => check.checked)
        }
        $fullWidth
      >
        회원 탈퇴
      </Button>
    </WorkspaceCard>
  );
}
