import {
  WorkspaceCardWrapper,
  WorkspaceContainer,
  WorkspaceTitle,
} from 'pages/workspace/Workspace.styles';
import AnalyticsMenu from 'pages/workspace/analytics/AnalyticsMenu';
import WeeklyVisitors from 'pages/workspace/analytics/WeeklyVisitors';
import { useState } from 'react';

export type TAnalyticsMenuItem = {
  id: number;
  label: string;
  image?: string;
};

const analyticsItems: TAnalyticsMenuItem[] = [
  {
    id: 0,
    label: '주차별 유입자 현황',
  },
];

export default function WorkspaceAnalytics() {
  const [selectedItem, setSelectedItem] = useState(0);
  return (
    <WorkspaceContainer>
      <WorkspaceTitle>분석</WorkspaceTitle>
      <WorkspaceCardWrapper>
        <AnalyticsMenu
          analyticsItems={analyticsItems}
          selectedItemId={selectedItem}
          setSelectedItem={setSelectedItem}
        />
        {selectedItem === 0 && <WeeklyVisitors />}
      </WorkspaceCardWrapper>
    </WorkspaceContainer>
  );
}
