import { TPaymentMethod } from 'apis/@types';
import { doc, getDoc } from 'firebase/firestore';
import { db } from 'utils/firebase';

export default async function getPaymentMethod(userId?: string) {
  const paymentDoc = await getDoc(
    doc(db, `users/${userId}/settings/paymentMethod`)
  );
  if (paymentDoc.exists()) {
    return paymentDoc.data() as TPaymentMethod;
  }
  return undefined;
}
