import { doc, getDoc } from 'firebase/firestore';
import getLastSundayString from 'utils/date/getLastSundayString';
import { db } from 'utils/firebase';

export type TWeeklyVisitors = {
  name?: string;
  mobileVisitorCount: number;
  pcVisitorCount: number;
  pageViewCount: number;
  visitorCount: number;
};

export default async function getWeeklyVisitors(userId: string) {
  const weeklyVisitors: TWeeklyVisitors[] = [];
  for (let i = 4; i >= 0; i--) {
    const date = getLastSundayString(i);
    const visitors = (
      await getDoc(doc(db, 'users', userId, 'weekly-visitors', date))
    ).data() as TWeeklyVisitors;

    if (!visitors) {
      weeklyVisitors.push({
        name: i === 0 ? '이번주' : i === 1 ? '지난주' : `${i}주 전`,
        mobileVisitorCount: 0,
        pcVisitorCount: 0,
        pageViewCount: 0,
        visitorCount: 0,
      });
    } else {
      weeklyVisitors.push({
        name: i === 0 ? '이번주' : i === 1 ? '지난주' : `${i}주 전`,
        mobileVisitorCount: visitors.mobileVisitorCount,
        pcVisitorCount: visitors.pcVisitorCount,
        pageViewCount: visitors.pageViewCount,
        visitorCount: visitors.visitorCount,
      });
    }
  }
  return weeklyVisitors;
}
