import useContacts from 'hooks/admin/useContacts';
import {
  AdminSubscriptionsContext,
  AdminUsersContext,
} from 'layouts/admin/admin_layout';
import { pages } from 'pages';
import { AdminContainer, AdminTitle } from 'pages/admin/Admin.styles';
import {
  AdminHomeCard,
  AdminHomeCardTitle,
  AdminHomeGrid,
} from 'pages/admin/AdminHome.styles';
import ContactsTable from 'pages/admin/components/ContactsTable';
import SubscriptionContainer from 'pages/admin/components/SubscriptionContainer';
import UsersTable from 'pages/admin/components/UsersTables';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export default function AdminHome() {
  const { contacts, handleCheckContact } = useContacts();
  const navigate = useNavigate();
  const subscriptions = useContext(AdminSubscriptionsContext);
  const users = useContext(AdminUsersContext);

  return (
    <AdminContainer>
      <AdminTitle>대시보드</AdminTitle>
      <AdminHomeGrid>
        <AdminHomeCard
          onClick={() => {
            navigate(pages.AdminContacts.path);
          }}
        >
          <AdminHomeCardTitle>상담문의</AdminHomeCardTitle>
          <ContactsTable
            contacts={contacts}
            handleCheckContact={handleCheckContact}
          />
        </AdminHomeCard>
        <AdminHomeCard
          onClick={() => {
            navigate(pages.AdminSubscriptions.path);
          }}
        >
          <AdminHomeCardTitle>구독 요청된 신청</AdminHomeCardTitle>
          <SubscriptionContainer
            subscriptions={subscriptions}
            users={users}
            status="pending"
          />
        </AdminHomeCard>
        <AdminHomeCard
          onClick={() => {
            navigate(`${pages.AdminSubscriptions.path}?status=matched`);
          }}
        >
          <AdminHomeCardTitle>구독 중인 매칭</AdminHomeCardTitle>
          <SubscriptionContainer
            subscriptions={subscriptions}
            users={users}
            status="matched"
          />
        </AdminHomeCard>
        <AdminHomeCard
          onClick={() => {
            navigate(`${pages.AdminSubscriptions.path}?status=end`);
          }}
        >
          <AdminHomeCardTitle>마감 매칭</AdminHomeCardTitle>
          <SubscriptionContainer
            subscriptions={subscriptions}
            users={users}
            status="end"
          />
        </AdminHomeCard>
        <AdminHomeCard
          onClick={() => {
            navigate(`${pages.AdminUsers.path}?role=client`);
          }}
        >
          <AdminHomeCardTitle>유저 관리</AdminHomeCardTitle>
          <UsersTable users={users.filter((u) => u.role === 'client')} />
        </AdminHomeCard>
      </AdminHomeGrid>
    </AdminContainer>
  );
}
