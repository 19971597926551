import styled from 'styled-components';

export const AuthLayoutWrapper = styled.div`
  min-height: 100vh;
  height: 100%;
  background-color: ${(props) => props.theme.colors.black.light};
`;

export const AuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.black.light};
  padding: 80px 0;
  width: 500px;
  margin: 0 auto;
`;
