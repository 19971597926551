import { useLocation, useNavigate } from 'react-router-dom';

export default function PaymentMethodFail() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const message = params.get('message');

  alert(message);
  setTimeout(() => {
    navigate(-1);
  }, 0);
  return <></>;
}
