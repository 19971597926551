import logout from 'apis/auth/logout';
import Avatar from 'components/Avatar/Avatar';
import {
  AdminHeaderApplyButton,
  AdminHeaderWrapper,
} from 'layouts/admin/components/AdminHeader/AdminHeader.styles';
import { pages } from 'pages';

import { useNavigate } from 'react-router-dom';

export default function AdminHeader() {
  const navigate = useNavigate();

  const handleLogout = () => {
    logout().then(() => {
      navigate(pages.LandingHome.path);
    });
  };

  return (
    <AdminHeaderWrapper>
      <Avatar />
      마케터하이어 님
      <AdminHeaderApplyButton onClick={handleLogout}>
        로그아웃
      </AdminHeaderApplyButton>
    </AdminHeaderWrapper>
  );
}
