import { pages } from 'pages';
import { Link } from 'react-router-dom';

function HeroSection14() {
  return (
    <section className="h-full w-full bg-[#0000000A]">
      <div className="mx-auto flex w-full max-w-[1440px] flex-col items-center justify-start px-[120px] py-[96px]">
        <div className="flex  flex-col items-center justify-start gap-4">
          <div className="gap-1">
            <p className="text-center font-suit text-[20px] font-medium leading-[32px] tracking-[-0.02em] text-[#00000099]">
              검증된 마케터 구독 서비스
            </p>
            <h4 className="text-center font-suit text-[40px] font-bold leading-[60px] tracking-[-0.02em] text-[#000000E5]">
              꼭 필요한 핵심 업무만 골라서,
              <br />
              숙련된 전문가의 역량과 경험을 그대로 쓰세요
            </h4>
          </div>
          <pre className="text-center font-suit text-[20px] font-normal leading-[32px] tracking-[-0.02em] text-[#000000CC]">
            단 1원의 마케팅 비용도 허투루 쓰지 마세요.
          </pre>
          <div className="mt-[44px] flex w-[362px] items-center justify-between gap-2">
            <Link
              id="0705_sec11_inquiry_cv0"
              to={pages.AuthLogin.path}
              className="flex w-[206px] items-center justify-center whitespace-nowrap rounded-[8px] bg-[#795BF9] px-6 py-3"
            >
              <span className="text-center font-suit text-[16px] font-bold leading-[26px] tracking-[0.45px] text-[#FFFFFF]">
                3일 무료! 마케터 구독
              </span>
            </Link>
            <Link
              id="0705_sec11_cv0"
              to={pages.KakaoConsult.path}
              target="_blank"
              className="w-[148px] whitespace-nowrap rounded-[8px] bg-[#795BF929] px-6 py-3"
            >
              <span className="text-center font-suit text-[16px] font-bold leading-[26px] tracking-[0.45px] text-[#795BF9]">
                24시 즉시 상담
              </span>
            </Link>
          </div>
          <div className="flex gap-7 w-full mt-10 justify-center">
            <div className="py-3 text-wrap px-6 bg-white rounded-lg font-bold">
              퍼포먼스 마케팅
            </div>
            <div className="py-3 text-wrap px-6 bg-white rounded-lg font-bold">
              콘텐츠 마케팅
            </div>
            <div className="py-3 text-wrap px-6 bg-white rounded-lg font-bold">
              그로스 마케팅
            </div>
            <div className="py-3 text-wrap px-6 bg-white rounded-lg font-bold">
              바이럴 마케팅
            </div>
            <div className="py-3 text-wrap px-6 bg-white rounded-lg font-bold">
              B2B 마케팅
            </div>
            <div className="py-3 text-wrap px-6 bg-white rounded-lg font-bold">
              SEO
            </div>
            <div className="py-3 text-wrap px-6 bg-white rounded-lg font-bold">
              구글/메타
            </div>
            <div className="py-3 text-wrap px-6 bg-white rounded-lg font-bold">
              네이버 마케팅
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection14;
