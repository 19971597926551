import { TSubscription } from 'apis/@types';

export default function subscriptionsToEvents(subscriptions: TSubscription[]) {
  const events = [];
  for (const subscription of subscriptions) {
    if (subscription.status === 'matched') {
      events.push({
        title: `[${subscription.workName}] 시작`,
        date: subscription.startDate,
      });
      if (subscription.endDate) {
        events.push({
          title: `[${subscription.workName}] 종료`,
          date: subscription.endDate,
        });
      }
      if (subscription.paymentDate) {
        events.push({
          title: `[${subscription.workName}] 결제`,
          date: subscription.paymentDate,
        });
      }
    }
  }
  return events;
}
