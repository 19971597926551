import { TSubscription, TUserProfile } from 'apis/@types';
import getUsers from 'apis/admin/getUsers';
import getUserProfile from 'apis/common/getUserProfile';
import updateLastVisit from 'apis/common/updateLastVisit';
import { onAuthStateChanged } from 'firebase/auth';
import useSubscriptions from 'hooks/admin/useSubscriptions';
import { AdminLayoutWrapper } from 'layouts/admin/admin.styles';
import AdminHeader from 'layouts/admin/components/AdminHeader/AdminHeader';
import AdminSidebar from 'layouts/admin/components/AdminSidebar/AdminSidebar';
import { pages } from 'pages';
import { createContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { auth } from 'utils/firebase';

export const AdminUsersContext = createContext<TUserProfile[]>([]);
export const AdminSubscriptionsContext = createContext<TSubscription[]>([]);

export default function AdminLayout() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [users, setUsers] = useState<TUserProfile[]>([]);
  const { subscriptions } = useSubscriptions();

  const navigate = useNavigate();
  useEffect(() => {
    let unsubscribe: () => void;
    getUsers().then((data) => setUsers(data));

    unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate(pages.LandingHome.path);
        return;
      }
      const profile = await getUserProfile(user.uid);
      if (profile.role !== 'admin') {
        navigate(pages.WorkspaceHome.path);
      }
      await updateLastVisit();
      setIsAuthenticated(true);
    });
    return unsubscribe;
  }, [navigate]);

  return isAuthenticated ? (
    <AdminUsersContext.Provider value={users}>
      <AdminSubscriptionsContext.Provider value={subscriptions}>
        <AdminLayoutWrapper>
          <AdminSidebar />
          <AdminHeader />
          <Outlet />
        </AdminLayoutWrapper>
      </AdminSubscriptionsContext.Provider>
    </AdminUsersContext.Provider>
  ) : null;
}
