import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div<{
  $gap?: number;
}>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.$gap || 16}px;
  width: 100%;
`;

export const InputLabel = styled.label<{
  required?: boolean;
  color?: string;
}>`
  font-size: 1rem;
  font-weight: 500;
  color: ${(props) => props.color || 'white'};
  opacity: 0.9;
  ${(props) =>
    props.required
      ? `
    &::after {
        content: '*';
        color: ${props.theme.colors.primary.main};
        }
    `
      : ''}
`;

export const Input = styled.input<{
  color?: string;
  backgroundColor?: string;
  $borderColor?: string;
  opacity?: number;
}>`
  padding: 14px 16px;
  border: 1px solid ${(props) => props.$borderColor || 'white'};
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 300;
  background-color: ${(props) => props.backgroundColor || 'transparent'};
  color: ${(props) => props.color || 'white'};
  opacity: ${(props) => props.opacity || 1};
  flex: 1;
  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.primary.main};
  }
  &:disabled {
    background-color: ${(props) => props.theme.colors.gray.main};
    color: ${(props) => props.theme.colors.black.main};
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

export const Button = styled.button<{
  $color?: string;
  $backgroundColor?: string;
  $hoverColor?: string;
  $hoverBackgroundColor?: string;
  $borderColor?: string;
  $disabledColor?: string;
  $disabledBackgroundColor?: string;
  $fullWidth?: boolean;
}>`
  width: ${(props) => (props.$fullWidth ? '100%' : 'auto')};
  padding: 14px 16px;
  border: 1px solid ${(props) => props.$borderColor || 'white'};
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 300;
  background-color: ${(props) => props.$backgroundColor || 'transparent'};
  color: ${(props) => props.$color || 'white'};
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.$hoverBackgroundColor || 'transparent'};
    color: ${(props) => props.$hoverColor || 'white'};
  }
  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.primary.main};
  }
  &:disabled {
    background-color: ${(props) =>
      props.$disabledBackgroundColor || props.theme.colors.gray.main};
    color: ${(props) => props.$disabledColor || 'white'};
    cursor: not-allowed;
  }
`;

export const ErrorMessage = styled.span`
  font-size: 0.875rem;
  color: ${(props) => props.theme.colors.error.light};
  opacity: 0.7;
`;
