import { SubscriptionsContext } from 'layouts/workspace/workspace_layout';
import {
  WorkspaceCardWrapper,
  WorkspaceContainer,
  WorkspaceTitle,
} from 'pages/workspace/Workspace.styles';
import ChatList from 'components/Chat/ChatList';
import ChatContainer from 'components/Chat/ChatContainer';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

export default function WorkspaceChats() {
  const subscriptions = useContext(SubscriptionsContext);
  const { search } = useLocation();
  const subscriptionId = new URLSearchParams(search).get('subscription');
  const selectedSubscription = subscriptions.find(
    (s) => s.id === subscriptionId
  );
  return (
    <WorkspaceContainer>
      <WorkspaceTitle>채팅</WorkspaceTitle>
      <WorkspaceCardWrapper>
        <ChatList subscriptions={subscriptions} />
        <ChatContainer subscription={selectedSubscription} />
      </WorkspaceCardWrapper>
    </WorkspaceContainer>
  );
}
