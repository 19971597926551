import { TUserProfile } from 'apis/@types';
import { doc, getDoc } from 'firebase/firestore';
import { db } from 'utils/firebase';

export default async function getUserProfile(
  uid: string
): Promise<TUserProfile> {
  const docRef = doc(db, 'users', uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data() as TUserProfile;
  } else {
    throw new Error('No such document!');
  }
}
