import { pages } from 'pages';
import { Link } from 'react-router-dom';

function SectionText({
  className,
  children,
}: {
  className: string;
  children: string | JSX.Element;
}) {
  return (
    <span className={`${className} text-center font-suit tracking-[-0.02em]`}>
      {children}
    </span>
  );
}

function MobileSection14() {
  const blocks = [
    '퍼포먼스 마케팅',
    '콘텐츠 마케팅',
    '그로스 마케팅',
    '바이럴 마케팅',
    'B2B 마케팅',
    'SEO',
    '구글/메타',
    '네이버 마케팅',
  ];

  return (
    <section className="h-full w-full bg-[#0000000A]">
      <div className="mx-auto flex w-full flex-col items-center justify-start px-4 py-12">
        <div className="flex w-full flex-col items-center justify-start gap-2">
          <div className="gap-1">
            <SectionText className="text-[14px] font-medium leading-[22.4px] text-[#00000099]">
              검증된 마케터 구독 서비스
            </SectionText>
            <h4 className="text-center font-suit text-2xl font-bold leading-[42px] text-[#000000E5]">
              꼭 필요한 핵심 업무만 골라서,
              <br />
              숙련된 전문가의 역량과 경험을
              <br />
              그대로 쓰세요
            </h4>
          </div>
          <pre className="text-center font-suit text-[16px] font-normal leading-[25.6px] text-[#000000CC]">
            단 1원의 마케팅 비용도 허투루 쓰지 마세요.
          </pre>

          <div className="mt-4 flex w-[206] flex-col items-center justify-between gap-2">
            <Link
              id="mo0705_sec11_cv0"
              to={pages.AuthLogin.path}
              className={`flex h-[50px] w-full items-center justify-center whitespace-nowrap rounded-[8px] bg-[#795BF9] px-6 py-3 text-[#FFFFFF]`}
            >
              <SectionText className="text-[16px] font-bold leading-[26px] tracking-[0.45px]">
                3일 무료! 마케터 구독
              </SectionText>
            </Link>

            <Link
              id="mo0705_sec11_inquiry_cv0"
              to={pages.KakaoConsult.path}
              target="_blank"
              className={`flex h-[50px] w-full items-center justify-center whitespace-nowrap rounded-[8px] bg-[#795BF929] px-6 py-3 text-[#795BF9]`}
            >
              <SectionText className="text-[16px] font-bold leading-[26px] tracking-[0.45px]">
                24시 즉시 상담 신청
              </SectionText>
            </Link>
          </div>

          <div className="flex flex-wrap gap-4 justify-center mt-10">
            {blocks.map((block, index) => (
              <div
                key={index}
                className="py-3 px-6 bg-white rounded-lg font-bold w-fit"
              >
                {block}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default MobileSection14;
