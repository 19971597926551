import logout from 'apis/auth/logout';
import Avatar from 'components/Avatar/Avatar';
import useStorageUrl from 'hooks/useStorageUrl';
import {
  WorkspaceHeaderDropdownButton,
  WorkspaceHeaderWrapper,
  WorkspaceHeaderDropdown,
  WorkspaceHeaderDropdownItem,
  WorkspaceHeaderApplyButton,
} from 'layouts/workspace/components/WorkspaceHeader/WorkspaceHeader.styles';
import { UserContext } from 'layouts/workspace/workspace_layout';
import { pages } from 'pages';
import { useContext, useEffect, useRef, useState } from 'react';
import { GoChevronDown } from 'react-icons/go';
import { Link, useNavigate } from 'react-router-dom';

export default function WorkspaceHeader() {
  const userProfile = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const [showShadow, setShowShadow] = useState(false);
  const navigate = useNavigate();
  const dropdown = useRef<HTMLDivElement>(null);
  const profileImage = useStorageUrl(userProfile?.profileImage);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    logout().then(() => {
      navigate(pages.LandingHome.path);
    });
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (dropdown.current && !dropdown.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };

    // if scollY is bigger than 0, it will show shadow
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShowShadow(true);
      } else {
        setShowShadow(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <WorkspaceHeaderWrapper $showShadow={showShadow}>
      <Avatar alt="profileImage" src={profileImage} />
      <WorkspaceHeaderDropdownButton onClick={handleOpen}>
        {userProfile?.name} 님 <GoChevronDown />
        {isOpen && (
          <WorkspaceHeaderDropdown ref={dropdown}>
            <WorkspaceHeaderDropdownItem>
              <Link to={pages.WorkspaceSettings.path}>계정 설정</Link>
            </WorkspaceHeaderDropdownItem>
            <WorkspaceHeaderDropdownItem onClick={handleLogout}>
              로그아웃
            </WorkspaceHeaderDropdownItem>
          </WorkspaceHeaderDropdown>
        )}
      </WorkspaceHeaderDropdownButton>
      <WorkspaceHeaderApplyButton to={pages.Subscribe.path}>
        구독 신청
      </WorkspaceHeaderApplyButton>
    </WorkspaceHeaderWrapper>
  );
}
