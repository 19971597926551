import { TSubscription } from 'apis/@types';
import ChatInputs from 'components/Chat/ChatInput';
import ChatRoomHeader from 'components/Chat/ChatRoomHeader';
import Chats from 'components/Chat/Chats';
import { WorkspaceCard } from 'pages/workspace/Workspace.styles';

interface IChatContainerProps {
  subscription?: TSubscription;
}

export default function ChatContainer({ subscription }: IChatContainerProps) {
  if (!subscription) return <WorkspaceCard></WorkspaceCard>;
  return (
    <WorkspaceCard $padding="0">
      <ChatRoomHeader subscription={subscription} />
      <Chats subscription={subscription} />
      <ChatInputs />
    </WorkspaceCard>
  );
}
