import { SidebarContainer } from 'layouts/workspace/components/Sidebar/Sidebar.styles';
import SidebarItem from 'layouts/workspace/components/Sidebar/SidebarItem';
import { pages } from 'pages';
import { FaHome, FaPlusCircle, FaRegCalendar } from 'react-icons/fa';
import { GoGraph } from 'react-icons/go';
import { IoMdSettings } from 'react-icons/io';
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5';

import { useLocation } from 'react-router-dom';

const items = {
  subscribe: {
    exclude: true,
    icon: <FaPlusCircle />,
    text: '구독신청',
    path: pages.Subscribe.path,
  },
  dashboard: {
    exclude: false,
    icon: <FaHome />,
    text: '대시보드',
    path: pages.WorkspaceHome.path,
  },
  subscription: {
    exclude: false,
    icon: <FaRegCalendar />,
    text: '구독관리',
    path: pages.WorkspaceSubscriptions.path,
  },
  // chats: {
  //   exclude: false,
  //   icon: <IoChatbubbleEllipsesOutline />,
  //   text: '채팅',
  //   path: pages.WorkspaceChats.path,
  // },
  analytics: {
    exclude: false,
    icon: <GoGraph />,
    text: '분석',
    path: pages.WorkspaceAnalytics.path,
  },

  settings: {
    exclude: false,
    icon: <IoMdSettings />,
    text: '계정설정',
    path: pages.WorkspaceSettings.path,
  },
};

export default function Sidebar() {
  const pathname = useLocation().pathname;

  return (
    <SidebarContainer>
      {Object.entries(items).map(([key, item]) => {
        return (
          <SidebarItem
            key={key}
            icon={item.icon}
            text={item.text}
            path={item.path}
            $active={pathname === item.path}
            $exclude={item.exclude}
          />
        );
      })}
    </SidebarContainer>
  );
}
