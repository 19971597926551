import { TContact } from 'apis/@types';
import {
  AdminContactsTable,
  AdminContactsTableHead,
  AdminContactsTableRow,
  AdminContactsTableHeader,
  AdminContactsTableBody,
  AdminContactsTableCell,
  AdminContactsTableCheckbox,
} from 'pages/admin/components/AdminContacts.styles';
import { timestampToKst } from 'utils/date/format';

export type ContactsTableProps = {
  contacts: TContact[];
  handleCheckContact: (contactId: string) => void;
};

export default function ContactsTable({
  contacts,
  handleCheckContact,
}: ContactsTableProps) {
  return (
    <AdminContactsTable>
      <AdminContactsTableHead>
        <AdminContactsTableRow>
          <AdminContactsTableHeader>신청시각</AdminContactsTableHeader>
          <AdminContactsTableHeader>회사명/성함/직함</AdminContactsTableHeader>
          <AdminContactsTableHeader>이메일</AdminContactsTableHeader>
          <AdminContactsTableHeader>전화번호</AdminContactsTableHeader>
          <AdminContactsTableHeader>확인</AdminContactsTableHeader>
        </AdminContactsTableRow>
      </AdminContactsTableHead>
      <AdminContactsTableBody>
        {contacts.map((contact, i) => (
          <AdminContactsTableRow key={i}>
            <AdminContactsTableCell>
              {timestampToKst(contact.createdAt)}
            </AdminContactsTableCell>
            <AdminContactsTableCell>{contact.name}</AdminContactsTableCell>
            <AdminContactsTableCell>{contact.email}</AdminContactsTableCell>
            <AdminContactsTableCell>{contact.phone}</AdminContactsTableCell>
            <AdminContactsTableCell>
              <AdminContactsTableCheckbox
                type="checkbox"
                onChange={() => handleCheckContact(contact.id)}
                checked={contact.check}
              />
            </AdminContactsTableCell>
          </AdminContactsTableRow>
        ))}
      </AdminContactsTableBody>
    </AdminContactsTable>
  );
}
