import { FlexColumn } from 'Globalstyles';
import FileInput from 'components/Form/FileInput/FileInput';
import {
  FileInputFile,
  FileInputFileList,
} from 'components/Form/FileInput/FileInput.styles';

import { Input, InputContainer, InputLabel } from 'components/Form/Form.styles';
import { StepProps } from 'pages/subscribe/Subscribe';
import {
  SubscribeButton,
  SubscribeButtonContainer,
  SubscribeSubmitButton,
  SubscribeTempSaveButton,
} from 'pages/subscribe/Subscribe.styles';
import ApplyCardHeader from 'pages/subscribe/SubscribeCardHeader';
import { IoMdClose } from 'react-icons/io';
import theme from 'theme';

export default function Step3({
  step,
  setStep,
  subscribeFormData,
  totalSteps,
  handleTempSave,
}: StepProps) {
  const { register, watch, control, setValue } = subscribeFormData;

  const files: (string | File)[] = watch('reference');

  return (
    <FlexColumn $gap="40px">
      <ApplyCardHeader
        step={step}
        totalSteps={totalSteps}
        title="(선택) 어떤 마케터가 필요하신가요?"
      />
      <InputContainer $gap={12}>
        <InputLabel htmlFor="plan" color={theme.colors.black.main}>
          참고자료 및 레퍼런스
        </InputLabel>

        {(!files || files.length === 0) && (
          <FileInput
            rules={{ required: false }}
            control={control}
            //   color={theme.colors.black.main}
            //   borderColor={theme.colors.gray.main}
            name="reference"
          />
        )}
        {files && files.length > 0 && (
          <FileInputFileList>
            {Array.from(files).map((file, index) => (
              <FileInputFile
                key={index}
                onClick={() => {
                  const newFiles = Array.from(files).filter(
                    (_, i) => i !== index
                  );
                  setValue('reference', newFiles);
                }}
              >
                {typeof file === 'string' ? file.split('/').at(-1) : file.name}
                <IoMdClose />
              </FileInputFile>
            ))}
          </FileInputFileList>
        )}
      </InputContainer>

      <InputContainer $gap={12}>
        <InputLabel htmlFor="ect" color={theme.colors.black.main}>
          기타 요청 사항
        </InputLabel>
        <Input
          id="etc"
          color={theme.colors.black.main}
          $borderColor={theme.colors.gray.main}
          {...register('etc')}
          as={'textarea'}
          rows={5}
          style={{ resize: 'none' }}
          placeholder="기타 요청사항을 입력해 주세요"
        />
      </InputContainer>

      <SubscribeButtonContainer>
        <SubscribeTempSaveButton onClick={handleTempSave} type="button">
          임시저장
        </SubscribeTempSaveButton>
        <SubscribeButton type="button" onClick={() => setStep(step - 1)}>
          &lt; 이전
        </SubscribeButton>
        <SubscribeSubmitButton>매칭 신청</SubscribeSubmitButton>
      </SubscribeButtonContainer>
    </FlexColumn>
  );
}
