import { FlexRow, Blank } from 'Globalstyles';
import {
  AuthForm,
  AuthButton,
  AuthLink,
  AuthInputButton,
  AuthTitle,
} from 'pages/auth/auth.styles';
import { pages } from 'pages';
import {
  ErrorMessage,
  Input,
  InputContainer,
  InputLabel,
} from 'components/Form/Form.styles';
import { useForm } from 'react-hook-form';
import useCheckPhone from 'hooks/useCheckPhone';
import { FaCheckCircle } from 'react-icons/fa';
import signupClient from 'apis/auth/signup';
import { useState } from 'react';
import theme from 'theme';
import { useLocation } from 'react-router-dom';

export type TSignupForm = {
  email: string;
  password: string;
  'password-confirm': string;
};

export default function AuthSignup() {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<TSignupForm>();

  const { search } = useLocation();
  const type =
    new URLSearchParams(search).get('type') === 'partner'
      ? 'partner'
      : 'client';

  const [isLoading, setIsLoading] = useState(false);

  const {
    phone,
    setPhone,
    phoneErrorMessage,
    formatTime,
    handleClickPhoneVerificationConfirm,
    handleClickPhoneVerificationRequest,
    isPhoneVefificationConfirmed,
    isPhoneVerificationMessageRequested,
    phoneVefification,
    phoneVerificationErrorMessage,
    sendPhoneVerificationMessage,
    setPhoneVerification,
    time,
  } = useCheckPhone();

  function onSubmit(data: TSignupForm) {
    setIsLoading(true);
    signupClient({
      email: data.email,
      password: data.password,
      phone,
      role: type,
    })
      .then(() => {
        setIsLoading(false);
        alert('회원가입이 완료되었습니다');
      })
      .catch((error) => {
        setIsLoading(false);
        switch (error.code) {
          case 'auth/email-already-in-use':
            setError('root', {
              type: 'manual',
              message: '이미 사용중인 이메일입니다',
            });
            break;
          default:
            setError('root', {
              type: 'manual',
              message: '회원가입에 실패했습니다',
            });
            break;
        }
      });
  }

  return (
    <>
      <AuthForm as={'form'} onSubmit={handleSubmit(onSubmit)}>
        <FlexRow $justifyContent="space-between" $alignItems="center">
          <AuthTitle>
            {type === 'partner' ? '파트너 ' : '고객사 '}
            회원가입
          </AuthTitle>
          {/* <AuthLink
            color="primary"
            to={`${pages.AuthSignup.path}?type=${
              type === 'partner' ? 'client' : 'partner'
            }`}
          >
            {type === 'partner' ? '고객사' : '파트너'}로 회원가입
          </AuthLink> */}
        </FlexRow>
        <Blank $height="8px" />
        <InputContainer>
          <InputLabel htmlFor="email">이메일</InputLabel>
          <Input
            type="email"
            id="email"
            placeholder="example@example.com"
            {...register('email', {
              required: '이메일을 입력해주세요',
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: '이메일 형식이 올바르지 않습니다',
              },
            })}
          />
          {errors.email && <ErrorMessage>{errors.email?.message}</ErrorMessage>}
        </InputContainer>
        <InputContainer>
          <InputLabel htmlFor="password">비밀번호</InputLabel>
          <Input
            type="password"
            id="password"
            placeholder="영문, 숫자, 특수문자 포함 8~16자"
            {...register('password', {
              required: '비밀번호를 입력해주세요',
              pattern: {
                value: /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}/,
                message:
                  '비밀번호는 8~16자의 영문, 숫자, 특수문자를 포함해야합니다',
              },
            })}
          />
          {errors.password && (
            <ErrorMessage>{errors.password?.message}</ErrorMessage>
          )}
          <Input
            type="password"
            id="password-confirm"
            placeholder="비밀번호 확인"
            {...register('password-confirm', {
              required: '비밀번호를 다시 입력해주세요',
              validate: (value) =>
                value === watch('password') || '비밀번호가 일치하지 않습니다',
            })}
          />
          {errors['password-confirm'] && (
            <ErrorMessage>{errors['password-confirm']?.message}</ErrorMessage>
          )}
        </InputContainer>
        <InputContainer>
          <InputLabel htmlFor="nickname">휴대폰번호</InputLabel>
          <FlexRow $gap="8px" $alignItems="center">
            <Input
              type="text"
              id="phone"
              placeholder="'-' 없이 입력해주세요"
              pattern="[0-9]{11}"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  sendPhoneVerificationMessage();
                }
              }}
            />
            {!isPhoneVefificationConfirmed &&
              (isPhoneVerificationMessageRequested ? (
                <AuthInputButton onClick={handleClickPhoneVerificationRequest}>
                  재전송
                </AuthInputButton>
              ) : (
                <AuthInputButton onClick={sendPhoneVerificationMessage}>
                  인증번호 전송
                </AuthInputButton>
              ))}
            {isPhoneVefificationConfirmed && (
              <FlexRow $alignItems="center" $gap="8px">
                <InputLabel>인증됨</InputLabel>
                <FaCheckCircle color="green" size="24px" />
              </FlexRow>
            )}
          </FlexRow>
          {isPhoneVerificationMessageRequested &&
            !isPhoneVefificationConfirmed && (
              <FlexRow $gap="8px" $alignItems="center">
                <Input
                  type="text"
                  id="phone-auth"
                  placeholder="인증번호를 입력해주세요"
                  pattern="[0-9]{6}"
                  name="phone-auth"
                  onChange={(e) => setPhoneVerification(e.target.value)}
                  value={phoneVefification}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleClickPhoneVerificationConfirm();
                    }
                  }}
                />

                <FlexRow width="200px" $gap="8px">
                  <AuthInputButton
                    onClick={handleClickPhoneVerificationConfirm}
                  >
                    인증
                  </AuthInputButton>
                  <InputLabel style={{ alignSelf: 'end', padding: '4px' }}>
                    {formatTime(time)}
                  </InputLabel>
                </FlexRow>
              </FlexRow>
            )}
          {phoneErrorMessage && (
            <ErrorMessage>{phoneErrorMessage}</ErrorMessage>
          )}
          {phoneVerificationErrorMessage && (
            <ErrorMessage>{phoneVerificationErrorMessage}</ErrorMessage>
          )}
        </InputContainer>
        {errors.root && <ErrorMessage>{errors.root?.message}</ErrorMessage>}
        <AuthButton
          disabled={
            watch('email') === '' ||
            watch('password') === '' ||
            watch('password-confirm') === '' ||
            phone === '' ||
            isPhoneVefificationConfirmed === false ||
            isLoading
          }
        >
          회원가입
        </AuthButton>
        <FlexRow $justifyContent="center">
          <AuthLink to={pages.AuthLogin.path} color="white">
            이미 회원이신가요?{' '}
            <p style={{ color: theme.colors.primary.light }}>로그인 </p>
          </AuthLink>
        </FlexRow>
        {/* <Divider $direction="horizontal" $bgColor="rgba(255,255,255,0.5)" />
        <SocialButtonWrapper>
          <KakaoButton>
            <img src={kakaoLogo} alt="kakao" height="20px" />
            카카오로 회원가입
          </KakaoButton>
          <NaverButton>
            <img src={naverLogo} alt="naver" height="16px" />
            네이버로 회원가입
          </NaverButton>
        </SocialButtonWrapper> */}
      </AuthForm>
    </>
  );
}
