export default function addDaysExecptWeekends(date: Date, days: number): Date {
  const copy = new Date(Number(date));
  let i = 0;
  while (i < days) {
    copy.setDate(copy.getDate() + 1);
    if (copy.getDay() !== 0 && copy.getDay() !== 6) {
      i++;
    }
  }
  return copy;
}
