import { TSubscription } from 'apis/@types';
import { doc, getDoc } from 'firebase/firestore';
import { db } from 'utils/firebase';

export default async function getSubscription(
  id: string
): Promise<TSubscription> {
  const docRef = doc(db, 'subscriptions', id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as TSubscription;
  } else {
    throw new Error('Subscription not found');
  }
}
