import {
  CalendarButton,
  CalendarDay,
  CalendarDayText,
  CalendarEvent,
  CalendarEventIndicator,
  CalendarEventIndicatorWrapper,
  CalendarEventTitle,
  CalendarEventsWrapper,
  CalendarGrid,
  CalendarHeader,
  CalendarTitle,
  CalendarWrapper,
} from 'components/Calendar/Calendar.styles';
import { useState } from 'react';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';

export default function Calendar({
  events,
}: {
  events: { date: string; title: string }[];
}) {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState('');

  const firstDay = new Date(
    selectedMonth.getFullYear(),
    selectedMonth.getMonth(),
    1
  ).getDay();

  function handlePrevMonth() {
    setSelectedMonth(
      new Date(selectedMonth.setMonth(selectedMonth.getMonth() - 1))
    );
  }

  function handleNextMonth() {
    setSelectedMonth(
      new Date(selectedMonth.setMonth(selectedMonth.getMonth() + 1))
    );
  }

  function handleSelectDate(date: string) {
    setSelectedDate(date);
  }

  const days = ['일', '월', '화', '수', '목', '금', '토'];

  return (
    <CalendarWrapper>
      <CalendarHeader>
        <CalendarButton onClick={handlePrevMonth}>
          <FaChevronCircleLeft />
        </CalendarButton>
        <CalendarTitle>
          {selectedMonth.getFullYear()}년 {selectedMonth.getMonth() + 1}월
        </CalendarTitle>
        <CalendarButton onClick={handleNextMonth}>
          <FaChevronCircleRight />
        </CalendarButton>
      </CalendarHeader>
      <CalendarGrid>
        {days.map((day) => (
          <CalendarDay key={day}>
            <CalendarDayText
              $color={day === '일' ? 'red' : day === '토' ? 'blue' : 'black'}
            >
              {day}
            </CalendarDayText>
          </CalendarDay>
        ))}
        {Array.from({ length: firstDay }).map((_, index) => (
          <CalendarDay key={index} />
        ))}
        {Array.from({
          length: new Date(
            selectedMonth.getFullYear(),
            selectedMonth.getMonth() + 1,
            0
          ).getDate(),
        }).map((_, index) => {
          const date = new Date(
            selectedMonth.getFullYear(),
            selectedMonth.getMonth(),
            index + 1,
            9
          );

          const isToday = new Date().toDateString() === date.toDateString();

          const event = events.find(
            (event) => event.date === date.toISOString().split('T')[0]
          );
          const color =
            date.getDay() === 0
              ? 'red'
              : date.getDay() === 6
              ? 'blue'
              : undefined;

          return (
            <CalendarDay
              key={index}
              onClick={() => handleSelectDate(date.toISOString().split('T')[0])}
              selected={selectedDate === date.toISOString().split('T')[0]}
            >
              <CalendarDayText $isToday={isToday} $color={color}>
                {index + 1}
              </CalendarDayText>
              <CalendarEventIndicator $show={!!event} />
            </CalendarDay>
          );
        })}
      </CalendarGrid>
      <CalendarEventsWrapper>
        {events
          .filter((event) =>
            event.date.startsWith(
              `${selectedMonth.getFullYear()}-${(selectedMonth.getMonth() + 1)
                .toString()
                .padStart(2, '0')}`
            )
          )
          .map((event, index) => (
            <CalendarEvent
              onClick={() => handleSelectDate(event.date)}
              key={index}
              selected={selectedDate === event.date}
            >
              <CalendarEventIndicatorWrapper>
                {event.date.split('-')[2]}
              </CalendarEventIndicatorWrapper>

              <CalendarEventTitle>{event.title}</CalendarEventTitle>
            </CalendarEvent>
          ))}
        {events.filter(
          (event) =>
            event.date.split('-')[1] ===
            (selectedMonth.getMonth() + 1).toString().padStart(2, '0')
        ).length === 0 && (
          <CalendarEvent style={{ opacity: 0.7 }}>
            이번달의 일정이 없습니다.
          </CalendarEvent>
        )}
      </CalendarEventsWrapper>
    </CalendarWrapper>
  );
}
