import styled from 'styled-components';

export const AdminContactsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const AdminContactsTableHead = styled.thead`
  background-color: ${(props) => props.theme.colors.primary.main};
  color: ${(props) => props.theme.colors.white.main};
`;

export const AdminContactsTableBody = styled.tbody`
  background-color: ${(props) => props.theme.colors.white.main};
`;

export const AdminContactsTableRow = styled.tr`
  border-bottom: 1px solid ${(props) => props.theme.colors.black.light};
`;

export const AdminContactsTableHeader = styled.th`
  text-align: left;
  padding: 10px;
`;

export const AdminContactsTableCell = styled.td`
  padding: 10px;
`;

export const AdminContactsTableCheckbox = styled.input`
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  background-color: ${(props) => props.theme.colors.white.main};

  &:checked {
    background-color: ${(props) => props.theme.colors.primary.main};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.primary.light};
  }
`;
