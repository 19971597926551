import { Input } from 'components/Form/Form.styles';
import styled from 'styled-components';

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;

  .chevron-down {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    opacity: 0.7;
  }
`;

export const DropdownButton = styled(Input)`
  cursor: pointer;
`;

export const DropdownList = styled.ul<{
  maxHeight?: string;
  direction?: 'up' | 'down';
}>`
  position: absolute;
  top: ${({ direction }) => (direction === 'up' ? 'auto' : '100%')};
  bottom: ${({ direction }) => (direction === 'up' ? '100%' : 'auto')};
  left: 0;
  width: 100%;
  max-height: ${({ maxHeight }) => maxHeight || '200px'};
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: ${({ direction }) => (direction === 'up' ? '-4px' : '4px')};
  margin-bottom: ${({ direction }) => (direction === 'up' ? '4px' : '-4px')};
  z-index: 1;
  list-style: none;
  padding: 0;
  box-shadow: ${({ direction }) =>
    direction === 'up'
      ? '0 -2px 8px 0 rgba(0, 0, 0, 0.1)'
      : '0 2px 8px 0 rgba(0, 0, 0, 0.1)'};
`;

export const DropdownItem = styled.li<{
  disabled?: boolean;
}>`
  padding: 14px 16px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background-color: ${({ disabled }) => (disabled ? 'initial' : '#f5f5f5')};
  }
  color: ${({ disabled }) => (disabled ? '#ccc' : 'initial')};
`;

export const DropdownInput = styled.input`
  position: absolute;
  top: 100%;
  left: 0;
  height: 0;
  border: none;
  cursor: default;
  &:focus {
    outline: none;
  }
  color: transparent;
`;
