import { TSubscription } from 'apis/@types';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from 'utils/firebase';

// find all subscriptions that belong to the current user
// subscription.clientId === currentUser.uid
export default function useClientSubscriptions(clientId?: string | null) {
  const [subscriptions, setSubscriptions] = useState<TSubscription[]>([]);
  useEffect(() => {
    if (!clientId) return;
    const q = query(
      collection(db, 'subscriptions'),
      where('clientId', '==', clientId)
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as TSubscription[];
      setSubscriptions(data);
    });
    return unsubscribe;
  }, [clientId]);

  return {
    subscriptions,
  };
}
