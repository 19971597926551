import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

export interface ISendSMSProps {
  sender: string;
  receiver: string[];
  msg: string;
}

export interface ISendSMSResponse {
  error_cnt?: number; // 0
  message: string; // success
  msg_id?: string; // '643979429'
  msg_type?: string; // SMS
  result_code: string; // 1 = success
  success_cnt?: number; // 1
}

// eslint-disable-next-line @typescript-eslint/no-shadow
export async function sendSMS(sendSMS: ISendSMSProps) {
  const response = await fetch(
    `${process.env.REACT_APP_ALLIMTOK_URL}/message/sms`,
    {
      method: 'POST',
      body: JSON.stringify(sendSMS),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const data: ISendSMSResponse = await response.json();
  return data;
}

export interface ICheckPhone {
  // phone verification
  phone: string;
  setPhone: Dispatch<SetStateAction<string>>;
  phoneErrorMessage: string;
  isPhoneVerificationMessageRequested: boolean;
  phoneVefification: string;
  setPhoneVerification: Dispatch<SetStateAction<string>>;
  phoneVerificationErrorMessage: string;
  isPhoneVefificationConfirmed: boolean;
  sendPhoneVerificationMessage: () => void;
  handleClickPhoneVerificationRequest: () => void;
  handleClickPhoneVerificationConfirm: () => void;
  // verification timer
  time: number;
  formatTime: (arg: number) => string;
}

export default function useCheckPhone(
  onSuccess?: () => void,
  onFailure?: () => void
): ICheckPhone {
  // phone verification
  const [phone, setPhone] = useState('');
  const [phoneErrorMessage, setPhoneErrorMessage] = useState<string>('');
  const [phoneVerificationNumber, setPhoneVerificationNumber] = useState('');
  const [
    isPhoneVerificationMessageRequested,
    setIsPhoneVerificationMessageRequest,
  ] = useState<boolean>(false);
  const [phoneVefification, setPhoneVerification] = useState('');
  const [isPhoneVefificationConfirmed, setIsPhoneVerificationConfirmed] =
    useState<boolean>(false);
  const [phoneVerificationErrorMessage, setPhoneVerificationErrorMessage] =
    useState<string>('');

  const sendPhoneVerificationMessage = async () => {
    const verificationNumber: string = Array(6)
      .fill(0)
      .map(() => {
        return Math.floor(Math.random() * 10);
      })
      .join('');

    try {
      const response: ISendSMSResponse = await sendSMS({
        sender: `${process.env.REACT_APP_SMS_SENDER}`,
        receiver: [phone],
        msg: `[마케터하이어] 회원가입 인증\n인증번호: ${verificationNumber}`,
      });
      if (response.result_code !== '1') {
        setIsPhoneVerificationMessageRequest(false);
        alert('휴대폰 인증 실패');
        return;
      }

      resetTimer();
      setPhoneVerificationNumber(verificationNumber);
      setIsPhoneVerificationMessageRequest(true);
    } catch (e: any) {
      alert('휴대폰 인증 실패');
    }
  };

  const handleClickPhoneVerificationRequest = () => {
    // 전화번호 인증을 요청한 경우 인증을 초기화하고 재인증 시나리오를 진행한다.
    if (isPhoneVerificationMessageRequested) {
      setIsPhoneVerificationMessageRequest(false);
      setPhoneErrorMessage('');
      setPhoneVerificationNumber('');
      setPhone('');
      setPhoneVerification('');
      setIsPhoneVerificationConfirmed(false);
      setPhoneVerificationErrorMessage('');
      return;
    }
    if (!/^\d{11}$/.test(phone)) {
      setPhoneErrorMessage('올바른 휴대폰 번호를 입력해주세요');
      setPhoneVerificationNumber('');
      setIsPhoneVerificationMessageRequest(false);
      return;
    }

    sendPhoneVerificationMessage();
    setPhoneErrorMessage('');
  };

  const handleClickPhoneVerificationConfirm = () => {
    if (phoneVerificationNumber === '') {
      setPhoneVerificationErrorMessage('휴대폰 번호 인증을 먼저 진행하세요');
      setIsPhoneVerificationConfirmed(false);
      onFailure && onFailure();
      return;
    }
    if (phoneVerificationNumber !== phoneVefification) {
      setPhoneVerificationErrorMessage('인증번호가 일치하지 않습니다');
      setIsPhoneVerificationConfirmed(false);
      onFailure && onFailure();
      return;
    }

    setIsPhoneVerificationConfirmed(true);
    setPhoneVerificationErrorMessage('');
    onSuccess && onSuccess();
  };

  // verification timer
  const [time, setTime] = useState<number>(180);
  const [isRunning, setIsRunning] = useState<boolean>(false);

  const resetTimer = (): void => {
    setTime(180);
    setIsRunning(true);
  };

  const formatTime = (seconds: number): string => {
    if (seconds === 0) return '0:00';
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;

    if (isRunning && time > 0) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isRunning, time]);

  return {
    phone,
    setPhone,
    phoneErrorMessage,
    isPhoneVerificationMessageRequested,
    phoneVefification,
    setPhoneVerification,
    phoneVerificationErrorMessage,
    isPhoneVefificationConfirmed,
    sendPhoneVerificationMessage,
    handleClickPhoneVerificationRequest,
    handleClickPhoneVerificationConfirm,
    time,
    formatTime,
  };
}
