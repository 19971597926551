import { Blank, FlexColumn, FlexRow } from 'Globalstyles';
import updateProfileImage from 'apis/common/updateProfileImage';
import updateUserName from 'apis/common/updateUserName';
import updateUserPhone from 'apis/common/updateUserPhone';
import Avatar from 'components/Avatar/Avatar';
import {
  Button,
  ErrorMessage,
  Input,
  InputContainer,
  InputLabel,
} from 'components/Form/Form.styles';
import useCheckPhone from 'hooks/useCheckPhone';
import useStorageUrl from 'hooks/useStorageUrl';
import { UserContext } from 'layouts/workspace/workspace_layout';
import {
  WorkspaceCard,
  WorkspaceCardButton,
  WorkspaceCardTitle,
} from 'pages/workspace/Workspace.styles';
import { TSettingMenuItem } from 'pages/workspace/WorkspaceSettings';

import { useContext, useRef, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';

import theme from 'theme';

export default function SettingProfile(settingMenuItem: TSettingMenuItem) {
  const userProfile = useContext(UserContext);
  const profileImageUrl = useStorageUrl(userProfile?.profileImage);
  const inputRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState(userProfile?.name);
  const [editName, setEditName] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const {
    phone,
    setPhone,
    phoneErrorMessage,
    formatTime,
    handleClickPhoneVerificationConfirm,
    handleClickPhoneVerificationRequest,
    isPhoneVefificationConfirmed,
    isPhoneVerificationMessageRequested,
    phoneVefification,
    phoneVerificationErrorMessage,
    sendPhoneVerificationMessage,
    setPhoneVerification,
    time,
  } = useCheckPhone(() => {
    updateUserPhone(phone).then(() => {
      setEditPhone(false);
      userProfile?.reload();
    });
  });
  return (
    <WorkspaceCard $padding="40px 60px">
      <WorkspaceCardTitle style={{ alignSelf: 'start' }}>
        {settingMenuItem.label}
      </WorkspaceCardTitle>
      <Blank $height="40px" />
      <FlexRow $gap="80px" width="100%">
        <FlexColumn $gap="40px" width="280px" $alignItems="center">
          <Avatar alt="profile" size="152px" src={profileImageUrl} />
          <WorkspaceCardButton
            onClick={() => {
              inputRef.current?.click();
            }}
          >
            프로필 사진 변경
          </WorkspaceCardButton>
          <input
            ref={inputRef}
            type="file"
            style={{ display: 'none' }}
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (file) {
                updateProfileImage(file).then(() => {
                  userProfile?.reload();
                });
              }
            }}
          />
        </FlexColumn>
        <FlexColumn $gap="40px" style={{ flex: 1 }}>
          <InputContainer>
            <InputLabel color={theme.colors.black.main}>이메일</InputLabel>
            <Input
              color={theme.colors.black.main}
              $borderColor={theme.colors.gray.main}
              disabled
              value={userProfile?.email}
            />
          </InputContainer>

          <InputContainer>
            <InputLabel color={theme.colors.black.main}>이름</InputLabel>
            <FlexRow>
              <Input
                color={theme.colors.black.main}
                $borderColor={theme.colors.gray.main}
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={!editName}
                // press enter to save
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (editName && !!name && name !== userProfile?.name) {
                      updateUserName(name).then(() => {
                        userProfile?.reload();
                      });
                    }
                    setEditName(!editName);
                  }
                }}
              />
              <Button
                $backgroundColor={theme.colors.primary.light}
                $hoverBackgroundColor={theme.colors.primary.dark}
                onClick={() => {
                  if (editName && !!name && name !== userProfile?.name) {
                    updateUserName(name).then(() => {
                      userProfile?.reload();
                    });
                  }
                  setEditName(!editName);
                }}
              >
                {editName ? '저장' : '변경'}
              </Button>
            </FlexRow>
          </InputContainer>

          {!editPhone ? (
            <InputContainer>
              <InputLabel color={theme.colors.black.main}>
                휴대폰 번호
              </InputLabel>
              <FlexRow>
                <Input
                  color={theme.colors.black.main}
                  $borderColor={theme.colors.gray.main}
                  value={userProfile?.phone}
                  disabled
                />
                <Button
                  $backgroundColor={theme.colors.primary.light}
                  $hoverBackgroundColor={theme.colors.primary.dark}
                  onClick={() => setEditPhone(true)}
                >
                  변경
                </Button>
              </FlexRow>
            </InputContainer>
          ) : (
            <InputContainer>
              <InputLabel htmlFor="nickname">휴대폰번호</InputLabel>
              <FlexRow $gap="8px" $alignItems="center">
                <Input
                  type="text"
                  id="phone"
                  placeholder="'-' 없이 입력해주세요"
                  pattern="[0-9]{11}"
                  name="phone"
                  value={phone}
                  color={theme.colors.black.main}
                  $borderColor={theme.colors.gray.main}
                  onChange={(e) => setPhone(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleClickPhoneVerificationRequest();
                    }
                  }}
                />
                {!isPhoneVefificationConfirmed &&
                  (isPhoneVerificationMessageRequested ? (
                    <Button
                      $backgroundColor={theme.colors.primary.light}
                      $hoverBackgroundColor={theme.colors.primary.dark}
                      onClick={handleClickPhoneVerificationRequest}
                    >
                      재전송
                    </Button>
                  ) : (
                    <Button
                      $backgroundColor={theme.colors.primary.light}
                      $hoverBackgroundColor={theme.colors.primary.dark}
                      onClick={sendPhoneVerificationMessage}
                    >
                      인증번호 전송
                    </Button>
                  ))}
                {isPhoneVefificationConfirmed && (
                  <FlexRow $alignItems="center" $gap="8px">
                    <InputLabel>인증됨</InputLabel>
                    <FaCheckCircle color="green" size="24px" />
                  </FlexRow>
                )}
              </FlexRow>
              {isPhoneVerificationMessageRequested &&
                !isPhoneVefificationConfirmed && (
                  <FlexRow $gap="8px" $alignItems="center">
                    <Input
                      color={theme.colors.black.main}
                      $borderColor={theme.colors.gray.main}
                      type="text"
                      id="phone-auth"
                      placeholder="인증번호를 입력해주세요"
                      pattern="[0-9]{6}"
                      name="phone-auth"
                      onChange={(e) => setPhoneVerification(e.target.value)}
                      value={phoneVefification}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleClickPhoneVerificationConfirm();
                        }
                      }}
                    />

                    <FlexRow $gap="8px">
                      <Button
                        $backgroundColor={theme.colors.primary.light}
                        $hoverBackgroundColor={theme.colors.primary.dark}
                        onClick={handleClickPhoneVerificationConfirm}
                      >
                        인증
                      </Button>

                      <InputLabel
                        style={{
                          alignSelf: 'end',
                          padding: '4px',
                          color: theme.colors.black.light,
                        }}
                      >
                        {formatTime(time)}
                      </InputLabel>
                    </FlexRow>
                  </FlexRow>
                )}

              {phoneErrorMessage && (
                <ErrorMessage>{phoneErrorMessage}</ErrorMessage>
              )}
              {phoneVerificationErrorMessage && (
                <ErrorMessage>{phoneVerificationErrorMessage}</ErrorMessage>
              )}
              <Button
                $backgroundColor={theme.colors.error.light}
                $hoverBackgroundColor={theme.colors.error.main}
                onClick={() => {
                  setEditPhone(false);
                  handleClickPhoneVerificationRequest();
                }}
              >
                취소
              </Button>
            </InputContainer>
          )}
        </FlexColumn>
      </FlexRow>
    </WorkspaceCard>
  );
}
