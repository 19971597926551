import { getDownloadURL, ref } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { storage } from 'utils/firebase';

export default function useStorageUrl(storagePath?: string) {
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (!storagePath) return;
    if (storagePath?.startsWith('http') || storagePath?.startsWith('https'))
      return setUrl(storagePath);
    getDownloadURL(ref(storage, storagePath)).then((url) => {
      setUrl(url);
    });
  }, [storagePath]);

  return url;
}
