import { pages } from 'pages';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import theme from 'theme';

const ServiceUsage = () => {
  const navigate = useNavigate();
  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>마케터하이어 사용법</h2>

      <div style={styles.stepsContainer}>
        <div style={styles.step}>
          <h3 style={styles.stepHeading}>1. 구독 신청</h3>
          <p style={styles.stepDescription}>
            상단의{' '}
            <span
              style={styles.highlight}
              onClick={() => navigate(pages.Subscribe.path)}
            >
              구독 신청
            </span>{' '}
            버튼을 클릭하여 무료 요금제로 구독을 신청하세요. 구독 신청 시
            결제수단으로 카드를 등록해야 합니다. 이는 결제를 위한 것이 아닌
            인증을 위해 필요합니다.
          </p>
        </div>
        <div style={styles.step}>
          <h3 style={styles.stepHeading}>2. 담당자 연락</h3>
          <p style={styles.stepDescription}>
            희망 업무 시작일에 담당자가 등록된 휴대폰 번호로 문자 혹은 전화로
            연락을 드립니다.
          </p>
        </div>
        <div style={styles.step}>
          <h3 style={styles.stepHeading}>3. 마케팅 현황 파악</h3>
          <p style={styles.stepDescription}>
            담당자가 기업의 마케팅 현황을 파악하기 위해 현재 진행 중인 마케팅
            현황을 질문할 것이며, 데이터를 요청하거나 직접 계정을 받아 데이터를
            확인할 수 있습니다.
          </p>
        </div>
        <div style={styles.step}>
          <h3 style={styles.stepHeading}>4. 보고서 제공</h3>
          <p style={styles.stepDescription}>
            일주일 이내에 맞춤형 마케팅 보고서를 제공해 드립니다.
          </p>
        </div>
      </div>
      <p style={styles.description}>
        궁금한 사항이 있으시면 우측 하단의
        <Link
          to={pages.KakaoConsult.path}
          target="_blank"
          style={{
            ...styles.highlight,

            marginLeft: '8px',
          }}
        >
          채팅 버튼
        </Link>
        을 통해 문의해 주세요.
      </p>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    minHeight: '540px',
    textAlign: 'center',
    padding: '20px',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
  },
  heading: {
    fontSize: '28px',
    marginBottom: '20px',
    color: '#333',
  },
  description: {
    fontSize: '18px',
    color: '#555',
    marginTop: '40px',
  },
  stepsContainer: {
    textAlign: 'left',
  },
  step: {
    marginBottom: '20px',
  },
  stepHeading: {
    fontSize: '20px',
    color: '#333',
    marginBottom: '10px',
  },
  stepDescription: {
    fontSize: '16px',
    color: '#555',
  },
  highlight: {
    color: theme.colors.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};

export default ServiceUsage;
