import { TUserProfile } from 'apis/@types';
import styled from 'styled-components';

interface UsersTableProps {
  users: TUserProfile[];
}

const AdminUsersTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
`;

const AdminUsersTableHeader = styled.thead`
  background-color: ${(props) => props.theme.colors.gray.light};
`;

const AdminUsersTableBody = styled.tbody`
  background-color: ${(props) => props.theme.colors.white.main};
`;

const AdminUsersTableRow = styled.tr`
  border-bottom: 1px solid ${(props) => props.theme.colors.gray.light};
`;

const AdminUsersTableHeaderCell = styled.th`
  padding: 10px;
  text-align: left;
`;

const AdminUsersTableCell = styled.td`
  padding: 10px;
`;

const AdminUsersTableCheckbox = styled.input`
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  background-color: ${(props) => props.theme.colors.white.main};

  &:checked {
    background-color: ${(props) => props.theme.colors.primary.main};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.primary.light};
  }
`;

export default function UsersTable({ users }: UsersTableProps) {
  return (
    <AdminUsersTable>
      <AdminUsersTableHeader>
        <AdminUsersTableRow>
          <AdminUsersTableHeaderCell></AdminUsersTableHeaderCell>
          <AdminUsersTableHeaderCell>이름</AdminUsersTableHeaderCell>
          <AdminUsersTableHeaderCell>이메일</AdminUsersTableHeaderCell>
          <AdminUsersTableHeaderCell>휴대폰번호</AdminUsersTableHeaderCell>
          <AdminUsersTableHeaderCell>역할</AdminUsersTableHeaderCell>
          <AdminUsersTableHeaderCell>가입일</AdminUsersTableHeaderCell>
          <AdminUsersTableHeaderCell>최근 접속</AdminUsersTableHeaderCell>
        </AdminUsersTableRow>
      </AdminUsersTableHeader>
      <AdminUsersTableBody>
        {users.map((user) => (
          <AdminUsersTableRow key={user.id}>
            <AdminUsersTableCell>
              <AdminUsersTableCheckbox type="checkbox" />
            </AdminUsersTableCell>
            <AdminUsersTableCell>{user.name}</AdminUsersTableCell>
            <AdminUsersTableCell>{user.email}</AdminUsersTableCell>
            <AdminUsersTableCell>{user.phone}</AdminUsersTableCell>
            <AdminUsersTableCell>{user.role}</AdminUsersTableCell>
            <AdminUsersTableCell>
              {user.createdAt.toDate().toLocaleDateString()}
            </AdminUsersTableCell>
            <AdminUsersTableCell>
              {user.lastVisitAt?.toDate().toLocaleDateString()}
            </AdminUsersTableCell>
          </AdminUsersTableRow>
        ))}
      </AdminUsersTableBody>
    </AdminUsersTable>
  );
}
