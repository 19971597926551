import { pages } from 'pages';
import { Link } from 'react-router-dom';

function HeroSection5() {
  return (
    <section className="h-full w-full bg-section5-gradient-color">
      <div className="mx-auto flex h-[240px] w-[1440px] items-center justify-between px-[120px] py-[71px]">
        <h2 className="text-left font-suit text-[36px] font-normal leading-[48.6px] tracking-[-0.02em] text-white">
          검증된 역량으로, 정확하고 빠르게 문제를 해결해 줄
          <br />
          <span className="font-suit text-[36px] font-bold leading-[48.6px] tracking-[-0.02em]">
            전담 마케터와 함께 일하세요
          </span>
        </h2>
        <div className="flex h-[50px] w-[328px] items-center gap-2">
          <Link
            to={pages.KakaoConsult.path}
            target="_blank"
            className="h-[50px] whitespace-nowrap rounded-lg bg-[#FFFFFF] px-6 py-3"
            id="0705_sec04_inquiry_cv0"
          >
            <span className="bg-section5-gradient-text bg-clip-text text-left font-suit text-[16px] font-bold leading-[26px] tracking-[0.45px] text-section5-gradient-text">
              24시 즉시 상담
            </span>
          </Link>
          <Link
            to={pages.AuthLogin.path}
            id="0705_sec04_cv0"
            className="h-[50px] whitespace-nowrap rounded-lg bg-[#FFFFFF33] px-6 py-3"
          >
            <span className="text-left font-suit text-[16px] font-bold leading-[26px] tracking-[0.45px] text-[#FFFFFF]">
              지금 바로 구독하기
            </span>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default HeroSection5;
