function MobileSection10() {
  return (
    <section className="h-full w-full bg-[#0000000A] px-4">
      <div className="mx-auto flex flex-col items-start justify-between gap-8 px-4 py-12">
        <div className="flex flex-col items-start justify-start gap-2">
          <div className="flex flex-col items-start justify-start gap-2">
            <p className="text-left font-suit text-[14px] font-semibold leading-[22.4px] tracking-[-0.02em] text-[#00000099]">
              대행사 직원이 아니라, 클라이언트의 직원입니다
            </p>
            <h4 className="text-left font-suit text-2xl font-bold leading-[42px] tracking-[-0.02em] text-[#000000E5]">
              계약한 시간을 온전히 독점하세요
            </h4>
          </div>
          <pre className="text-wrap text-left font-suit text-[16px] font-normal leading-[25.6px] tracking-[-0.02em] text-[#000000CC]">
            마하에서는 한명의 마케터가 여러 고객사의 업무를 한번에 핸들링하지
            않습니다.
            <span className="text-left font-suit text-[16px] font-bold leading-[25.6px] tracking-[-0.02em] text-[#000000CC]">
              계약된 시간에는 한 기업만 전담으로 맡아
            </span>
            집중하여 업무를 진행합니다.
          </pre>
        </div>

        <img
          src="/img/box/section10-box1.png"
          alt="section10-box1"
          className="w-full"
        />
      </div>
    </section>
  );
}

export default MobileSection10;
