import { doc, setDoc } from 'firebase/firestore';
import { db } from 'utils/firebase';

export default async function updatePaymentMethod(
  customerKey: string,
  authKey: string
) {
  const result = await fetch(
    'https://api.tosspayments.com/v1/billing/authorizations/issue',
    {
      method: 'POST',
      headers: {
        Authorization: `Basic ${btoa(
          `${process.env.REACT_APP_TOSS_SECRET_KEY!}:`
        )}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        authKey,
        customerKey,
      }),
    }
  );

  if (result.status === 200) {
    const data = await result.json();
    console.log(data);

    await setDoc(doc(db, `users/${customerKey}/settings/paymentMethod`), data);
  } else {
    throw new Error('결제 수단 등록 실패');
  }
}
