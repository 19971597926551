import styled from 'styled-components';

const ChatInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e0e0e0;
  height: 90px;
  width: 100%;
  padding: 0 12px;
  box-sizing: border-box;
`;

const ChatInput = styled.textarea`
  background-color: transparent;
  height: 100%;
  resize: none;
  flex: 1;
  border: none;
  font-size: 1rem;
  padding-top: 12px;
  padding-left: 12px;
  font-weight: 400;

  &:focus {
    outline: none;
  }
`;

const ChatSendButton = styled.button`
  padding: 8px;
  background-color: #3f51b5;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

export default function ChatInputs() {
  return (
    <ChatInputWrapper>
      <ChatInput placeholder="메시지를 입력하세요" />
      <ChatSendButton>전송</ChatSendButton>
    </ChatInputWrapper>
  );
}
