import styled from 'styled-components';

type TCheckboxItem = {
  checked: boolean;
  label: string;
};

type TMultipleCheckboxProps = {
  checkboxItems: TCheckboxItem[];
  setCheckboxItems: React.Dispatch<React.SetStateAction<TCheckboxItem[]>>;
};

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.gray.main};
  border-radius: 8px;
  padding: 10px;
`;

const CheckboxItem = styled.div<{ checked: boolean }>`
  display: flex;
  padding: 16px 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: ${(props) =>
    props.checked ? props.theme.colors.primary.light : 'transparent'};
  color: ${(props) =>
    props.checked
      ? props.theme.colors.white.main
      : props.theme.colors.black.main};
  &:hover {
    background-color: ${(props) =>
      props.checked
        ? props.theme.colors.primary.light
        : props.theme.colors.white.dark};
  }
`;

const Checkbox = styled.input`
  display: none;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
`;

const CheckboxText = styled.p`
  font-size: 14px;
`;

export default function MultipleCheckbox({
  checkboxItems,
  setCheckboxItems,
}: TMultipleCheckboxProps) {
  return (
    <CheckboxContainer>
      {checkboxItems.map((item, index) => (
        <CheckboxItem
          key={index}
          checked={item.checked}
          onClick={() => {
            setCheckboxItems((prev) =>
              prev.map((prevItem, prevIndex) =>
                prevIndex === index
                  ? { ...prevItem, checked: !prevItem.checked }
                  : prevItem
              )
            );
          }}
        >
          <Checkbox
            type="checkbox"
            checked={item.checked}
            onChange={() => {
              setCheckboxItems((prev) =>
                prev.map((prevItem, prevIndex) =>
                  prevIndex === index
                    ? { ...prevItem, checked: !prevItem.checked }
                    : prevItem
                )
              );
            }}
          />
          <CheckboxLabel>
            <CheckboxText>{item.label}</CheckboxText>
          </CheckboxLabel>
        </CheckboxItem>
      ))}
    </CheckboxContainer>
  );
}
