import styled from 'styled-components';

export const AdminHomeGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
`;

export const AdminHomeCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white.main};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 300px;
  box-sizing: border-box;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`;

export const AdminHomeCardTitle = styled.h2`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black.main};
  margin-bottom: 20px;
  opacity: 0.9;
`;
