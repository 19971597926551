import styled from 'styled-components';

const Chip = styled.div<{
  color?: string;
  backgroundColor?: string;
}>`
  width: fit-content;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 0.875rem;
  cursor: default;
  line-height: 1.5;
  color: ${(props) => props.color || props.theme.colors.white.main};
  background-color: ${(props) =>
    props.backgroundColor || props.theme.colors.primary.main};
`;

export default Chip;
