import { pages } from 'pages';
import { Link } from 'react-router-dom';

function HeroSection() {
  return (
    <section className="h-full w-full bg-hero-section-gradient">
      <div className="mx-auto flex max-h-[476px] min-h-[476px] min-w-[1440px] max-w-[1440px] flex-col items-center justify-center gap-[24px]">
        <article className="flex h-full w-full flex-col items-center justify-center gap-2">
          <h1 className="text-center font-suit text-[48px] font-normal leading-[1.35] tracking-[-0.02em] text-white text-opacity-90">
            최적의 탑티어 마케터를
            <br />
            <span className="text-center font-suit text-[48px] font-extrabold leading-[1.35] tracking-[-0.02em] text-white">
              필요한 만큼만 고용하세요
            </span>
          </h1>
          <p className="font-suit text-[20px] font-normal leading-[1.6] tracking-[-0.02em] text-white text-opacity-90">
            번거로웠던 마케터 구인, 이제 마하에서 해결하세요
          </p>
        </article>
        <article className="mt-6 flex h-full w-full items-center justify-center gap-2">
          <Link
            id="0705_sec01_cv0"
            to={pages.AuthLogin.path}
            className="h-[50px] rounded-lg bg-white px-6 py-3"
          >
            <span className="whitespace-nowrap bg-hero-button-gradient bg-clip-text text-center font-suit text-[16px] font-bold leading-[26px] tracking-[0.45px] text-transparent">
              3일 무료! 마케터 구독
            </span>
          </Link>
          <Link
            id="0705_sec01_inquiry_cv0"
            to={pages.KakaoConsult.path}
            target="_blank"
            className="h-[50px]  rounded-lg bg-white bg-opacity-[0.16] px-6 py-3"
          >
            <span className="whitespace-nowrap font-suit text-[16px] font-bold leading-[26px] tracking-[0.45px] text-white">
              24시 즉시 상담
            </span>
          </Link>
        </article>
      </div>
    </section>
  );
}

export default HeroSection;
