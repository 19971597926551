import { useState, useEffect } from 'react';

function HeroSection3() {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prevStep) => (prevStep + 1) % 5);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const steps = [
    {
      title: '1단계',
      description: '1:10 경쟁률 역량 검증',
      width: 'w-[338.72px]',
      height: 'h-[92.77px]',
    },
    {
      title: '2단계',
      description: '인터뷰 및 심층 면접',
      width: 'w-[268.57px]',
      height: 'h-[93.16px]',
    },
    {
      title: '3단계',
      description: '내부 교육',
      width: 'w-[200.07px]',
      height: 'h-[93.16px]',
    },
  ];

  const details = [
    {
      number: '01',
      title: '희망 업무 스택 99.999% 일치',
      description: '희망하는 업무 스택에 완벽히 일치하는 마케터를 배정합니다 ',
    },
    {
      number: '02',
      title: '엄격한 심사로 검증된 퀄리티',
      description:
        '총 3단계를 거친 마케터 합격 절차로 확실한 퀄리티를 보장합니다',
    },
    {
      number: '03',
      title: '직원보다 더 성실한 근무 태도',
      description:
        '마하의 관리 시스템으로 직원보다 성실한 근무 태도로 일합니다',
    },
  ];

  return (
    <section className="h-full w-full bg-[#131733]">
      <div className="mx-auto flex h-[920px] w-[1440px] flex-col px-[120px] py-[96px]">
        <div className="flex items-center justify-center">
          <div className="h-[130px]">
            <div className="flex gap-[28px] w-full">
              <div className="bg-white rounded-lg py-2 px-2.5 w-fit ">
                서류평가
              </div>
              <div className="bg-white rounded-lg py-2 px-2.5 w-fit ">
                인터뷰
              </div>
              <div className="bg-white rounded-lg py-2 px-2.5 w-fit ">
                역량 검증
              </div>
              <div className="bg-white rounded-lg py-2 px-2.5 w-fit ">
                연봉 협상
              </div>
              <div className="bg-white rounded-lg py-2 px-2.5 w-fit ">
                온보딩
              </div>
              <div className="bg-white rounded-lg py-2 px-2.5 w-fit ">
                업무 환경 세팅
              </div>
            </div>
            <p className="font-suit text-center mt-6 text-[40px] font-bold leading-[54px] tracking-[-0.02em] text-[#FFFFFF]">
              마케터 채용, 이제는 쉽게 하세요
              <br />
              번거로운 과정은 이미 마하가 해결했어요
            </p>
            <pre className="mt-4 font-suit text-center text-xl font-normal leading-[30px] tracking-[-0.02em] text-[#FFFFFF]">
              마케터하이어에서 이미 검증된 마케터풀을 구축했습니다.
              <br />
              <span className="font-bold">
                복잡한 절차는 생략하고, 꼭 필요한 부분만 손쉽게 누리세요.
              </span>
            </pre>
          </div>
        </div>
        <div className="mt-[180px] flex items-center justify-between">
          <div className="flex h-[412px] w-[640px] flex-col items-center justify-center gap-y-2 rounded-[20px] bg-[#1F2340] px-[150.64px] py-[63.51px] shadow-section3-box-shadow section3-box">
            {steps.map((step, index) => (
              <div
                key={index}
                style={{
                  backgroundImage: `url(/img/box/section3-box${index + 1}.png)`,
                  opacity: activeStep > index ? 1 : 0,
                  transition: 'opacity 0.5s ease-in-out',
                }}
                className={`${step.width} ${step.height} flex flex-col items-center justify-center`}
              >
                <p className="font-suit text-[14px] font-semibold leading-[17.47px] text-[#FFFFFF]">
                  {step.title}
                </p>
                <p className="font-suit text-[14px] font-normal leading-[17.47px] text-[#FFFFFF]">
                  {step.description}
                </p>
              </div>
            ))}
          </div>
          <div className="flex h-[412px] w-[480px] flex-col items-start justify-start gap-2">
            <div className="text-[30px] font-bold text-white mb-[30px]">
              마케터하이어의 인력 풀 세팅 프로세스
            </div>
            {details.map((detail, index) => (
              <div
                key={index}
                className="h-[109px] w-[480px] gap-4 border-b-[1px] border-solid border-[#D9D9D9]"
              >
                <div className="h-[92px] w-[376px] gap-1">
                  <p
                    className="text-left font-suit text-[16px] font-normal leading-[24px] tracking-[-0.02em] text-[#FFFFFF]"
                    style={{
                      opacity: activeStep > index ? 1 : 0.1,
                      transition: 'opacity 0.5s ease-in-out',
                    }}
                  >
                    {detail.number}
                  </p>
                  <p
                    className="text-left font-suit text-[20px] font-bold leading-[30px] text-[#FFFFFF]"
                    style={{
                      opacity: activeStep > index ? 1 : 0.1,
                      transition: 'opacity 0.5s ease-in-out',
                    }}
                  >
                    {detail.title}
                  </p>
                  <pre
                    className="text-left font-suit text-[16px] font-normal leading-[24px] tracking-[-0.02em] text-[#FFFFFF]"
                    style={{
                      opacity: activeStep > index ? 1 : 0.1,
                      transition: 'opacity 0.5s ease-in-out',
                    }}
                  >
                    {detail.description}
                  </pre>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection3;
