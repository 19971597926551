import { doc, updateDoc } from 'firebase/firestore';
import { auth, db } from 'utils/firebase';

export default async function updateUserName(newName: string) {
  const user = auth.currentUser;
  if (!user) throw new Error('User not found');

  return await updateDoc(doc(db, 'users', user.uid), {
    name: newName,
  });
}
