import styled from 'styled-components';

export const FileInputWrapper = styled.div`
  width: 100%;
`;

export const HiddenInput = styled.input`
  width: fit-content;
  height: 100%;
  opacity: 0;
  display: none;
`;

export const CustomButton = styled.button`
  width: fit-content;
  padding: 12px 28px;
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.white.main};
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.dark};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const FileInputFileList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0;
  max-height: 200px;
  overflow-y: auto;
`;

export const FileInputFile = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px;
  background-color: #f1f1f1;
  border-radius: 4px;
  font-size: 0.875rem;
  cursor: pointer;
`;
