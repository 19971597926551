export const pages = {
  LandingHome: {
    path: '/',
  },
  AuthLogin: {
    path: '/auth',
  },
  AuthSignup: {
    path: '/auth/signup',
  },
  AuthNewPassword: {
    path: '/auth/new-password',
  },
  WorkspaceHome: {
    path: '/workspace',
  },
  WorkspaceAnalytics: {
    path: '/workspace/analytics',
  },
  WorkspaceChats: {
    path: '/workspace/chats',
  },
  WorkspaceSubscriptions: {
    path: '/workspace/subscriptions',
  },
  WorkspaceSettings: {
    path: '/workspace/settings',
  },
  Subscribe: {
    path: '/subscribe',
  },
  AdminHome: {
    path: '/admin',
  },
  AdminContacts: {
    path: '/admin/contacts',
  },
  AdminSubscriptions: {
    path: '/admin/subscriptions',
  },
  AdminUsers: {
    path: '/admin/users',
  },
  PaymentMethodSuccess: {
    path: '/callback/payment-method/success',
  },
  PaymentMethodFail: {
    path: '/callback/payment-method/fail',
  },
  KakaoConsult: {
    path: 'http://pf.kakao.com/_xlgxfHG',
  },
  ServiceIntroduction: {
    path: '/static/media/마케터하이어_서비스소개서.pdf',
  },
};

export const layouts = {
  LandingLayout: {
    path: '/',
  },
  AuthLayout: {
    path: '/auth',
  },
  WorkspaceLayout: {
    path: '/workspace',
  },
  SubscribeLayout: {
    path: '/subscribe',
  },
  AdminLayout: {
    path: '/admin',
  },
  CallbackLayout: {
    path: '/callback',
  },
};
