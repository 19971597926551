export function dateToKst(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'Asia/Seoul',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return new Intl.DateTimeFormat('ko-KR', options).format(date);
}

export function timestampToKst(timestamp: number): string {
  return dateToKst(new Date(timestamp));
}
