import styled from 'styled-components';

export const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
`;

export const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const CalendarTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
`;

export const CalendarButton = styled.div`
  font-size: 1.2rem;
  opacity: 0.7;
  color: ${(props) => props.theme.colors.black.light};
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-top: 16px;
  gap: 8px;
`;

export const CalendarDay = styled.div<{
  selected?: boolean;
  onClick?: () => void;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 5px;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  transition: all 0.3s ease;
  ${(props) =>
    props.selected && `background-color: ${props.theme.colors.white.dark};`}
  &:hover {
    background-color: ${(props) =>
      props.selected ? props.theme.colors.white.dark : 'transparent'};
  }
`;

export const CalendarDayText = styled.span<{
  $color?: string;
  $isToday?: boolean;
}>`
  font-size: 1rem;
  font-weight: 500;
  opacity: ${(props) => (props.$isToday ? 1 : 0.7)};
  padding: 8px;
  border-radius: 50%;

  color: ${(props) =>
    props.$isToday
      ? props.theme.colors.primary.main
      : props.$color || props.theme.colors.black.main};
  background-color: ${(props) =>
    props.$isToday && props.theme.colors.primary[200]};
`;

export const CalendarEventIndicator = styled.div<{
  $show?: boolean;
}>`
  width: 8px;
  height: 8px;
  background-color: ${(props) =>
    props.$show ? props.theme.colors.primary.light : 'transparent'};
  border-radius: 50%;
`;

// hide scrollbar
export const CalendarEventsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 8px;
  overflow-y: scroll;
  height: 120px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CalendarEvent = styled.div<{ selected?: boolean }>`
cursor: pointer;
  display: flex;
  gap: 16px;
  padding: 8px;
  border-radius: 5px;
  align-items: center;
  background-color: ${(props) =>
    props.selected ? props.theme.colors.white.dark : 'transparent'};
  };
`;

export const CalendarEventIndicatorWrapper = styled.div`
  color: ${(props) => props.theme.colors.white.main};
  width: 20px;
  height: 20px;
  display: flex;
  align-items: end;
  justify-content: center;
  padding: 4px;
  background-color: ${(props) => props.theme.colors.primary.main};
  border-radius: 50%;
`;

export const CalendarEventTitle = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: #333;
`;
