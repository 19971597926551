import { TContact } from 'apis/@types';
import getContacts from 'apis/admin/getContacts';
import { useState, useEffect } from 'react';

export default function useContacts() {
  const [contacts, setContacts] = useState<TContact[]>([]);

  useEffect(() => {
    getContacts().then((contacts) => {
      const sortedContacts = contacts.sort((a, b) => {
        return a.createdAt > b.createdAt ? -1 : 1;
      });
      setContacts(sortedContacts);
    });
  }, []);

  function handleCheckContact(contactId: string) {
    setContacts((prevContacts) =>
      prevContacts.map((contact) => {
        if (contact.id === contactId) {
          return { ...contact, check: !contact.check };
        }
        return contact;
      })
    );
  }

  return { contacts, handleCheckContact };
}
