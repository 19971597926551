import { Timestamp } from 'firebase/firestore';

export type Category<T> = {
  value: T;
  label: string;
  disabled?: boolean;
};

export type TContactForm = {
  name: string;
  phone: string;
  email: string;
  check: boolean;
  createdAt: number;
};

export type TContact = TContactForm & {
  id: string;
};

export type TSubscriptionStatus = 'temp' | 'pending' | 'matched' | 'end';

export type TSubscribeForm = {
  companyName: string;
  workName: string;
  industry: string;
  plan: string;
  startDate: string;
  reference: (string | File)[];
  etc: string;
};

// 가전제품
// 가구
// 생활용품
// 건강/의료
// 공구/자재
// 금융서비스
// 기념일/이벤트
// 반려동물
// 뷰티
// 스포츠/레저
// 식품
// 여행
// 육아
// 의류/액세서리
// 자동차
// 취미
// 기타
export type TSubscriptionIndustryValue =
  | '가전제품'
  | '가구'
  | '생활용품'
  | '건강/의료'
  | '공구/자재'
  | '금융서비스'
  | '기념일/이벤트'
  | '반려동물'
  | '뷰티'
  | '스포츠/레저'
  | '식품'
  | '여행'
  | '육아'
  | '의류/액세서리'
  | '자동차'
  | '취미'
  | '기타';

export const SubscriptionIndustryCategories: Category<TSubscriptionIndustryValue>[] =
  [
    { value: '가전제품', label: '가전제품' },
    { value: '가구', label: '가구' },
    { value: '생활용품', label: '생활용품' },
    { value: '건강/의료', label: '건강/의료' },
    { value: '공구/자재', label: '공구/자재' },
    { value: '금융서비스', label: '금융서비스' },
    { value: '기념일/이벤트', label: '기념일/이벤트' },
    { value: '반려동물', label: '반려동물' },
    { value: '뷰티', label: '뷰티' },
    { value: '스포츠/레저', label: '스포츠/레저' },
    { value: '식품', label: '식품' },
    { value: '여행', label: '여행' },
    { value: '육아', label: '육아' },
    { value: '의류/액세서리', label: '의류/액세서리' },
    { value: '자동차', label: '자동차' },
    { value: '취미', label: '취미' },
    { value: '기타', label: '기타' },
  ];

// standard, all-in-one, enterprise, conslut
export type TSubscriptionPlanValue =
  | 'free'
  | 'standard'
  | 'all-in-one'
  | 'enterprise';

export const SubscriptionPlanCategories: Category<TSubscriptionPlanValue>[] = [
  { value: 'free', label: 'FREE (무료 / 분석 보고서 제공)' },
  { value: 'standard', label: 'STANDARD (130만 원 / 전략 및 집행)' },
  { value: 'all-in-one', label: 'ALL-IN-ONE (300만 원 / 콘텐츠 포함 올인원)' },
  { value: 'enterprise', label: 'ENTERPRISE (문의 / 사업 전반)' },
];

export const getSubscriptionPlanLabel = (value: TSubscriptionPlanValue) => {
  return SubscriptionPlanCategories.find((category) => category.value === value)
    ?.label;
};

export type TSubscription = {
  id?: string;
  companyName: string;
  workName: string;
  industry: TSubscriptionIndustryValue;
  plan: TSubscriptionPlanValue;
  startDate: string;
  paymentDate: string | null;
  endDate: string | null;
  reference: string[];
  etc: string;
  clientId: string;
  status: TSubscriptionStatus;
  partners: string[];
  createdAt: Timestamp;
};

export type TUserProfile = {
  id?: string;
  name: string;
  email: string;
  phone: string;
  role: 'client' | 'partner' | 'admin';
  profileImage?: string;
  createdAt: Timestamp;
  lastVisitAt?: Timestamp;
  reload: () => void;
};

export type TCard = {
  acquirerCode: string;
  cardType: string;
  issuerCode: string;
  number: string;
  ownerType: string;
};

export type TPaymentMethod = {
  authenticatedAt: string;
  billingKey: string;
  card: TCard;
  cardCompany: string;
  cardNumber: string;
  customerKey: string;
  mId: string;
  method: string;
};
