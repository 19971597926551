import {
  SidebarItemContainer,
  SidebarItemIcon,
  SidebarItemText,
} from 'layouts/workspace/components/Sidebar/Sidebar.styles';
import { ReactNode } from 'react';

interface SidebarItemProps {
  icon: ReactNode;
  text: string;
  path: string;
  $active?: boolean;
  $exclude?: boolean;
}

export default function SidebarItem({
  icon,
  text,
  path,
  $active: active,
  $exclude: exclude,
}: SidebarItemProps) {
  return (
    <SidebarItemContainer $active={active} to={path} $exclude={exclude}>
      <SidebarItemIcon>{icon}</SidebarItemIcon>
      <SidebarItemText>{text}</SidebarItemText>
    </SidebarItemContainer>
  );
}
