// @ts-nocheck
import { ScrollProvider } from 'landing/contexts/ScrollContext';
import MobileSection from 'landing/mobile_sections/mobileSection';
import MobileSection10 from 'landing/mobile_sections/mobileSection10';
import MobileSection11 from 'landing/mobile_sections/mobileSection11';
import MobileSection12 from 'landing/mobile_sections/mobileSection12';
import MobileSection14 from 'landing/mobile_sections/mobileSection14';
import MobileSection15 from 'landing/mobile_sections/mobileSection15';
import MobileSection2 from 'landing/mobile_sections/mobileSection2';
import MobileSection3 from 'landing/mobile_sections/mobileSection3';
import MobileSection5 from 'landing/mobile_sections/mobileSection5';
import MobileSection6 from 'landing/mobile_sections/mobileSection6';
import MobileSection7 from 'landing/mobile_sections/mobileSection7';
import MobileSection9 from 'landing/mobile_sections/mobileSection9';

export default function MobileLanding() {
  return (
    <main className="pc:hidden zero:flex overflow-x-hidden w-screen flex-col items-center justify-start">
      <ScrollProvider>
        <MobileSection />
        <MobileSection2 />
        <MobileSection3 />
        <MobileSection5 />
        <MobileSection6 />
        <MobileSection7 />
        <MobileSection9 />
        <MobileSection10 />
        <MobileSection11 />
        <MobileSection12 />
        <MobileSection14 />
        <MobileSection15 />
      </ScrollProvider>
    </main>
  );
}
