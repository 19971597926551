function HeroSection2() {
  return (
    <section className="h-full w-full bg-[#0000000A]">
      <div className="mx-auto flex h-[760px] w-[1440px] flex-col px-[120px] py-[96px]">
        <article className="flex h-full w-full flex-col items-start gap-2">
          <p className="font-suit text-[20px] font-normal leading-[1.6] tracking-[-0.02em] text-[#000000] text-opacity-80">
            마케터 구독 서비스를 처음 알게 되신 분들에게
          </p>
          <h2 className="text-left font-suit text-[40px] font-bold leading-[1.5] tracking-[-0.02em] text-[#000000E5]">
            마케터하이어는
            <br />
            이런 고민을 해결합니다.
          </h2>
        </article>
        <article className="mt-[32px] flex h-[400px] w-[1200px] flex-col flex-wrap content-start items-start gap-5">
          <div className="order-1 flex h-[400px] w-[285px] flex-none flex-grow flex-col items-start justify-start gap-5 rounded-[20px] bg-[#FFFFFF] p-6">
            <img
              src="/img/logo/section2-logo1.png"
              alt="section2-logo1"
              className="order-0 h-[100px] w-[100px] flex-none flex-grow-0 rounded-[50px]"
            />
            <div className="order-1 flex w-[237px] flex-none flex-grow-0 flex-col items-start justify-center gap-2 self-stretch whitespace-nowrap">
              <div className="flex flex-none flex-grow-0 flex-row items-center gap-2">
                <div className="flex flex-row items-center rounded bg-[#795BF914] px-2 py-[2px]">
                  {/* # CASE 01  font-family: 'DM Sans' */}
                  <p className="text-sm font-medium leading-[19.6px] tracking-[-0.02em] text-[#6F42F0]">
                    # CASE 01
                  </p>
                </div>
                <p className="font-suit text-sm font-bold leading-[19.6px] tracking-[-0.02em] text-[#6F42F0]">
                  검증된 경력직 인력 구인
                </p>
              </div>
              <pre className="self-stretch text-left font-suit text-xl font-bold leading-[32px] tracking-[-0.02em] text-[#000000CC]">
                성장을 이미 경험해 본
                <br />
                전문가가 필요한데...
              </pre>
              <pre className="self-stretch text-left font-suit text-[16px] font-normal leading-[25.6px] tracking-[0.02em] text-[#000000CC]">
                내부에 마케팅 전문가가 없으니 자체적
                <br />
                으로 마케팅 효율을 평가하거나, 실무
                <br />
                집행의 디테일을 설정하는 데 어려움이
                <br />
                있어요. 숙련된 전문가의 경험과 역량,
                <br />
                인사이트가 필요해요.
              </pre>
            </div>
          </div>
          <div className="order-1 flex h-[400px] w-[285px] flex-none flex-grow flex-col items-start justify-start gap-5 rounded-[20px] bg-[#FFFFFF] p-6">
            <img
              src="/img/logo/section2-logo2.png"
              alt="section2-logo2"
              className="order-0 h-[100px] w-[100px] flex-none flex-grow-0 rounded-[50px]"
            />
            <div className="order-1 flex h-[208px] w-[237px] flex-none flex-grow-0 flex-col items-start justify-start gap-2 self-stretch whitespace-nowrap">
              <div className="flex flex-none flex-grow-0 flex-row items-center gap-2">
                <div className="flex flex-row items-center rounded bg-[#795BF914] px-2 py-[2px]">
                  {/* # CASE 01  font-family: 'DM Sans' */}
                  <p className="text-sm font-medium leading-[19.6px] tracking-[-0.02em] text-[#6F42F0]">
                    # CASE 02
                  </p>
                </div>
                <p className="font-suit text-sm font-bold leading-[19.6px] tracking-[-0.02em] text-[#6F42F0]">
                  채용 리스크 헷지
                </p>
              </div>
              <pre className="self-stretch text-left font-suit text-xl font-bold leading-[32px] tracking-[-0.02em] text-[#000000CC]">
                초기 팀은 채용 부담이 커요
              </pre>
              <pre className="self-stretch text-left font-suit text-[16px] font-normal leading-[25.6px] tracking-[0.02em] text-[#000000CC]">
                복지, 부대 비용, 인사 비용, 퇴직금...
                <br />
                초기 성장 중인 팀이 검증된 마케터를
                <br />
                채용하려니 비용 부담이 커요. 가장
                <br />
                빠르고 쉽게 최적의 마케터를 구하려면
                <br />
                어떻게 해야 할까요?
              </pre>
            </div>
          </div>
          <div className="order-1 flex h-[400px] w-[285px] flex-none flex-grow flex-col items-start justify-start gap-5 rounded-[20px] bg-[#FFFFFF] p-6">
            <img
              src="/img/logo/section2-logo3.png"
              alt="section2-logo3"
              className="order-0 h-[100px] w-[100px] flex-none flex-grow-0 rounded-[50px]"
            />
            <div className="order-1 flex h-[208px] w-[237px] flex-none flex-grow-0 flex-col items-start justify-center gap-2 self-stretch whitespace-nowrap">
              <div className="flex flex-none flex-grow-0 flex-row items-center gap-2">
                <div className="flex flex-row items-center rounded bg-[#795BF914] px-2 py-[2px]">
                  {/* # CASE 01  font-family: 'DM Sans' */}
                  <p className="text-sm font-medium leading-[19.6px] tracking-[-0.02em] text-[#6F42F0]">
                    # CASE 03
                  </p>
                </div>
                <p className="font-suit text-sm font-bold leading-[19.6px] tracking-[-0.02em] text-[#6F42F0]">
                  광고 대행사 페인포인트
                </p>
              </div>
              <pre className="self-stretch text-left font-suit text-xl font-bold leading-[32px] tracking-[-0.02em] text-[#000000CC]">
                대행사를 활용 중인데, 원하는
                <br />
                만큼의 성과가 나오지 않아요
              </pre>
              <pre className="self-stretch text-left font-suit text-[16px] font-normal leading-[25.6px] tracking-[0.02em] text-[#000000CC]">
                효율은 그대로인데 예산만 늘어나고,
                <br />
                표로 했던 KPI를 달성하기가
                <br />
                어려워요. 문제의 원인 파악과 해결책을
                <br />
                더 적극적으로 탐색하고 제시할 수 있는
                <br />
                실력있는 전문가의 도움을 받고 싶어요.
              </pre>
            </div>
          </div>
          <div className="order-1 flex h-[400px] w-[285px] flex-none flex-grow flex-col items-start justify-start gap-5 rounded-[20px] bg-[#FFFFFF] p-6">
            <img
              src="/img/logo/section2-logo4.png"
              alt="section2-logo4"
              className="order-0 h-[100px] w-[100px] flex-none flex-grow-0 rounded-[50px]"
            />
            <div className="order-1 flex h-[208px] w-[237px] flex-none flex-grow-0 flex-col items-start justify-center gap-2 self-stretch whitespace-nowrap">
              <div className="flex flex-none flex-grow-0 flex-row items-center gap-2">
                <div className="flex flex-row items-center rounded bg-[#795BF914] px-2 py-[2px]">
                  {/* # CASE 01  font-family: 'DM Sans' */}
                  <p className="text-sm font-medium leading-[19.6px] tracking-[-0.02em] text-[#6F42F0]">
                    # CASE 04
                  </p>
                </div>
                <p className="font-suit text-sm font-bold leading-[19.6px] tracking-[-0.02em] text-[#6F42F0]">
                  핵심 인력이 필요할 때
                </p>
              </div>
              <pre className="self-stretch text-left font-suit text-xl font-bold leading-[32px] tracking-[-0.02em] text-[#000000CC]">
                우리의 프로덕트에 200%
                <br />
                몰입할 마케터를 찾아요
              </pre>
              <pre className="self-stretch text-left font-suit text-[16px] font-normal leading-[25.6px] tracking-[0.02em] text-[#000000CC]">
                성과나 실무 집행에서 확실한 케어를
                <br />
                받고 싶습니다. 단순 업무가 아닌
                <br />
                고도화된 방식으로, 직원처럼 함께
                <br />
                해주실 전문가는 없을까요?
              </pre>
            </div>
          </div>
        </article>
      </div>
    </section>
  );
}

export default HeroSection2;
