import styled from 'styled-components';

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
`;

export const MenuItem = styled.div<{
  $active?: boolean;
  opacity?: number;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;
  opacity: ${(props) => props.opacity || 0.9};
  transition: background-color 0.2s;

  background-color: ${(props) =>
    props.$active ? props.theme.colors.primary.light : 'transparent'};
  color: ${(props) =>
    props.$active
      ? props.theme.colors.white.main
      : props.theme.colors.black.main};
  &:hover {
    background-color: ${(props) =>
      props.$active
        ? props.theme.colors.primary.light
        : props.theme.colors.white.dark};
  }
`;
