import { Blank } from 'Globalstyles';
import { TSubscriptionStatus } from 'apis/@types';
import { Tab, Tabs } from 'components/Tab/Tabs';
import {
  AdminSubscriptionsContext,
  AdminUsersContext,
} from 'layouts/admin/admin_layout';
import { pages } from 'pages';
import { AdminContainer, AdminTitle } from 'pages/admin/Admin.styles';
import SubscriptionContainer from 'pages/admin/components/SubscriptionContainer';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const AdminSubscriptionCardButton = styled.button`
  width: 100%;
  height: 40px;
  border: 1px solid ${(props) => props.theme.colors.primary.main};
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.primary.main};
  color: ${(props) => props.theme.colors.white.main};
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
`;

export default function AdminSubscriptions() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const status = (new URLSearchParams(search).get('status') ??
    'pending') as TSubscriptionStatus;
  const users = useContext(AdminUsersContext);
  const subscriptions = useContext(AdminSubscriptionsContext);

  return (
    <AdminContainer>
      <AdminTitle>구독관리</AdminTitle>
      <Tabs>
        <Tab
          active={status === 'pending'}
          onClick={() =>
            navigate(`${pages.AdminSubscriptions.path}?status=pending`)
          }
        >
          검토중 {subscriptions.filter((s) => s.status === 'pending').length}
        </Tab>
        <Tab
          active={status === 'matched'}
          onClick={() =>
            navigate(`${pages.AdminSubscriptions.path}?status=matched`)
          }
        >
          구독중 {subscriptions.filter((s) => s.status === 'matched').length}
        </Tab>
        <Tab
          active={status === 'end'}
          onClick={() =>
            navigate(`${pages.AdminSubscriptions.path}?status=end`)
          }
        >
          마감 {subscriptions.filter((s) => s.status === 'end').length}
        </Tab>
      </Tabs>
      <Blank $height="24px" />
      <SubscriptionContainer
        subscriptions={subscriptions}
        users={users}
        status={status}
      />
    </AdminContainer>
  );
}
