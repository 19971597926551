import { WorkspaceCard } from 'pages/workspace/Workspace.styles';
import { TAnalyticsMenuItem } from 'pages/workspace/WorkspaceAnalytics';
import {
  MenuContainer,
  MenuItem,
} from 'pages/workspace/components/Menu.styles';
import weekly_visitors from 'pages/workspace/analytics/images/weekly_visitors.png';

interface IAnalyticsMenuProps {
  analyticsItems: TAnalyticsMenuItem[];
  selectedItemId: number;
  setSelectedItem: (id: number) => void;
}

export default function AnalyticsMenu({
  analyticsItems,
  selectedItemId,
  setSelectedItem,
}: IAnalyticsMenuProps) {
  return (
    <WorkspaceCard $width="280px" $padding="12px">
      <MenuContainer>
        {analyticsItems.map((item) => (
          <MenuItem
            key={item.label}
            $active={selectedItemId === item.id}
            onClick={() => setSelectedItem(item.id)}
          >
            <img src={weekly_visitors} alt={item.label} width={'100%'} />
            {item.label}
          </MenuItem>
        ))}
      </MenuContainer>
    </WorkspaceCard>
  );
}
