import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const adminSidebarWidth = '100px';

export const AdminSidebarContainer = styled.aside`
  width: ${adminSidebarWidth};
  box-sizing: border-box;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.main};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  padding-top: 64px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
`;

export const AdminSidebarItemContainer = styled(Link)<{
  $active?: boolean;
  $exclude?: boolean;
}>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.$active ? props.theme.colors.primary.main : 'transparent'};

  border: none;
  padding: 16px;

  opacity: 0.7;
  cursor: pointer;
  display: flex;
  align-items: center;

  gap: 2px;

  ${(props) =>
    props.$exclude
      ? `
    color: ${props.theme.colors.primary.dark};
  `
      : `
    color: ${
      props.$active
        ? props.theme.colors.white.main
        : props.theme.colors.black.main
    };
    `}
`;

export const AdminSidebarItemIcon = styled.span`
  font-size: 1.8rem;
`;

export const AdminSidebarItemText = styled.span`
  font-size: 1rem;
  font-weight: 500;
`;
