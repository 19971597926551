import { pages } from 'pages';
import { Link } from 'react-router-dom';

function MobileSection5() {
  return (
    <section className="h-full w-full bg-section5-gradient-color px-4">
      <div className="mx-auto flex flex-col items-center justify-between px-4 py-14">
        <h2 className="text-center mb-6 font-suit text-2xl font-normal leading-[36px] tracking-[-0.02em] text-white">
          검증된 역량으로,
          <br />
          정확하고 빠르게 문제를 해결해 줄
          <br />
          <span className="font-suit text-2xl font-bold leading-[36px] tracking-[-0.02em]">
            전담 마케터와 함께 일하세요
          </span>
        </h2>
        <div className="flex w-[172px] flex-col items-center gap-2">
          <Link
            id="mo0705_sec04_inquiry_cv0"
            to={pages.AuthLogin.path}
            className="flex h-[50px] w-full items-center justify-center whitespace-nowrap rounded-lg bg-[#FFFFFF] px-6 py-3"
          >
            <span className="bg-section5-gradient-text bg-clip-text font-suit text-[16px] font-bold tracking-[0.45px] text-section5-gradient-text">
              지금 바로 구독하기
            </span>
          </Link>
          <Link
            id="mo0705_sec04_cv0"
            to={pages.KakaoConsult.path}
            target="_blank"
            className="flex h-[50px] w-full items-center justify-center whitespace-nowrap rounded-lg bg-[#FFFFFF33] px-6 py-3"
          >
            <span className="font-suit text-[16px] font-bold tracking-[0.45px] text-[#FFFFFF]">
              24시 즉시 상담 신청
            </span>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default MobileSection5;
