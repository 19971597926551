import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

function MobileSection3() {
  const steps = [
    {
      title: '1단계',
      description: '1:10 경쟁률 역량 검증',
      width: 'w-[220px]',
      height: 'h-[46px]',
    },
    {
      title: '2단계',
      description: '인터뷰 및 심층 면접',
      width: 'w-[175px]',
      height: 'h-[46px]',
    },
    {
      title: '3단계',
      description: '내부 교육',
      width: 'w-[129px]',
      height: 'h-[46px]',
    },
  ];
  const details = [
    {
      number: '01',
      title: '희망 업무 스택 99.999% 일치',
      description:
        '희망하는 업무 스택에 완벽히 일치하는\n 마케터를 배정합니다 ',
    },
    {
      number: '02',
      title: '엄격한 심사로 검증된 퀄리티',
      description:
        '총 3단계를 거친 마케터 합격 절차로 \n확실한 퀄리티를 보장합니다',
    },
    {
      number: '03',
      title: '직원보다 더 성실한 근무 태도',
      description:
        '마하의 관리 시스템으로 직원보다 \n성실한 근무 태도로 일합니다',
    },
  ];

  return (
    <section className="w-full bg-[#131733] px-4">
      <div className="mx-auto flex flex-col px-4 py-12">
        <div className="flex items-center justify-center">
          <div className="gap-y-2">
            <div className="flex justify-center gap-[28px] w-full">
              <div className="bg-white rounded-lg py-2 px-2.5 w-fit ">
                서류평가
              </div>
              <div className="bg-white rounded-lg py-2 px-2.5 w-fit ">
                역량 검증
              </div>
              <div className="bg-white rounded-lg py-2 px-2.5 w-fit ">
                연봉 협상
              </div>
            </div>
            <p className="mt-7 font-suit text-center text-[26px] font-bold leading-[37.7px] tracking-[-0.02em] text-[#FFFFFF]">
              마케터 채용, 이제는 쉽게 하세요
            </p>
            <p className="font-suit text-center text-[22px] font-bold leading-[37.7px] tracking-[-0.02em] text-[#FFFFFF]">
              번거로운 과정은 이미 마하가 해결했어요
            </p>

            <pre className="mt-2 text-center font-suit text-[16px] leading-[24px] tracking-[-0.02em] text-[#FFFFFF]">
              마케터하이어에서 이미 검증된 마케터풀을 구축했습니다.
              <br />
              <strong className="font-bold">
                복잡한 절차는 생략하고, 꼭 필요한 부분만 손쉽게 누리세요.
              </strong>
            </pre>
          </div>
        </div>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          className="mt-10 w-full"
          autoplay={{ delay: 2000 }}
          pagination={{ clickable: true, el: '.custom-pagination' }}
          modules={[Autoplay, Pagination]}
        >
          {steps.map((step, index) => (
            <SwiperSlide key={index}>
              <div className="mx-auto max-w-[500px]">
                <div className="h-[230px] rounded-[20px] py-7 shadow-section3-box-shadow section3-box">
                  {steps.slice(0, index + 1).map((s, idx) => (
                    <div
                      key={idx}
                      style={{
                        backgroundImage: `url(/img/box/section3-box${
                          idx + 1
                        }.png)`,
                      }}
                      className={`${s.width} ${s.height} mx-auto mt-2 flex flex-col items-center justify-center bg-cover`}
                    >
                      <p className="font-suit text-[12px] font-semibold leading-[17.47px] text-[#FFFFFF]">
                        {s.title}
                      </p>
                      <p className="font-suit text-[12px] font-normal leading-[17.47px] text-[#FFFFFF]">
                        {s.description}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="my-6 flex w-full flex-col items-start justify-start gap-2">
                  <div className="w-full gap-4">
                    <div className="w-full gap-1">
                      <p className="text-center font-suit text-[20px] font-bold leading-[30px] text-[#FFFFFF]">
                        {details[index].title}
                      </p>
                      <pre className="text-center mt-2 font-suit text-[16px] font-normal leading-[24px] tracking-[-0.02em] text-[#FFFFFF]">
                        {details[index].description}
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="custom-pagination flex justify-center gap-1"></div>
      </div>
    </section>
  );
}

export default MobileSection3;
