import { FlexColumn, Logo } from 'Globalstyles';
import {
  FooterText,
  FooterTextBold,
  FooterWrapper,
} from 'layouts/landing/components/Footer/Footer.styles';
import logo from 'assets/logo.png';
import { MobileNewLine } from 'pages/deprecated_landing/sections/sections.styles';

export default function Footer() {
  return (
    <FooterWrapper>
      <Logo src={logo} alt="logo" cursor="pointer" />
      <FooterText>Copyright ⓒ 매치스 플랜. All Rights Reserved</FooterText>
      <FlexColumn $alignItems="center">
        <FooterText style={{ textAlign: 'center' }}>
          {/* 상호명매치스 플랜 |사업자등록번호868-15-01597 |통신판매신고 제 2022-서울마포-2746호 |대표김이삭 */}
          <FooterTextBold>상호명</FooterTextBold>
          매치스 플랜 |<FooterTextBold>사업자등록번호</FooterTextBold>
          868-15-01597 |
          <MobileNewLine />
          <FooterTextBold>통신판매신고 </FooterTextBold>제 2022-서울마포-2746호
          |<FooterTextBold>대표</FooterTextBold>
          김이삭
        </FooterText>
        <FooterText style={{ textAlign: 'center' }}>
          {/* 주소 서울특별시 마포구 잔다리로7안길 27 | 전화번호 010-8000-6959 */}
          <FooterTextBold>주소</FooterTextBold>서울특별시 마포구 잔다리로7안길
          27 |
          <MobileNewLine />
          <FooterTextBold>전화번호</FooterTextBold> 010-8000-6959
        </FooterText>
        <FooterText>
          <FooterTextBold>이메일 및 문의</FooterTextBold>
          support@designer-hire.com
        </FooterText>
      </FlexColumn>
    </FooterWrapper>
  );
}
