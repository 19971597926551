import Calendar from 'components/Calendar/Calendar';
import subscriptionsToEvents from 'layouts/workspace/utils/subscriptionsToEvents';
import {
  SubscriptionsContext,
  UserContext,
} from 'layouts/workspace/workspace_layout';
import ProfileCard from 'pages/workspace/components/ProfileCard/ProfileCard';
import ServiceUsage from 'pages/workspace/components/ServiceUsage';
import {
  WorkspaceCard,
  WorkspaceCardWrapper,
  WorkspaceContainer,
  WorkspaceTitle,
} from 'pages/workspace/Workspace.styles';
import { useContext } from 'react';

export default function WorkspaceHome() {
  const subscriptions = useContext(SubscriptionsContext);
  const userProfile = useContext(UserContext);

  return (
    <WorkspaceContainer>
      <WorkspaceTitle>대시보드</WorkspaceTitle>
      <WorkspaceCardWrapper>
        <ProfileCard userProfile={userProfile} />
        <WorkspaceCard $padding="8px">
          <ServiceUsage />
        </WorkspaceCard>
        <WorkspaceCard $width="400px">
          <Calendar events={subscriptionsToEvents(subscriptions)} />
        </WorkspaceCard>
      </WorkspaceCardWrapper>
    </WorkspaceContainer>
  );
}
