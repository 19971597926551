import AdminLayout from 'layouts/admin/admin_layout';
import AuthLayout from 'layouts/auth/auth_layout';
import LandingLayout from 'layouts/landing/landing_layout';
import WorkspaceLayout from 'layouts/workspace/workspace_layout';
import { layouts, pages } from 'pages';
import AdminContacts from 'pages/admin/AdminContacts';
import AdminHome from 'pages/admin/AdminHome';
import AuthLogin from 'pages/auth/AuthLogin';
import AuthNewPassword from 'pages/auth/AuthNewPassword';
import AuthSignup from 'pages/auth/AuthSignup';
import WorkspaceHome from 'pages/workspace/WorkspaceHome';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import SubscribeLayout from 'layouts/subscribe/subscribe_layout';
import Subscribe from 'pages/subscribe/Subscribe';
import WorkspaceSubscriptions from 'pages/workspace/WorkspaceSubscriptions';
import WorkspaceSettings from 'pages/workspace/WorkspaceSettings';
import WorkspaceAnalytics from 'pages/workspace/WorkspaceAnalytics';
import WorkspaceChats from 'pages/workspace/WorkspaceChats';
import PaymentMethodSuccess from 'pages/callback/PaymentMethodSuccess';
import PaymentMethodFail from 'pages/callback/PaymentMethodFail';
import AdminSubscriptions from 'pages/admin/AdminSubscriptions';
import AdminUsers from 'pages/admin/AdminUsers';
import 'App.css';
import Landing from 'pages/Landing';

function App() {
  return (
    <Routes>
      <Route path={layouts.LandingLayout.path} element={<LandingLayout />}>
        <Route index element={<Landing />} />
      </Route>
      <Route path={layouts.AuthLayout.path} element={<AuthLayout />}>
        <Route index element={<AuthLogin />} />
        <Route path={pages.AuthSignup.path} element={<AuthSignup />} />
        <Route
          path={pages.AuthNewPassword.path}
          element={<AuthNewPassword />}
        />
      </Route>
      <Route path={layouts.WorkspaceLayout.path} element={<WorkspaceLayout />}>
        <Route index element={<WorkspaceHome />} />
        <Route
          path={pages.WorkspaceAnalytics.path}
          element={<WorkspaceAnalytics />}
        />
        <Route path={pages.WorkspaceChats.path} element={<WorkspaceChats />} />
        <Route
          path={pages.WorkspaceSubscriptions.path}
          element={<WorkspaceSubscriptions />}
        />
        <Route
          path={pages.WorkspaceSettings.path}
          element={<WorkspaceSettings />}
        />
        <Route
          path={pages.WorkspaceSettings.path + '/:tab'}
          element={<WorkspaceSettings />}
        />
      </Route>
      <Route path={layouts.SubscribeLayout.path} element={<SubscribeLayout />}>
        <Route index element={<Subscribe />} />
        <Route path={`${pages.Subscribe.path}/:id`} element={<Subscribe />} />
      </Route>
      <Route path={layouts.AdminLayout.path} element={<AdminLayout />}>
        <Route index element={<AdminHome />} />
        <Route path={pages.AdminContacts.path} element={<AdminContacts />} />
        <Route
          path={pages.AdminSubscriptions.path}
          element={<AdminSubscriptions />}
        />
        <Route path={pages.AdminUsers.path} element={<AdminUsers />} />
      </Route>

      <Route path={layouts.CallbackLayout.path} element={<Outlet />}>
        <Route
          path={pages.PaymentMethodSuccess.path}
          element={<PaymentMethodSuccess />}
        />
        <Route
          path={pages.PaymentMethodFail.path}
          element={<PaymentMethodFail />}
        />
      </Route>

      <Route path="*" element={<Navigate to={pages.LandingHome.path} />} />
    </Routes>
  );
}

export default App;
