import { pages } from 'pages';
import { Link } from 'react-router-dom';

function HeroSection15() {
  return (
    <section className="h-full w-full">
      <div className="mx-auto flex h-[1062px] w-full flex-col items-center justify-start gap-8 bg-white px-[120px] pb-[32px] pt-[96px]">
        <div className="h-[96px] w-[573px] gap-1">
          <p className="text-center font-suit text-[20px] font-medium leading-[32px] tracking-[-0.02em] text-[#00000099]">
            2,451+ 명의 마케터 24시간 대기중
          </p>
          <h3 className="text-center font-suit text-[40px] font-bold leading-[60px] tracking-[-0.02em] text-[#000000E5]">
            탑티어 마케터 일 2시간, 월 110만 원
          </h3>
        </div>
        <div className="flex h-[428px] w-[1200px] items-center justify-between gap-5">
          <Link
            to={pages.AuthLogin.path}
            className="flex h-[428px] w-[386.67px] flex-col items-start justify-start rounded-[20px] bg-[#0000000A] px-6 py-8"
          >
            <img
              src="/img/logo/section15-logo1.png"
              alt="section15-logo1"
              className="h-[130px] w-[130px]"
            />
            <div className="mt-[14px] h-[172px] w-[338.67px] gap-2">
              <h5 className="text-left font-suit text-[24px] font-bold leading-[33.6px] tracking-[-0.02em] text-[#000000E5]">
                서비스를 이제 막 구축하는
                <br />
                초기 스타트업이라면?
              </h5>
              <pre className="mt-[8px] whitespace-nowrap text-left font-suit text-[20px] font-normal leading-[32px] tracking-[-0.02em] text-[#000000CC]">
                이미 초기 스타트업 경험이 여러 번 있고,
                <br />
                원활하게 커뮤니케이션하며 서비스를 함께
                <br />
                성장시킬 숙련된 마케터를 배정해 드려요
              </pre>
            </div>
            <div className="mt-4 cursor-pointer text-left font-suit text-[20px] font-bold leading-[32px] tracking-[-0.02em] text-[#795BF9]">
              마케터 배정 받기 &gt;
            </div>
          </Link>
          <Link
            to={pages.AuthLogin.path}
            className="flex h-[428px] w-[386.67px] flex-col items-start justify-start rounded-[20px] bg-[#0000000A] px-6 py-8"
          >
            <img
              src="/img/logo/section15-logo2.png"
              alt="section15-logo2"
              className="h-[130px] w-[130px]"
            />
            <div className="mt-[14px] h-[172px] w-[338.67px] gap-2">
              <h5 className="text-left font-suit text-[24px] font-bold leading-[33.6px] tracking-[-0.02em] text-[#000000E5]">
                급한 업무도 많고 정신없이
                <br />
                성장 중인 중소 기업이라면?
              </h5>
              <pre className="mt-[8px] whitespace-nowrap text-left font-suit text-[20px] font-normal leading-[32px] tracking-[-0.02em] text-[#000000CC]">
                직접 마케팅 전략을 수립하거나 인사이트를
                <br />
                제안하며, 한정된 예산으로 높은 효율을 뽑아
                <br />낼 수 있는 꼼꼼이 마케터를 배정해 드려요
              </pre>
            </div>
            <div className="mt-4 cursor-pointer text-left font-suit text-[20px] font-bold leading-[32px] tracking-[-0.02em] text-[#795BF9]">
              마케터 배정 받기 &gt;
            </div>
          </Link>
          <Link
            to={pages.AuthLogin.path}
            className="flex h-[428px] w-[386.67px] flex-col items-start justify-start rounded-[20px] bg-[#0000000A] px-6 py-8"
          >
            <img
              src="/img/logo/section15-logo3.png"
              alt="section15-logo3"
              className="h-[130px] w-[130px]"
            />
            <div className="mt-[14px] h-[172px] w-[338.67px] gap-[8px]">
              <h5 className="text-left font-suit text-[24px] font-bold leading-[33.6px] tracking-[-0.02em] text-[#000000E5]">
                체계가 잘 잡혀있고 성숙한
                <br />
                중견·대기업이라면?
              </h5>
              <pre className="mt-[8px] whitespace-nowrap text-left font-suit text-[20px] font-normal leading-[32px] tracking-[-0.02em] text-[#000000CC]">
                아웃소싱 업체나 광고 대행사와 컨택하며
                <br />
                확실하게 성과를 평가하고 직접 핸들링할
                <br />
                능숙한 마케터를 배정해 드려요
              </pre>
            </div>
            <div className="mt-4 cursor-pointer text-left font-suit text-[20px] font-bold leading-[32px] tracking-[-0.02em] text-[#795BF9]">
              마케터 배정 받기 &gt;
            </div>
          </Link>
        </div>
        <div className="flex h-[346px] w-[1200px] flex-col items-center justify-start rounded-3xl bg-[#131733] pb-[85.16px] pt-[66px]">
          <h4 className="text-center font-suit text-[36px] font-normal leading-[48.6px] tracking-[-0.02em] text-[#FFFFFF]">
            어떤 마케터가 필요한지 모르겠다면?
            <br />
            <span className="font-bold">상담 후 결정해 보세요</span>
          </h4>
          <div className="mt-[46.84px] flex h-[50px] w-[328px] items-center justify-between gap-2">
            <Link
              id="0705_sec12_inquiry_cv0"
              to={pages.KakaoConsult.path}
              target="_blank"
              className="h-[50px] w-[148px] whitespace-nowrap rounded-[8px] bg-[#FFFFFF4D] px-6 py-3 text-center font-suit text-[16px] font-bold leading-[26px] tracking-[0.45px] text-[#FFFFFF]"
            >
              24시 즉시 상담
            </Link>
            <Link
              id="0705_sec12_cv0"
              to={pages.AuthLogin.path}
              className="h-[50px] w-[172px] whitespace-nowrap rounded-lg bg-[white] px-6 py-3 text-center font-suit text-[16px] font-bold leading-[26px] tracking-[0.45px] text-[#131733]"
            >
              지금 바로 구독하기{' '}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection15;
