import { createUserWithEmailAndPassword } from 'firebase/auth';
import { Timestamp, doc, setDoc } from 'firebase/firestore';
import { TSignupForm } from 'pages/auth/AuthSignup';
import { auth, db } from 'utils/firebase';

export default async function signupClient({
  email,
  password,
  phone,
  role,
}: Omit<TSignupForm, 'password-confirm'> & {
  phone: string;
  role: 'client' | 'partner';
}) {
  const { user } = await createUserWithEmailAndPassword(auth, email, password);

  await setDoc(doc(db, 'users', user.uid), {
    email,
    phone,
    role,
    name: email.split('@')[0],
    createdAt: Timestamp.now(),
  });

  return user;
}
