import { TUserProfile } from 'apis/@types';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from 'utils/firebase';

export default async function getUsers(role?: 'client' | 'partner' | 'admin') {
  if (!role) {
    const snapshot = await getDocs(collection(db, 'users'));
    const users: TUserProfile[] = [];
    snapshot.forEach((doc) => {
      const user = doc.data() as TUserProfile;
      user.id = doc.id;
      users.push(user);
    });
    return users;
  }

  const snapshot = await getDocs(
    query(collection(db, 'users'), where('role', '==', role))
  );
  const users: TUserProfile[] = [];
  snapshot.forEach((doc) => {
    const user = doc.data() as TUserProfile;
    user.id = doc.id;
    users.push(user);
  });
  return users;
}
