import styled from 'styled-components';

export const SubscribeContainer = styled.div`
  padding-top: 80px;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubscribeHeader = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
`;

export const SubscribeProgressWrapper = styled.div`
  width: 100%;
  height: 10px;
`;

// background : linear-gradient
export const SubscribeProgress = styled.div<{
  $progress: number;
}>`
  border-radius: 0 5px 5px 0;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.colors.primary.dark}11 00%,
    ${({ theme }) => theme.colors.primary.dark}FF
      ${({ $progress: progress }) => (progress === 0 ? 0 : 10000 / progress)}%
  );
  width: ${({ $progress: progress }) => progress}%;
  height: 100%;
`;

export const SubscribeBackButton = styled.div`
  position: absolute;
  top: 40px;
  left: 40px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  opacity: 0.7;
  color: ${({ theme }) => theme.colors.black.main};
`;

export const SubscribeCardWrapper = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
`;

export const SubscribeCard = styled.div<{ order: number }>`
  display: ${({ order }) => (order === 0 ? 'block' : 'none')};
  background-color: ${({ theme }) => theme.colors.white.main};
  width: 460px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 48px 32px;
`;

export const SubscribeCardHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white.dark};
`;

export const SubscribeCardTitle = styled.h1`
  color: ${({ theme }) => theme.colors.black.main};
  white-space: pre-line;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 1.5;
`;

export const SubscribeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 8px;
`;

export const SubscribeTempSaveButton = styled.button`
  background-color: ${({ theme }) => theme.colors.white.main};
  padding: 12px 14px;
  color: ${({ theme }) => theme.colors.black.ligth};
  border: 1px solid ${({ theme }) => theme.colors.white.dark};
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white.dark};
  }
`;

export const SubscribeButton = styled.button`
  background-color: ${({ theme }) => theme.colors.white.dark};
  padding: 12px 28px;
  color: ${({ theme }) => theme.colors.black.ligth};
  opacity: 0.9;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: ${({ theme }) =>
      theme.colors.white.dark}; /* Disabled 상태일 때의 배경색 */
    color: ${({ theme }) =>
      theme.colors.black.ligth}; /* Disabled 상태일 때의 텍스트 색 */
  }

  &:not(:disabled):hover {
    background-color: ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.white.main};
  }
`;

export const SubscribeSubmitButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary.main};
  padding: 12px 28px;
  color: ${({ theme }) => theme.colors.white.main};
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  flex: 1;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.light};
  }
`;
