import { TSubscription } from 'apis/@types';
import styled from 'styled-components';

const ChatRoomHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 26px;
  height: 72px;
  box-sizing: border-box;
  border-bottom: 1px solid #e0e0e0;
`;

const ChatRoomTitle = styled.p`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.25;
`;

interface ChatRoomHeaderProps {
  subscription: TSubscription;
}

export default function ChatRoomHeader({ subscription }: ChatRoomHeaderProps) {
  return (
    <ChatRoomHeaderWrapper>
      <ChatRoomTitle>{subscription.workName}</ChatRoomTitle>
    </ChatRoomHeaderWrapper>
  );
}
