import { Blank, FlexColumn, FlexRow } from 'Globalstyles';
import {
  AuthForm,
  AuthButton,
  AuthTitle,
  AuthSubtitle,
  AuthLink,
} from 'pages/auth/auth.styles';
import { pages } from 'pages';
import {
  InputContainer,
  InputLabel,
  Input,
  ErrorMessage,
} from 'components/Form/Form.styles';
import { useForm } from 'react-hook-form';
import newPassword from 'apis/auth/newPassword';
import { useState } from 'react';
import theme from 'theme';

export type TNewPasswordForm = {
  email: string;
};

export default function AuthNewPassword() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<TNewPasswordForm>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [sentEmail, setSentEmail] = useState('');

  function onSubmit(newPasswordForm: TNewPasswordForm) {
    setIsLoading(true);
    newPassword(newPasswordForm)
      .then(() => {
        setIsLoading(false);
        setIsSent(true);
        setSentEmail(newPasswordForm.email);
      })
      .catch((error) => {
        setIsLoading(false);
        switch (error.code) {
          case 'auth/user-not-found':
            setError('email', {
              type: 'manual',
              message: '가입되지 않은 이메일 주소입니다',
            });
            break;
          default:
            setError('email', {
              type: 'manual',
              message: '가입되지 않은 이메일 주소입니다',
            });
        }
      });
  }

  return (
    <>
      <FlexColumn $gap="12px" $alignItems="center">
        <AuthTitle>비밀번호 재설정</AuthTitle>
        <AuthSubtitle>
          회원가입 시 등록했던 이메일 주소를 입력해 주시면
          <br />
          해당 이메일로 비밀번호 재설정 링크를 전달드립니다.
        </AuthSubtitle>
      </FlexColumn>
      <Blank $height="24px" />
      {!isSent ? (
        <AuthForm onSubmit={handleSubmit(onSubmit)}>
          <InputContainer>
            <InputLabel htmlFor="email">이메일</InputLabel>
            <Input
              id="email"
              placeholder="example@example.com"
              {...register('email', {
                required: '이메일을 입력해주세요',
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: '이메일 형식이 올바르지 않습니다',
                },
              })}
            />
            {errors.email && (
              <ErrorMessage>{errors.email?.message}</ErrorMessage>
            )}
          </InputContainer>

          <AuthButton disabled={isLoading}>비밀번호 재설정</AuthButton>
          <FlexRow $justifyContent="space-between" width="500px">
            <InputLabel>로그인이 필요하신가요?</InputLabel>
            <AuthLink to={pages.AuthLogin.path} color="primary">
              로그인 &gt;
            </AuthLink>
          </FlexRow>
        </AuthForm>
      ) : (
        <>
          <Blank $height="40px" />
          <FlexColumn $alignItems="center" $gap="8px">
            <AuthTitle style={{ color: theme.colors.primary.light }}>
              {sentEmail}
            </AuthTitle>
            <AuthSubtitle>
              위 이메일 주소로 비밀번호 재설정 링크를 전송했습니다.
            </AuthSubtitle>
            <AuthSubtitle>이메일을 확인해주세요.</AuthSubtitle>
          </FlexColumn>
          <Blank $height="80px" />
        </>
      )}
    </>
  );
}
