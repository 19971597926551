import { adminSidebarWidth } from 'layouts/admin/components/AdminSidebar/AdminSidebar.styles';
import styled from 'styled-components';

export const adminHeaderHeight = '72px';
export const AdminHeaderWrapper = styled.div`
  position: fixed;
  width: calc(100% - ${adminSidebarWidth});
  box-sizing: border-box;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 12px;
  padding-right: 40px;
  height: ${adminHeaderHeight};
  background-color: transparent;
  color: ${(props) => props.theme.colors.black.main};
  right: 0;
  top: 0;
`;

export const AdminHeaderApplyButton = styled.button`
  border: none;
  background-color: ${(props) => props.theme.colors.primary.light};
  color: ${(props) => props.theme.colors.white.main};
  padding: 12px 16px;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  border-radius: 12px;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary.main};
  }
`;
