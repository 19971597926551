import { Blank, FlexColumn, FlexRow } from 'Globalstyles';
import { THost } from 'apis/analytics/@types';
import createHost from 'apis/analytics/host/createHost';
import getHosts from 'apis/analytics/host/getHosts';
import getScript from 'apis/analytics/host/getScript';
import removeHost from 'apis/analytics/host/removeHost';
import { Button, Input } from 'components/Form/Form.styles';
import {
  WorkspaceCard,
  WorkspaceCardDescription,
  WorkspaceCardTitle,
} from 'pages/workspace/Workspace.styles';
import { TSettingMenuItem } from 'pages/workspace/WorkspaceSettings';
import { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atelierHeathLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styled from 'styled-components';
import theme from 'theme';
import { auth } from 'utils/firebase';

const HostItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const HostItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 360px;
  border: 1px solid ${(props) => props.theme.colors.black.light};
  padding: 10px 20px;
  border-radius: 4px;
  box-sizing: border-box;
`;

const HostItemText = styled.p`
  font-size: 1rem;
`;

const AddHostContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 360px;
  margin-top: 12px;
`;

const RemoveHostButton = styled(IoClose)`
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.error.main};
`;

export default function SettingAnalyticsApi(settingMenuItem: TSettingMenuItem) {
  const [hosts, setHosts] = useState<THost[]>([]);
  const [newHost, setNewHost] = useState('');
  const [scriptTag, setScriptTag] = useState<string>('');
  useEffect(() => {
    if (auth.currentUser?.uid) {
      getHosts(auth.currentUser.uid).then((hosts) => {
        setHosts(hosts.hosts);
      });
      getScript(auth.currentUser.uid).then((script) => {
        setScriptTag(script.scriptTag);
      });
    }
  }, []);

  function handleClickAddHost() {
    if (newHost && auth.currentUser?.uid) {
      createHost(auth.currentUser?.uid, newHost).then((host) => {
        setHosts([...hosts, host]);
        setNewHost('');
      });
    }
  }

  function handleClickRemoveHost(hostId: number) {
    if (auth.currentUser?.uid) {
      removeHost(auth.currentUser?.uid, hostId).then(() => {
        setHosts(hosts.filter((host) => host.id !== hostId));
      });
    }
  }

  const isValidNewHost =
    newHost &&
    !hosts.some((host) => host.hostname === newHost) &&
    (newHost.startsWith('http://') || newHost.startsWith('https://'));

  return (
    <WorkspaceCard $padding="40px 60px" $alignItems="start">
      <WorkspaceCardTitle>{settingMenuItem.label}</WorkspaceCardTitle>
      <Blank $height="40px" />
      <FlexColumn $gap="40px" $justifyContent="space-between" width="100%">
        <FlexColumn width="100%">
          <WorkspaceCardDescription>
            API 호스트를 추가하면, 해당 호스트의 데이터를 수집할 수 있습니다.
          </WorkspaceCardDescription>
          <WorkspaceCardDescription opacity={0.5}>
            호스트 주소는 http:// 또는 https://로 시작해야 합니다. (예:
            http://example.com)
          </WorkspaceCardDescription>
          <Blank $height="16px" />
          <FlexRow $gap="20px">
            <FlexColumn width="400px">
              <WorkspaceCardDescription>
                최대 5개까지 호스트를 추가할 수 있습니다.
              </WorkspaceCardDescription>
              {hosts.length < 5 && (
                <AddHostContainer>
                  <Input
                    color="black"
                    $borderColor="black"
                    value={newHost}
                    onChange={(e) => setNewHost(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && isValidNewHost) {
                        handleClickAddHost();
                      }
                    }}
                    placeholder="호스트 주소를 입력해주세요"
                  />
                  <Button
                    $color={theme.colors.white.main}
                    $backgroundColor={theme.colors.primary.main}
                    $hoverBackgroundColor={theme.colors.primary.dark}
                    onClick={handleClickAddHost}
                    disabled={!isValidNewHost}
                  >
                    추가
                  </Button>
                </AddHostContainer>
              )}
            </FlexColumn>
            <HostItemList>
              <WorkspaceCardDescription>
                추가된 호스트 목록 (현재 {hosts.length}개 추가됨)
              </WorkspaceCardDescription>
              {hosts.map((host) => (
                <HostItem key={host.id}>
                  <HostItemText>{host.hostname}</HostItemText>
                  <RemoveHostButton
                    onClick={() => handleClickRemoveHost(host.id)}
                  />
                </HostItem>
              ))}
            </HostItemList>
          </FlexRow>
          <Blank $height="12px" />
        </FlexColumn>
        <FlexColumn style={{ flex: 1 }}>
          <WorkspaceCardDescription>
            아래 스크립트를 웹사이트의 &lt;head&gt; 태그에 추가해주세요.
          </WorkspaceCardDescription>
          <Blank $height="12px" />
          <WorkspaceCardDescription>
            <SyntaxHighlighter
              language="html"
              style={atelierHeathLight}
              wrapLongLines={true}
            >
              {scriptTag}
            </SyntaxHighlighter>
          </WorkspaceCardDescription>
        </FlexColumn>
      </FlexColumn>
    </WorkspaceCard>
  );
}
