import { onAuthStateChanged } from 'firebase/auth';
import { pages } from 'pages';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { auth } from 'utils/firebase';

export default function SubscribeLayout() {
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate(pages.AuthLogin.path);
      }
    });
  }, [navigate]);

  return (
    <>
      <Outlet />
    </>
  );
}
