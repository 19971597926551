import { Blank, FlexRow } from 'Globalstyles';
import {
  AuthForm,
  AuthButton,
  AuthLink,
  AuthTitle,
} from 'pages/auth/auth.styles';
import { useLocation } from 'react-router-dom';
import { pages } from 'pages';
import {
  InputContainer,
  InputLabel,
  Input,
  ErrorMessage,
} from 'components/Form/Form.styles';
import { useForm } from 'react-hook-form';
import login from 'apis/auth/login';
import { useState } from 'react';
import theme from 'theme';

export type TLoginForm = {
  email: string;
  password: string;
};

export default function AuthLogin() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<TLoginForm>();
  const [isLoading, setIsLoading] = useState(false);
  const { search } = useLocation();
  const type = new URLSearchParams(search).get('type') ?? 'client';

  const onSubmit = handleSubmit((loginForm) => {
    setIsLoading(true);
    login(loginForm)
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);

        switch (error.code) {
          case 'auth/invalid-credential':
            setError('root', {
              type: 'manual',
              message: '이메일 또는 비밀번호가 일치하지 않습니다',
            });
            break;
          default:
            setError('root', {
              type: 'manual',
              message: '로그인에 실패했습니다',
            });
            break;
        }
      });
  });

  return (
    <AuthForm as={'form'} onSubmit={onSubmit}>
      <FlexRow $justifyContent="space-between" $alignItems="center">
        <AuthTitle>
          {type === 'partner' ? '파트너 ' : '고객사 '}
          로그인
        </AuthTitle>
        {/* <AuthLink
          color="primary"
          to={`${pages.AuthLogin.path}?type=${
            type === 'partner' ? 'client' : 'partner'
          }`}
        >
          {type === 'partner' ? '고객사' : '파트너'}로 로그인
        </AuthLink> */}
      </FlexRow>
      <Blank $height="8px" />
      <InputContainer>
        <InputLabel htmlFor="email">이메일</InputLabel>
        <Input
          id="email"
          placeholder="example@example.com"
          {...register('email', {
            required: '이메일을 입력해주세요',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: '이메일 형식이 올바르지 않습니다',
            },
          })}
        />
        {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
      </InputContainer>
      <InputContainer>
        <InputLabel htmlFor="password">비밀번호</InputLabel>
        <Input
          type="password"
          id="password"
          placeholder="•••••••••••••"
          {...register('password', { required: '비밀번호를 입력해주세요' })}
        />
        {errors.password && (
          <ErrorMessage>{errors.password.message}</ErrorMessage>
        )}
      </InputContainer>
      {errors.root && <ErrorMessage>{errors.root.message}</ErrorMessage>}
      <AuthLink
        to={pages.AuthNewPassword.path}
        opacity={0.5}
        textDecoration="underline"
      >
        비밀번호를 잊으셨나요?
      </AuthLink>
      <AuthButton disabled={isLoading}>이메일로 로그인</AuthButton>

      <FlexRow $justifyContent="center">
        <AuthLink to={pages.AuthSignup.path}>
          아직 회원이 아니신가요?{' '}
          <p style={{ color: theme.colors.primary.light }}>회원가입</p>
        </AuthLink>
      </FlexRow>
      {/* <Divider $direction="horizontal" $bgColor="rgba(255,255,255,0.5)" /> */}
      {/* <SocialButtonWrapper>
          <KakaoButton>
            <img src={kakaoLogo} alt="kakao" height="20px" />
            카카오로 로그인
          </KakaoButton>
          <NaverButton>
            <img src={naverLogo} alt="naver" height="16px" />
            네이버로 로그인
          </NaverButton>
        </SocialButtonWrapper> */}
    </AuthForm>
  );
}
