import { sidebarWidth } from 'layouts/workspace/components/Sidebar/Sidebar.styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const workspaceHeaderHeight = '72px';
export const WorkspaceHeaderWrapper = styled.div<{ $showShadow?: boolean }>`
  position: fixed;
  width: calc(100% - ${sidebarWidth});
  box-sizing: border-box;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 12px;
  padding-right: 40px;
  height: ${workspaceHeaderHeight};
  background-color: ${(props) => props.theme.colors.white.main};
  box-shadow: ${(props) =>
    props.$showShadow ? '0 2px 8px 0 rgba(0, 0, 0, 0.1)' : 'none'};
  right: 0;
  top: 0;
`;

export const WorkspaceHeaderDropdownButton = styled.div`
  background-color: transparent;
  color: ${(props) => props.theme.colors.black.main};
  border: none;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
`;

export const WorkspaceHeaderDropdown = styled.div`
  width: 120px;
  position: absolute;
  top: 36px;
  right: 0px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  background-color: white;
  color: black;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

export const WorkspaceHeaderDropdownItem = styled.button`
  background-color: transparent;
  border: none;
  padding: 12px 16px;
  font-size: 0.825rem;
  font-weight: 300;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.white.dark};
  }
`;

export const WorkspaceHeaderApplyButton = styled(Link)`
  border: 2px solid ${(props) => props.theme.colors.primary.light};
  background-color: transparent;
  color: ${(props) => props.theme.colors.primary.light};
  padding: 12px 16px;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  border-radius: 12px;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary.light};
    color: ${(props) => props.theme.colors.white.main};
    border: ${(props) => `2px solid ${props.theme.colors.primary.light}`};
  }
`;
