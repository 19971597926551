function HeroSection9() {
  return (
    <section className="h-full w-full bg-white">
      <div className="mx-auto flex w-[1440px] items-start justify-between px-[120px] py-[120px]">
        <div className="flex  w-[590px] flex-col items-start justify-start gap-6 pt-[40px]">
          <div className="w-[590px] gap-2">
            <p className="text-left font-suit text-[20px] font-semibold leading-[32px] tracking-[-0.02em] text-[#00000099]">
              업무 진행 방향의 근거를 데이터로 증명합니다
            </p>
            <h3 className="text-left font-suit text-[40px] font-semibold leading-[60px] tracking-[-0.02em] text-[#000000E5]">
              실시간으로 데이터 수집 및 추적 가능한
              <br />
              애널리틱스 대시보드
            </h3>
          </div>
          <pre className="text-left font-suit text-[20px] font-normal leading-[32px] tracking-[-0.02em] text-[#000000CC]">
            마케터하이어의 마케터는{' '}
            <span className="text-left font-suit text-[20px] font-bold leading-[32px] tracking-[-0.02em] text-[#000000CC]">
              플랫폼 전용 애널리틱스 대시보드를 활용
            </span>
            하여
            <br />
            <span className="text-left font-suit text-[20px] font-bold leading-[32px] tracking-[-0.02em] text-[#000000CC]">
              논리적으로 가설을 세우고 검증합니다.
            </span>
            그 모든 과정을 투명하게 확인하세요.
          </pre>
        </div>
        <img
          src="/img/box/section9-box1.png"
          alt="section9-box1"
          className="h-[334px] w-[480px]"
        />
      </div>
    </section>
  );
}

export default HeroSection9;
