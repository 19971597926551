import { TContact, TContactForm } from 'apis/@types';
import { collection, getDocs } from 'firebase/firestore';
import { db } from 'utils/firebase';

export default async function getContacts(): Promise<TContact[]> {
  const querySnapshot = await getDocs(collection(db, 'contacts'));
  const contacts: TContact[] = [];
  querySnapshot.forEach((doc) => {
    const contactInfo = doc.data() as TContactForm;
    contacts.push({
      id: doc.id,
      ...contactInfo,
    });
  });
  return contacts;
}
