import { pcWidth } from 'Globalstyles';
import styled from 'styled-components';
import theme from 'theme';

export const Header = styled.header`
  display: flex;
  height: 72px;
  background-color: ${theme.colors.white.main};
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  min-width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

export const Nav = styled.nav`
  display: flex;
  gap: 32px;
  font-size: 1rem;
  flex: 1;
  padding-left: 56px;
  padding-right: 56px;
  font-weight: 400;
  @media screen and (max-width: ${pcWidth}) {
    display: none;
  }
`;

export const NavLink = styled.a<{
  onlyPc?: boolean;
  onlyMobile?: boolean;
}>`
  color: ${theme.colors.black.main};
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: ${(props) => props.theme.colors.primary.main};
  }

  @media screen and (max-width: ${pcWidth}) {
    display: ${(props) => (props.onlyPc ? 'none' : 'block')};
  }

  @media screen and (min-width: ${pcWidth}) {
    display: ${(props) => (props.onlyMobile ? 'none' : 'block')};
  }
`;

export const NavButton = styled.a<{
  onlyPc?: boolean;
  onlyMobile?: boolean;
}>`
  text-decoration: none;
  color: white;
  background-color: ${(props) => props.theme.colors.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  border-radius: 20px;
  transition: background-color 0.3s;
  height: 44px;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary.dark};
  }

  @media screen and (max-width: ${pcWidth}) {
    display: ${(props) => (props.onlyPc ? 'none' : 'flex')};
  }

  @media screen and (min-width: ${pcWidth}) {
    display: ${(props) => (props.onlyMobile ? 'none' : 'flex')};
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;
