import updatePaymentMethod from 'apis/payment/updatePaymentMethod';
import { useLocation, useNavigate } from 'react-router-dom';

export default function PaymentMethodSuccess() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const customerKey = params.get('customerKey');
  const authKey = params.get('authKey');

  if (customerKey && authKey) {
    updatePaymentMethod(customerKey, authKey)
      .then(() => {
        alert('결제수단이 변경되었습니다.');
        navigate(-1);
      })
      .catch(() => {
        alert('결제수단 변경에 실패했습니다.');
        navigate(-1);
      });
  } else {
    navigate(-1);
  }

  return <></>;
}
