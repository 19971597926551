import { useRef, useState } from 'react';

function MobileSection2() {
  const slides = [
    {
      id: 1,
      title: '검증된 경력직 인력 구인',
      case: '# CASE 01',
      img: '/img/logo/section2-logo1.png',
      headline: '성장을 이미 경험해 본\n전문가가 필요한데...',
      description:
        '내부에 마케팅 전문가가 없으니 자체적으로 마케팅 효율을 평가하거나, 실무 집행의 디테일을 설정하는 데 어려움이 있어요. 숙련된 전문가의 경험과 역량, 인사이트가 필요해요. ',
    },
    {
      id: 2,
      title: '채용 리스크 헷지',
      case: '# CASE 02',
      img: '/img/logo/section2-logo2.png',
      headline: '초기 팀은 채용 부담이 커요',
      description:
        '복지, 부대 비용, 인사 비용, 퇴직금... 초기 성장 중인 팀이 검증된 마케터를 채용하려니 비용 부담이 커요. 가장 빠르고 쉽게 최적의 마케터를 구하려면 어떻게 해야 할까요? ',
    },
    {
      id: 3,
      title: '광고 대행사 페인포인트',
      case: '# CASE 03',
      img: '/img/logo/section2-logo3.png',
      headline: '대행사를 활용 중인데, 원하는\n 만큼의 성과가 나오지 않아요',
      description:
        '효율은 그대로인데 예산만 늘어나고, 목표로 했던 KPI를 달성하기가 어려워요. 문제의 원인 파악과 해결책을 더 적극적으로 탐색하고 제시할 수 있는 실력있는 전문가의 도움을 받고 싶어요.',
    },
    {
      id: 4,
      title: '핵심 인력이 필요할 때',
      case: '# CASE 04',
      img: '/img/logo/section2-logo4.png',
      headline: '우리의 프로덕트에 200%\n몰입할 마케터를 찾아요',
      description:
        '성과나 실무 집행에서 확실한 케어를 받고 싶습니다. 단순 업무가 아닌 고도화된 방식으로, 직원처럼 함께 해주실 전문가는 없을까요?',
    },
  ];

  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const onMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const onMouseLeave = () => {
    setIsDragging(false);
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const onMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <section className="h-full w-full bg-[#0000000A] py-12 px-4">
      <div className="mx-auto flex w-full flex-col pl-4 md:min-w-[375px] md:max-w-[500px]">
        <article className="flex h-full w-full flex-col items-start gap-2">
          <p className="font-suit text-base font-normal leading-[1.6] tracking-[-0.02em] text-[#000000] text-opacity-80">
            마케터 구독 서비스를 처음 알게 되신 분들에게
          </p>
          <h2 className="text-left font-suit text-[28px] font-bold leading-[1.5] tracking-[-0.02em] text-[#000000E5]">
            마케터하이어는
            <br />
            이런 고민을 해결합니다.
          </h2>
        </article>

        <div
          ref={containerRef}
          className="mt-[32px] flex space-x-4 overflow-x-scroll scrollbar-hide"
          onMouseDown={onMouseDown}
          onMouseLeave={onMouseLeave}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
        >
          {slides.map((slide) => (
            <div
              key={slide.id}
              className="w-[285px] flex-shrink-0 cursor-pointer rounded-[20px] bg-white p-6"
            >
              <div className="flex flex-col items-start justify-center gap-5">
                <img
                  src={slide.img}
                  alt={`logo${slide.id}`}
                  className="h-[100px] w-[100px] rounded-full object-contain"
                  loading="lazy"
                />
                <div className="flex w-full flex-col items-start justify-center gap-2 whitespace-nowrap">
                  <div className="flex flex-row items-center gap-2">
                    <div className="flex flex-row items-center rounded bg-[#795BF914] px-2 py-[2px]">
                      <p className="text-sm font-medium leading-[19.6px] tracking-[-0.02em] text-[#6F42F0]">
                        {slide.case}
                      </p>
                    </div>
                    <p className="font-suit text-sm font-bold leading-[19.6px] tracking-[-0.02em] text-[#6F42F0]">
                      {slide.title}
                    </p>
                  </div>
                  <div className="w-full whitespace-break-spaces text-left font-suit text-xl font-bold leading-[32px] tracking-[-0.02em] text-[#000000CC]">
                    {slide.headline}
                  </div>
                  <div className="w-full whitespace-break-spaces text-left font-suit text-[16px] font-normal leading-[25.6px] tracking-[-0.02em] text-[#000000CC]">
                    {slide.description}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default MobileSection2;
