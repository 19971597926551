import { pages } from 'pages';
import { Link } from 'react-router-dom';

function MobileSection() {
  return (
    <section className="h-full w-full bg-hero-section-gradient pb-12 pt-24 px-4">
      <div className="mx-auto flex w-full flex-col items-center justify-center gap-[48px] md:max-h-[640px] md:min-h-[640px] md:min-w-[375px] md:max-w-[500px]">
        <article className="flex h-full w-full flex-col items-center justify-center gap-y-2 md:gap-1">
          <h1 className="text-center font-suit text-[32px] font-normal leading-[1.35] tracking-[-0.02em] text-white text-opacity-90 md:text-[24px] md:leading-[1.35]">
            최적의 탑티어 마케터를
            <br />
            <span className="text-center font-suit text-[32px] font-extrabold leading-[1.35] tracking-[-0.02em] text-white md:text-[24px] md:leading-[1.35]">
              필요한 만큼만 고용하세요
            </span>
          </h1>

          <p className="flex text-center flex-col font-suit text-[20px] font-normal leading-[1.6] tracking-[-0.02em] text-white text-opacity-90 md:text-[14px] md:leading-[1.6]">
            번거로웠던 마케터 구인,
            <br />
            이제 마하에서 해결하세요
          </p>
        </article>
        <article className="flex h-full w-full flex-col items-center justify-center gap-2 md:gap-1">
          <Link
            id="mo0705_sec01_cv0"
            to={pages.AuthLogin.path}
            className="h-[50px] w-[206px] rounded-lg bg-white px-6 py-3 flex justify-center"
          >
            <span className="whitespace-nowrap bg-hero-button-gradient bg-clip-text text-left font-suit text-[16px] font-bold leading-[26px] text-transparent">
              3일 무료! 마케터 구독
            </span>
          </Link>
          <Link
            id="mo0705_sec01_inquiry_cv0"
            to={pages.KakaoConsult.path}
            target="_blank"
            className="h-[50px] w-[206px] rounded-lg bg-white bg-opacity-[0.16] px-9 py-3 md:px-3 md:py-1.5"
          >
            <p className="whitespace-nowrap font-suit text-[16px] font-bold leading-[26px] tracking-[0.45px] text-white">
              24시 즉시 상담 신청
            </p>
          </Link>
        </article>
      </div>
    </section>
  );
}

export default MobileSection;
