import { pages } from 'pages';
import { Link } from 'react-router-dom';

function MobileSection15() {
  return (
    <section className="h-full w-full">
      <div className="mx-auto flex w-full flex-col items-center justify-start gap-12 bg-white pt-12">
        <div className="w-full gap-1 px-4">
          <p className="text-center font-suit text-[14px] font-medium leading-[22.4px] tracking-[-0.02em] text-[#00000099]">
            2,451+명의 마케터 24시간 대기중
          </p>
          <h3 className="text-center font-suit text-[28px] font-bold leading-[42px] tracking-[-0.02em] text-[#000000E5]">
            탑티어 마케터 일 2시간,
            <br />월 110만 원
          </h3>
        </div>

        <div className="flex w-full flex-col items-center justify-between gap-5 px-8">
          <Link
            to={pages.AuthLogin.path}
            className="flex flex-col w-full items-start justify-start rounded-[20px] bg-[#0000000A] px-5 py-8"
          >
            <img
              src="/img/logo/section15-logo1.png"
              alt="section15-logo1"
              className="h-[100px] w-[100px]"
            />
            <div className="mt-6 gap-2">
              <h5 className="text-left font-suit text-[20px] font-bold leading-[28.6px] tracking-[-0.02em] text-[#000000E5]">
                서비스를 이제 막 구축하는
                <br />
                스타트업이라면?
              </h5>
              <pre className="mt-[8px] whitespace-nowrap text-left font-suit text-[16px] font-normal leading-[25.6px] tracking-[-0.02em] text-[#000000CC]">
                이미 초기 스타트업 경험이 여러 번 있고,
                <br />
                원활하게 커뮤니케이션하며 서비스를 함께
                <br />
                성장시킬 숙련된 마케터를 배정해 드려요
              </pre>
            </div>
            <div className="mt-6 cursor-pointer text-left font-suit text-[16px] font-bold leading-[25.6px] tracking-[-0.02em] text-[#795BF9]">
              마케터 배정 받기 &gt;
            </div>
          </Link>
          <Link
            to={pages.AuthLogin.path}
            className="flex w-full flex-col items-start justify-start rounded-[20px] bg-[#0000000A] px-5 py-8"
          >
            <img
              src="/img/logo/section15-logo2.png"
              alt="section15-logo2"
              className="h-[100px] w-[100px]"
            />
            <div className="mt-[14px] gap-2">
              <h5 className="text-left font-suit text-[20px] font-bold leading-[28px] tracking-[-0.02em] text-[#000000E5]">
                급한 업무도 많고 정신없이
                <br />
                성장 중인 중소 기업이라면?
              </h5>
              <pre className="mt-[8px] whitespace-nowrap text-left font-suit text-[16px] font-normal leading-[25.6px] tracking-[-0.02em] text-[#000000CC]">
                직접 마케팅 전략을 수립하거나 인사이트를
                <br />
                제안하며, 한정된 예산으로 높은 효율을 뽑아낼 수<br /> 있는
                꼼꼼이 마케터를 배정해 드려요
              </pre>
            </div>
            <div className="mt-6 cursor-pointer text-left font-suit text-[16px] font-bold leading-[25.6px] tracking-[-0.02em] text-[#795BF9]">
              마케터 배정 받기 &gt;
            </div>
          </Link>
          <Link
            to={pages.AuthLogin.path}
            className="flex w-full flex-col items-start justify-start rounded-[20px] bg-[#0000000A] px-5 py-8"
          >
            <img
              src="/img/logo/section15-logo3.png"
              alt="section15-logo3"
              className="h-[100px] w-[100px]"
            />
            <div className="mt-6 gap-[8px]">
              <h5 className="text-left font-suit text-[20px] font-bold leading-[28px] tracking-[-0.02em] text-[#000000E5]">
                체계가 잘 잡혀있고 성숙한
                <br />
                중견·대기업이라면?
              </h5>
              <pre className="mt-[8px] whitespace-nowrap text-left font-suit text-[16px] font-normal leading-[25.6px] tracking-[-0.02em] text-[#000000CC]">
                아웃소싱 업체나 광고 대행사와 컨택하며
                <br />
                확실하게 성과를 평가하고 직접 핸들링할
                <br />
                능숙한 마케터를 배정해 드려요
              </pre>
            </div>
            <div className="mt-6 cursor-pointer text-left font-suit text-[16px] font-bold leading-[25.6px] tracking-[-0.02em] text-[#795BF9]">
              마케터 배정 받기 &gt;
            </div>
          </Link>
        </div>

        <div className="flex w-full flex-col items-center justify-start gap-y-6 bg-[#131733] px-[39px] py-14">
          <h4 className="text-center font-suit text-[20px] font-normal leading-[26px] tracking-[-0.02em] text-[#FFFFFF]">
            어떤 마케터가 필요한지 모르겠다면?
            <br />
            <span className="text-[28px] font-bold leading-[48px]">
              상담 후 결정해 보세요
            </span>
          </h4>
          <div className="flex w-[172px] flex-col items-center justify-between gap-y-2">
            <Link
              id="mo0705_sec12_inquiry_cv0"
              to={pages.KakaoConsult.path}
              target="_blank"
              className="w-full whitespace-nowrap rounded-[8px] bg-[#FFFFFF4D] px-6 py-3 text-center font-suit text-[16px] font-bold leading-[26px] tracking-[0.45px] text-[#FFFFFF]"
            >
              24시 즉시 상담
            </Link>
            <Link
              id="mo0705_sec12_cv0"
              to={pages.AuthLogin.path}
              className="w-full whitespace-nowrap rounded-lg bg-[white] px-6 py-3 text-center font-suit text-[16px] font-bold leading-[26px] tracking-[0.45px] text-[#131733]"
            >
              지금 바로 구독하기{' '}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MobileSection15;
