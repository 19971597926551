import { FlexColumn } from 'Globalstyles';
import { TPaymentMethod } from 'apis/@types';

import { FaCreditCard } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa6';
import styled from 'styled-components';

const PaymentMethodContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  border: 1px solid ${(props) => props.theme.colors.gray.main};
  padding: 12px 20px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
`;

const PaymentMethodCard = styled(FaCreditCard)`
  color: ${(props) => props.theme.colors.primary.main};
  background-color: ${(props) => props.theme.colors.primary[200]};
  border-radius: 8px;
  padding: 12px;
  font-size: 20px;
`;

const PaymentMethodCardBank = styled.div`
  font-size: 1rem;
`;

const PaymentMethodCardNumber = styled.div`
  font-size: 0.875rem;
  opacity: 0.7;
`;

const PaymentMethodCardCheck = styled(FaCheck)`
  color: ${(props) => props.theme.colors.primary.main};
  padding: 4px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary[200]};
`;

function cardNumberFormatter(cardNumber: string) {
  // '-'

  return cardNumber.replaceAll('*', '•').replace(/(.{4})/g, '$1  ');
}

export default function PaymentMethod({
  paymentMethod,
}: {
  paymentMethod: TPaymentMethod;
}) {
  return (
    <PaymentMethodContainer>
      <PaymentMethodCard />
      <FlexColumn $justifyContent="center" $gap="8px">
        <PaymentMethodCardBank>
          {paymentMethod?.cardCompany}
        </PaymentMethodCardBank>
        <PaymentMethodCardNumber>
          {paymentMethod?.cardNumber &&
            cardNumberFormatter(paymentMethod.cardNumber)}
        </PaymentMethodCardNumber>
      </FlexColumn>
      <PaymentMethodCardCheck />
    </PaymentMethodContainer>
  );
}
