import {
  DropdownContainer,
  DropdownButton,
  DropdownList,
  DropdownItem,
  DropdownInput,
} from 'components/Form/Dropdown/Dropdown.styles';
import { useEffect, useRef, useState } from 'react';
import {
  useController,
  Control,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import { FaChevronDown } from 'react-icons/fa';

interface DropdownProps<T extends FieldValues> {
  id: string;
  options: { value: string; label: string; disabled?: boolean }[];
  name: Path<T>;
  control: Control<T>;
  placeholder?: string;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  maxHeight?: string;
}

const Dropdown = <T extends FieldValues>({
  id,
  options,
  name,
  control,
  placeholder,
  color,
  backgroundColor,
  borderColor,
  maxHeight,
}: DropdownProps<T>) => {
  const {
    field: { ref, onChange, value },
  } = useController<T>({
    name,
    control,
    defaultValue: '' as PathValue<T, Path<T>>,
  });

  const dropdownButtonRef = useRef<HTMLDivElement>(null);
  const dropdownListRef = useRef<HTMLUListElement>(null);

  // if height of dropdown list is bigger than remaining space, it will display above the dropdown button
  // if dropdown list height is smaller than remaining space, it will display below the dropdown button
  const [direction, setDirection] = useState<'up' | 'down'>('down');

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => setIsOpen(!isOpen);
  const handleOptionSelect = (option: string) => {
    onChange(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (dropdownButtonRef.current && dropdownListRef.current) {
      const dropdownButtonRect =
        dropdownButtonRef.current.getBoundingClientRect();
      const dropdownListRect = dropdownListRef.current.getBoundingClientRect();
      const remainingSpace = window.innerHeight - dropdownButtonRect.bottom;
      console.log(dropdownListRect.height, remainingSpace);
      if (dropdownListRect.height > remainingSpace) {
        setDirection('up');
      } else {
        setDirection('down');
      }
    }
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButton
        ref={dropdownButtonRef}
        as="div"
        onClick={handleToggle}
        color={color}
        opacity={1}
        backgroundColor={backgroundColor}
        $borderColor={borderColor}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        role="button"
        tabIndex={0}
      >
        <p style={{ opacity: value ? 1 : 0.5 }}>
          {value
            ? options.find((o) => o.value === value)?.label
            : placeholder || '선택해주세요'}
        </p>
        <FaChevronDown className="chevron-down" />
      </DropdownButton>
      {isOpen && (
        <DropdownList
          maxHeight={maxHeight}
          role="listbox"
          direction={direction}
          ref={dropdownListRef}
        >
          {options.map((option, index) => (
            <DropdownItem
              key={index}
              disabled={option.disabled}
              onClick={() => {
                if (!option.disabled) {
                  handleOptionSelect(option.value);
                }
              }}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
      <DropdownInput
        id={id}
        value={value || ''}
        name={name}
        ref={ref}
        readOnly
      />
    </DropdownContainer>
  );
};

export default Dropdown;
