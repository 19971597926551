import styled from 'styled-components';
import theme from 'theme';

export const Tabs = styled.div`
  display: flex;
  gap: 8px;
`;

export const Tab = styled.div<{ active: boolean }>`
  padding: 12px 16px;
  border-radius: 8px;
  background-color: ${({ active }) =>
    active ? theme.colors.primary.main : theme.colors.gray.main};
  color: ${({ active }) =>
    active ? theme.colors.white.main : theme.colors.black.main};
  cursor: pointer;
`;
