import styled from 'styled-components';

export const AdminContainer = styled.div`
  display: flex;
  min-width: 1200px;
  padding: 20px 80px;
  box-sizing: border-box;

  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: 100%;
`;

export const AdminTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 40px;
  text-align: left;
`;

export const AdminCardWrapper = styled.div<{
  direction?: 'row' | 'column';
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ direction }) => direction || 'row'};
  gap: 20px;
`;

export const AdminCard = styled.div<{
  width?: string;
  flex?: string;
  padding?: string;
}>`
  background-color: ${({ theme }) => theme.colors.white.main};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: ${({ padding }) => padding || '20px'};
  width: ${({ width }) => width || '300px'};
  flex: ${({ flex, width }) => flex || (width ? 'none' : '1')};
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 600px;
  box-sizing: border-box;
`;

export const AdminCardTitle = styled.h2`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black.main};
  margin-bottom: 20px;
  opacity: 0.9;
`;

export const AdminCardOutlineButton = styled.button`
  width: 100%;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.primary.main};
  padding: 16px 20px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.white.main};
  }
`;
