import { loadTossPayments } from '@tosspayments/payment-sdk';
import { TUserProfile } from 'apis/@types';
import { pages } from 'pages';
import styled from 'styled-components';

const UpdatePaymentMethodButtonContainer = styled.button`
  background-color: ${(props) => props.theme.colors.primary.main};
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  width: 100%;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary.dark};
  }
`;

export default function UpdatePaymentMethodButton({
  userProfile,
  children,
}: {
  userProfile: TUserProfile | null;
  children?: React.ReactNode;
}) {
  if (!process.env.REACT_APP_TOSS_CLIENT_KEY) {
    throw new Error('TOSS_CLIENT_KEY is not defined');
  }

  const clientKey = process.env.REACT_APP_TOSS_CLIENT_KEY;

  function handleClick() {
    loadTossPayments(clientKey)
      .then((TossPayments) => {
        if (!userProfile || !userProfile.id) {
          throw new Error('사용자 정보가 없습니다.');
        }
        TossPayments.requestBillingAuth('카드', {
          customerKey: userProfile.id,
          customerName: userProfile.name,
          customerEmail: userProfile.email,
          failUrl: `${window.location.origin}${pages.PaymentMethodFail.path}`,
          successUrl: `${window.location.origin}${pages.PaymentMethodSuccess.path}`,
        });
      })
      .catch((error) => {
        if (error.code === 'USER_CANCEL') {
          throw new Error('사용자가 결제를 취소했습니다.');
        } else if (error.code === 'INVALID_CARD_COMPANY') {
          throw new Error('지원하지 않는 카드사입니다.');
        }
      });
  }

  return (
    <UpdatePaymentMethodButtonContainer onClick={handleClick}>
      {children || '결제수단 변경'}
    </UpdatePaymentMethodButtonContainer>
  );
}
