import useContacts from 'hooks/admin/useContacts';
import { AdminContainer, AdminTitle } from 'pages/admin/Admin.styles';

import ContactsTable from 'pages/admin/components/ContactsTable';

export default function AdminContacts() {
  const { contacts, handleCheckContact } = useContacts();

  return (
    <AdminContainer>
      <AdminTitle>상담문의</AdminTitle>
      <ContactsTable
        contacts={contacts}
        handleCheckContact={handleCheckContact}
      />
    </AdminContainer>
  );
}
