import { Blank, FlexColumn, FlexRow } from 'Globalstyles';
import { TPaymentMethod } from 'apis/@types';
import getPaymentMethod from 'apis/payment/getPaymentMethod';
import { InputLabel } from 'components/Form/Form.styles';
import { UserContext } from 'layouts/workspace/workspace_layout';
import {
  WorkspaceCard,
  WorkspaceCardTitle,
} from 'pages/workspace/Workspace.styles';
import { TSettingMenuItem } from 'pages/workspace/WorkspaceSettings';
import PaymentHistory from 'pages/workspace/components/PaymentMethod/PaymentHistory';
import PaymentMethod from 'pages/workspace/components/PaymentMethod/PaymentMethod';
import UpdatePaymentMethodButton from 'pages/workspace/components/PaymentMethod/UpdatePaymentMethodButton';
import { useContext, useEffect, useState } from 'react';
import { auth } from 'utils/firebase';

export default function SettingPayments(settingMenuItem: TSettingMenuItem) {
  const userProfile = useContext(UserContext);
  const [paymentMethod, setPaymentMethod] = useState<TPaymentMethod>();

  useEffect(() => {
    getPaymentMethod(auth.currentUser?.uid).then((data) => {
      setPaymentMethod(data);
    });
  }, []);

  return (
    <WorkspaceCard $padding="40px 60px">
      <WorkspaceCardTitle style={{ alignSelf: 'start' }}>
        {settingMenuItem.label}
      </WorkspaceCardTitle>
      <Blank $height="40px" />
      <FlexRow width="100%" $gap="16px" height="100%">
        <FlexColumn width="360px" $gap="16px">
          <InputLabel color="black">결제 수단</InputLabel>
          {paymentMethod && <PaymentMethod paymentMethod={paymentMethod} />}
          <UpdatePaymentMethodButton userProfile={userProfile}>
            {paymentMethod ? '결제수단 변경' : '결제수단 등록'}
          </UpdatePaymentMethodButton>
        </FlexColumn>
        <FlexColumn style={{ flex: 1 }} $gap="16px" height="100%">
          <InputLabel color="black">결제 내역</InputLabel>
          <PaymentHistory />
        </FlexColumn>
      </FlexRow>
    </WorkspaceCard>
  );
}
