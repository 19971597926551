import { TSubscription } from 'apis/@types';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from 'utils/firebase';

export default function useSubscriptions() {
  const [subscriptions, setSubscriptions] = useState<TSubscription[]>([]);

  useEffect(() => {
    const q = query(collection(db, 'subscriptions'));
    let unscribe = onSnapshot(q, (snapshot) => {
      const subscriptions: TSubscription[] = [];
      snapshot.forEach((doc) => {
        const subscription = doc.data() as TSubscription;
        subscription.id = doc.id;
        subscriptions.push(subscription);
      });
      setSubscriptions(subscriptions);
    });
    return () => unscribe();
  }, []);

  return { subscriptions };
}
