import styled from 'styled-components';

const PaymentHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.gray.main};
  border-radius: 8px;
  height: 100%;
  min-height: 360px;
`;

export default function PaymentHistory() {
  return (
    <PaymentHistoryContainer>
      {/* <div>Payment History</div> */}
    </PaymentHistoryContainer>
  );
}
