import { Blank } from 'Globalstyles';
import { TUserProfile } from 'apis/@types';
import { Tab, Tabs } from 'components/Tab/Tabs';
import { AdminUsersContext } from 'layouts/admin/admin_layout';
import { pages } from 'pages';
import { AdminContainer, AdminTitle } from 'pages/admin/Admin.styles';
import UsersTable from 'pages/admin/components/UsersTables';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function AdminUsers() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const role = (new URLSearchParams(search).get('role') ??
    'client') as TUserProfile['role'];
  const users = useContext(AdminUsersContext);
  return (
    <AdminContainer>
      <AdminTitle>유저관리</AdminTitle>
      <Tabs>
        <Tab
          active={role === 'client'}
          onClick={() => navigate(`${pages.AdminUsers.path}?role=client`)}
        >
          클라이언트 {users.filter((u) => u.role === 'client').length}
        </Tab>

        <Tab
          active={role === 'partner'}
          onClick={() => navigate(`${pages.AdminUsers.path}?role=partner`)}
        >
          파트너 {users.filter((u) => u.role === 'partner').length}
        </Tab>

        <Tab
          active={role === 'admin'}
          onClick={() => navigate(`${pages.AdminUsers.path}?role=admin`)}
        >
          관리자 {users.filter((u) => u.role === 'admin').length}
        </Tab>
      </Tabs>
      <Blank $height="24px" />
      <UsersTable users={users.filter((u) => u.role === role)} />
    </AdminContainer>
  );
}
