import { TSubscriptionStatus } from 'apis/@types';
import Chip from 'components/Chip/Chip';
import theme from 'theme';

export default function SubscriptionCardChip({
  status,
}: {
  status: TSubscriptionStatus;
}) {
  let color = '';
  let backgroundColor = '';
  let label = '';

  //temp, pending, matched, end
  switch (status) {
    case 'temp':
      console.log('temp');
      color = theme.colors.black.light;
      backgroundColor = theme.colors.gray[200];
      label = '작성중';
      break;
    case 'pending':
      console.log('pending');
      color = theme.colors.warning.main;
      backgroundColor = theme.colors.warning[200];
      label = '검토중';
      break;
    case 'matched':
      console.log('matched');
      color = theme.colors.primary.main;
      backgroundColor = theme.colors.primary[200];
      label = '구독중';
      break;
    case 'end':
      console.log('end');
      color = theme.colors.error.main;
      backgroundColor = theme.colors.error[200];
      label = '마감';
      break;
  }

  return (
    <Chip
      color={color}
      backgroundColor={backgroundColor}
      style={{ fontWeight: 700, padding: '4px 12px', fontSize: '0.875rem' }}
    >
      {label}
    </Chip>
  );
}
