import { doc, updateDoc } from 'firebase/firestore';
import { db } from 'utils/firebase';

export default async function changeStatus(
  subscriptionId: string,
  status: 'temp' | 'pending' | 'matched' | 'end'
) {
  return await updateDoc(doc(db, 'subscriptions', subscriptionId), {
    status,
  });
}
