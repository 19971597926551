import { pages } from 'pages';
import { WorkspaceCard } from 'pages/workspace/Workspace.styles';
import { TSettingMenuItem } from 'pages/workspace/WorkspaceSettings';
import {
  MenuContainer,
  MenuItem,
} from 'pages/workspace/components/Menu.styles';
import { useLocation, useNavigate } from 'react-router-dom';

export default function WorkspaceSettingMenu({
  settingMenuItems,
}: {
  settingMenuItems: TSettingMenuItem[];
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const tab = new URLSearchParams(location.search).get('tab');

  return (
    <WorkspaceCard $width="280px" $padding="12px">
      <MenuContainer>
        {settingMenuItems.map((item) => (
          <MenuItem
            key={item.label}
            $active={tab === item.param || (item.default && !tab)}
            onClick={() => {
              if (item.onClick) {
                item.onClick();
              } else {
                navigate(`${pages.WorkspaceSettings.path}?tab=${item.param}`);
              }
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </MenuContainer>
    </WorkspaceCard>
  );
}
